
// @ is an alias to /src
import RoleAdminHl from '@/package/roleAdminHl/index.vue'
export default {
    name: 'roleAdmin',
    components: {
        RoleAdminHl
    },
    data() {
        return {
            loading: false,
            menuType: 'searchReviewPage'
        }
    }
}
