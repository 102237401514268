
// @ is an alias to /src
import * as OLAPOptions from './mock';
export default {
    name: 'TaskAnalysis',
    components: {},
    data() {
        return {
            value1: [new Date(2022, 11, 1), new Date(2022, 12, 31)],
            value: '选项1',
            options: [
                {
                    value: '选项1',
                    label: '立案时效'
                },
                {
                    value: '选项2',
                    label: '案件整体时效'
                },
                {
                    value: '选项3',
                    label: '录入核赔时效'
                },
                {
                    value: '选项4',
                    label: '复审时效'
                }
            ],
            auditTimeDistribute: OLAPOptions.auditTimeDistribute,
            firstAuditAvgTimeTop5: OLAPOptions.firstAuditAvgTimeTop5,
            riskRuleHitRate: OLAPOptions.riskRuleHitRate,
            googsTaskOLAP: OLAPOptions.googsTaskOLAP,
            operatorOLAP: OLAPOptions.operatorOLAP
        };
    },
    methods: {
        getOptions() {}
    },
    mounted() {
        //
    }
};
