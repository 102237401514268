
import da from 'element-ui/src/locale/lang/da';
import el from 'element-ui/src/locale/lang/el';

export default {
    name: 'InnerQualityConclusion',
    props: {
        caseDetail: {
            typeof: Object,
            default: {}
        }
    },
    data() {
        return {
            innerQualityConclusion: {
                qualityRiskLevelName: '',
                riskMarkList: [],
                qualityRemark: ''
            },
            customRemark: null
        };
    },
    mounted() {},
    methods: {
        getInnerQuality() {
            this.instance.get('/tpa/api/case/inner/quality/get?caseNo=' + this.caseDetail.caseNo).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    if (data.data) {
                        this.innerQualityConclusion = data.data;
                        let remarkStr = this.innerQualityConclusion.qualityRemark;
                        if (remarkStr.indexOf('--迪迦奥特曼--') != -1) {
                            this.customRemark = remarkStr.split('--迪迦奥特曼--');
                        }
                    } else {
                        this.innerQualityConclusion = {
                            qualityRiskLevelName: '',
                            riskMarkList: [],
                            qualityRemark: ''
                        };
                    }
                }
            });
        }
    },
    watch: {
        caseDetail(data) {
            if (data.caseNo) {
                this.getInnerQuality();
            }
        }
    }
};
