import { render, staticRenderFns } from "./deptAdmin.vue?vue&type=template&id=d6bbd550&scoped=true"
import script from "./deptAdmin.vue?vue&type=script&lang=js"
export * from "./deptAdmin.vue?vue&type=script&lang=js"
import style0 from "./deptAdmin.vue?vue&type=style&index=0&id=d6bbd550&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6bbd550",
  null
  
)

export default component.exports