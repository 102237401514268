
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

export default {
    name: 'userManageHl',
    dicts: ['sys_normal_disable', 'sys_user_sex'],
    components: { Treeselect },
    data() {
        const equalToPassword = (rule, value, callback) => {
            if (this.form.password != value) {
                callback(new Error('两次输入的密码不一致'));
            } else {
                callback();
            }
        };
        return {
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 用户表格数据
            userList: null,
            // 弹出层标题
            title: '',
            // 部门树选项
            deptOptions: undefined,
            // 是否显示弹出层
            open: false,
            // 部门名称
            deptName: undefined,
            // 默认密码
            initPassword: undefined,
            // 日期范围
            dateRange: [],
            // 岗位选项
            postOptions: [],
            // 角色选项
            roleOptions: [],
            dictOption: [],
            // 表单参数
            form: {},
            defaultProps: {
                children: 'children',
                label: 'deptName'
            },
            // 用户导入参数
            upload: {
                // 是否显示弹出层（用户导入）
                open: false,
                // 弹出层标题（用户导入）
                title: '',
                // 是否禁用上传
                isUploading: false,
                // 是否更新已经存在的用户数据
                updateSupport: 0,
                // 设置上传的请求头部
                headers: { Authorization: 'Bearer ' + this.$$getToken() },
                // 上传的地址
                url: process.env.VUE_APP_BASE_API + '/system/user/importData'
            },
            // 查询参数
            queryParams: {
                pageNo: 1,
                pageSize: 10,
                startCreateTime: '',
                endCreateTime: '',
                nickName: undefined,
                phoneNumber: undefined,
                status: undefined,
                deptId: undefined
            },
            // 列信息
            columns: [
                { key: 0, label: `用户编号`, visible: true },
                { key: 1, label: `用户名称`, visible: true },
                { key: 2, label: `姓名`, visible: true },
                { key: 3, label: `部门`, visible: true },
                { key: 4, label: `手机号码`, visible: true },
                { key: 5, label: `状态`, visible: true },
                { key: 6, label: `创建时间`, visible: true }
            ],
            // 表单校验
            rules: {
                userName: [
                    { required: true, message: '用户名称不能为空', trigger: 'blur' },
                    { min: 2, max: 20, message: '用户名称长度必须介于 2 和 20 之间', trigger: 'blur' }
                ],
                nickName: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
                deptId: [{ required: true, message: '归属组织不能为空', trigger: 'blur' }],
                password: [
                    { required: true, message: '密码不能为空', trigger: 'blur' },
                    { min: 5, max: 20, message: '密码长度必须介于 5 和 20 之间', trigger: 'blur' }
                ],
                newPassword: [
                    { validator: equalToPassword, trigger: 'blur' },
                    { required: true, message: '密码不能为空', trigger: 'blur' }
                ],
                email: [
                    {
                        type: 'email',
                        message: '请输入正确的邮箱地址',
                        trigger: ['blur', 'change']
                    }
                ],
                phoneNumber: [
                    { required: true, message: '手机号不能为空', trigger: 'blur' },
                    {
                        pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                        message: '请输入正确的手机号码',
                        trigger: 'blur'
                    }
                ],
                status: [{ required: true, message: '状态不能为空', trigger: 'change' }],
                roleIdList: [{ required: true, message: '角色不能为空', trigger: 'change' }]
            }
        };
    },
    watch: {
        // 根据名称筛选部门树
        deptName(val) {
            this.$refs.tree.filter(val);
        }
    },
    created() {
        this.getList();
        this.getDeptTree();
        this.getRoleListByTenantId();
    },
    methods: {
        /** 查询用户列表 */
        getList() {
            this.loading = true;
            this.tableData = []; //  清空table的值
            var queryParams = JSON.parse(JSON.stringify(this.queryParams));
            let json = {
                condition: queryParams,
                pageNo: queryParams.pageNo,
                pageSize: queryParams.pageSize
            };
            this.instance.post('/upms/api/user/paging', json).then((res) => {
                this.loading = false;
                this.userList = res.data.data.list;
                this.total = parseInt(res.data.data.total);
            });
        },
        /** 租户角色列表查询 */
        getRoleListByTenantId() {
            this.instance.post('/upms/api/role/getRoleListByTenantId', {}).then((res) => {
                if (res.data.code == 200) {
                    let response = res.data.data;
                    this.roleOptions = response;
                }
            });
        },
        /** 查询部门下拉树结构 */
        getDeptTree() {
            this.instance.get('/upms/api/dept/getDeptTree', {}).then((res) => {
                if (res.data.code == 200) {
                    let response = res.data.data;
                    function addLabel(items) {
                        if (items) {
                            items.forEach((val) => {
                                val.label = val.deptName;
                                val.id = val.deptId;
                                if (val.children == null) {
                                    delete val.children;
                                }
                                val.children && addLabel(val.children);
                            });
                        } else {
                            return false;
                        }
                    }
                    addLabel(response);
                    this.deptOptions = response;
                }
            });
        },
        // 筛选节点
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        // 节点单击事件
        handleNodeClick(data) {
            this.queryParams.deptId = data.deptId;
            this.handleQuery();
        },
        // 用户状态修改
        handleStatusChange(row) {
            let text = row.status === '0' ? '启用' : '停用';
            this.$modal
                .confirm('确认要"' + text + '""' + row.userName + '"用户吗？')
                .then(() => {
                    let json = {
                        userId: row.userId,
                        status: row.status
                    };
                    this.instance.post('/upms/api/user/edit', json).then((res) => {});
                    this.$modal.msgSuccess(text + '成功');
                })
                .catch(function () {
                    row.status = row.status === '0' ? '1' : '0';
                });
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 表单重置
        reset() {
            this.form = {
                userId: undefined,
                deptId: undefined,
                nickName: undefined,
                nickName: undefined,
                password: undefined,
                phoneNumber: undefined,
                email: undefined,
                sex: undefined,
                status: '0',
                remark: undefined,
                postIdList: [],
                roleIdList: []
            };
            this.resetForm('form');
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNo = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.dateRange = [];
            this.queryParams.startCreateTime = '';
            this.queryParams.endCreateTime = '';
            this.resetForm('queryForm');
            this.queryParams.deptId = undefined;
            this.$refs.tree.setCurrentKey(null);
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.userId);
            this.single = selection.length != 1;
            this.multiple = !selection.length;
        },
        // 更多操作触发
        handleCommand(command, row) {
            switch (command) {
                case 'handleResetPwd':
                    this.handleResetPwd(row);
                    break;
                case 'handleAuthRole':
                    this.handleAuthRole(row);
                    break;
                default:
                    break;
            }
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = '添加用户';
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            this.instance.get('/upms/api/user/' + row.userId, { params: {} }).then((res) => {
                if (res.data.code == 200) {
                    this.form = res.data.data;
                    this.form.password = '';
                    this.open = true;
                    this.title = '修改用户';
                } else {
                    this.$modal.msgError(res.data.message);
                }
            });
        },
        /** 重置密码按钮操作 */
        handleResetPwd(row) {
            this.$prompt('请输入"' + row.userName + '"的新密码', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal: false,
                inputPattern: /^.{5,20}$/,
                inputErrorMessage: '用户密码长度必须介于 5 和 20 之间'
            })
                .then(({ value }) => {
                    resetUserPwd(row.userId, value).then((response) => {
                        this.$modal.msgSuccess('修改成功，新密码是：' + value);
                    });
                })
                .catch(() => {});
        },
        /** 分配角色操作 */
        handleAuthRole: function (row) {
            const userId = row.userId;
            this.$router.push('/system/user-auth/role/' + userId);
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if (this.form.userId != undefined) {
                        this.instance.post('/upms/api/user/edit', this.form).then((res) => {
                            if (res.data.code == 200) {
                                this.open = false;
                                this.$modal.msgSuccess('修改成功');
                                this.getList();
                            } else {
                                this.$modal.msgError(res.data.message);
                            }
                        });
                    } else {
                        this.instance.post('/upms/api/user/add', this.form).then((res) => {
                            if (res.data.code == 200) {
                                this.$modal.msgSuccess('新增成功');
                                this.open = false;
                                this.getList();
                            } else {
                                this.$modal.msgError(res.data.message);
                            }
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const userIds = row.userId || this.ids;
            this.$modal
                .confirm('是否确认删除用户编号为"' + userIds + '"的数据项？')
                .then(function () {
                    return delUser(userIds);
                })
                .then(() => {
                    this.getList();
                    this.$modal.msgSuccess('删除成功');
                })
                .catch(() => {});
        },
        /** 导出按钮操作 */
        handleExport() {
            this.download(
                'system/user/export',
                {
                    ...this.queryParams
                },
                `user_${new Date().getTime()}.xlsx`
            );
        },
        /** 导入按钮操作 */
        handleImport() {
            this.upload.title = '用户导入';
            this.upload.open = true;
        },
        /** 下载模板操作 */
        importTemplate() {
            this.download('system/user/importTemplate', {}, `user_template_${new Date().getTime()}.xlsx`);
        },
        // 文件上传中处理
        handleFileUploadProgress(event, file, fileList) {
            this.upload.isUploading = true;
        },
        // 文件上传成功处理
        handleFileSuccess(response, file, fileList) {
            this.upload.open = false;
            this.upload.isUploading = false;
            this.$refs.upload.clearFiles();
            this.$alert("<div style='overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px 0;'>" + response.msg + '</div>', '导入结果', { dangerouslyUseHTMLString: true });
            this.getList();
        },
        // 提交上传文件
        submitFileForm() {
            this.$refs.upload.submit();
        },
        handleCurrentChange(val) {
            this.queryParams.pageNo = val;
            this.getList();
        },
        handleSizeChange(val) {
            this.queryParams.pageSize = val;
            this.getList();
        }
    }
};
