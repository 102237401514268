
import OSS from 'ali-oss';
import LossPage from '@/components/caseInfo/lossPage.vue';
import AdjustmentPage from '@/components/caseInfo/adjustmentPage.vue';
import ForecastCasePage from '@/components/caseInfo/ForecastCasePage.vue';
import RegisterPage from '@/components/caseInfo/registerPage.vue';
// @ is an alias to /src
export default {
    name: 'caseList',
    components: {
        LossPage,
        AdjustmentPage,
        ForecastCasePage,
        RegisterPage
    },
    props: {
        menuType: {
            type: String,
            default: '99'
        }
    },
    data() {
        return {
            tmpTabsValue: '',
            jdDutyOptions: [
                { value: true, label: '是' },
                { value: false, label: '否' }
            ],
            loading: false,
            dialogFormVisible: false,
            handleStatus: 1,
            userForm: {
                userId: ''
            },
            assignData: {
                caseNo: '',
                claimAmount: '',
                caseStep: ''
            },
            orders: [],
            navForm: {
                caseNo: '',
                insuranceNo: '',
                reporter: '',
                handlerId: '',
                caseStep: '',
                startAccTime: '',
                endAccTime: '',
                startReportTime: '',
                endReportTime: ''
            },
            workOrderDetail: {
                damageWorkOrderList: []
            },
            tmpUserData: [],
            dialogUserOption: [],
            userTableData: [],
            totalAmount: 0,
            pageNo: 1,
            pageSize: 10,
            total: 1,
            tableData: [{ insuranceNo: '当前' }],
            multipleSelection: [],
            caseStepOption: [],
            caseEndStepOption: [],
            fetchCaseStepFlow: [],
            queryUserData: [],
            dialogForm: {
                userId: '',
                phone: ''
            },
            editableTabsValue: '1',
            editableTabs: [
                {
                    title: '正式案件',
                    menuTypeNum: '1'
                },
                {
                    title: '预报案案件',
                    menuTypeNum: '2'
                }
            ],
            tabDictionary: {
                正式案件: '1',
                预报案案件: '2'
            },
            handleFlag: false,
            handleData: {},
            OSSClient: null,
            tableHeight: null,
            amountLevelArr: [
                {
                    level: 'A级',
                    levelColor: '#5891E6'
                },
                {
                    level: 'B级',
                    levelColor: '#6ABB39'
                },
                {
                    level: 'C级',
                    levelColor: '#E6A558'
                },
                {
                    level: 'D级',
                    levelColor: '#FF6E6E'
                }
            ]
        };
    },
    mounted() {
        // 动态计算table高度
        this.calcHeight();
        window.onresize = () => {
            this.calcHeight();
        };
        localStorage.removeItem('detailShow');

        this.getUserList();
    },
    methods: {
        calcHeight() {
            let elRadioGroup = document.querySelector('.el-radio-group') ? document.querySelector('.el-radio-group').clientHeight : 0;
            let registerTabsHeight = document.querySelector('.register_tabs') ? document.querySelector('.register_tabs').clientHeight : 0;
            let publicBoxHeight = document.querySelector('.public_box') ? document.querySelector('.public_box').clientHeight : 0;
            let elPaginationHeight = document.querySelector('.el-pagination') ? document.querySelector('.el-pagination').clientHeight : 0;
            let tableHeaderHeight = 51;
            let guHeight = null;

            switch (this.menuType) {
                case 'searchPage':
                    guHeight = 20 + 20 + 30 + 10 - 40;
                    this.tableHeight = window.innerHeight - registerTabsHeight - publicBoxHeight - elPaginationHeight - tableHeaderHeight - guHeight;
                    break;
                case 'staging':
                    guHeight = 20 + 20 + 30 + 20 + 10 - 40;
                    this.tableHeight = window.innerHeight - elRadioGroup - registerTabsHeight - publicBoxHeight - elPaginationHeight - tableHeaderHeight - guHeight;
                    break;

                default:
                    guHeight = 20 + 20 + 20 + 75 - 40;
                    this.tableHeight = window.innerHeight - registerTabsHeight - publicBoxHeight - elPaginationHeight - tableHeaderHeight - guHeight;
                    break;
            }
        },
        getSchedulingCaseStep(urlPath) {
            let path = `/tpa/api/enum/` + urlPath;
            this.instance.get(path).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.caseStepOption = [];
                    data.data.forEach((item) => {
                        if (item.desc != '完成' && item.desc != '结案') {
                            this.caseStepOption.push(item);
                        }
                    });
                    this.navForm.caseStep = this.caseStepOption.length > 0 ? this.caseStepOption[1].code : '';
                    this.getTableData();
                }
            });
        },
        getSearchCaseStep() {
            this.instance.get('/tpa/api/enum/getSchedulingCaseStep').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.caseStepOption = [];
                    data.data.forEach((item) => {
                        if (item.desc != '完成' && item.desc != '结案') {
                            this.caseStepOption.push(item);
                        }
                    });
                    this.navForm.caseStep = this.caseStepOption.length > 0 ? this.caseStepOption[1].code : '';
                    this.getTableData();
                }
            });
        },
        addTab() {
            // 30立案 40定损 50核赔 90预立案
            this.editableTabs.push({
                title: '案件详情',
                menuTypeNum: '99'
            });
        },
        removeTab(targetName) {
            let tabs = this.editableTabs;

            this.handleFlag = false;
            this.clickTab({ name: this.menuTypeNum });
            if (targetName == '99') {
                localStorage.removeItem('detailShow');
                this.editableTabs = tabs.filter((tab) => tab.menuTypeNum !== targetName);
                this.clickTab({
                    name: this.tmpTabsValue
                });
            }
        },
        clickTab(tabRow) {
            if (tabRow.name == 99) {
                this.handleFlag = true;
            } else {
                let tmpIndex = this.editableTabs.findIndex((item) => {
                    return item.menuTypeNum == '99';
                });
                this.handleFlag = tabRow.name == 99 ? true : false;
                this.menuTypeNum = tabRow.name;
                this.editableTabsValue = tabRow.name;
                if (tmpIndex == -1) {
                    this.handleData.menuTypeNum = tabRow.name;
                }
            }
        },
        handleCase1(data) {
            this.handleData = JSON.parse(JSON.stringify(data));
            this.handleData.menuType = this.menuType;

            if (this.handleData && this.handleData.closeCaseDetail && this.handleData.tmpHandleData) {
                this.handleData = this.handleData.tmpHandleData;
            }

            // 添加详情tab
            let tmpIndex = this.editableTabs.findIndex((item) => item.menuTypeNum == '99');
            tmpIndex == -1 && this.addTab();
            this.editableTabsValue = '99';
            this.handleFlag = true;
        },
        closeCaseDetail(switchData) {
            switchData ? this.handleCase1(switchData) : this.removeTab('99');
        },
        //切换table选中事件
        handleSelectionChange(val) {
            this.multipleSelection = val;
            if (val.length > 0) {
                var total = 0;
                val.forEach((value, index) => {
                    total += value.totalAmount;
                });
                this.totalAmount = total;
            }
        },
        //点击查询
        searchTable() {
            this.pageNo = 1;
            this.getTableData();
        },
        // 查询改派任务人员
        searchDialogTable() {
            let tmpListData = [];
            if (this.dialogForm.userId && this.dialogForm.phone) {
                this.userTableData.length > 0 &&
                    this.userTableData.forEach((item) => {
                        if (this.dialogForm.userId == item.userId && this.dialogForm.phone == item.mobile) {
                            tmpListData.push(item);
                        }
                    });
            }
            if (this.dialogForm.userId) {
                this.userTableData.length > 0 &&
                    this.userTableData.forEach((item) => {
                        if (this.dialogForm.userId == item.userId) {
                            tmpListData.push(item);
                        }
                    });
            }
            if (this.dialogForm.phone) {
                this.userTableData.length > 0 &&
                    this.userTableData.forEach((item) => {
                        if (this.dialogForm.phone == item.mobile) {
                            tmpListData.push(item);
                        }
                    });
            }
            if (!this.dialogForm.userId && !this.dialogForm.phone) {
                this.tmpUserData = this.queryUserData;
                return;
            }
            this.tmpUserData = tmpListData;
        },
        resetDialogTable() {
            this.tmpUserData = this.queryUserData;
            this.$refs.dialogForm.resetFields();
        },
        // 调度/查看按钮
        handleCase(row) {
            this.tmpUserData = [];
            this.dialogFormVisible = true;
            this.assignData = {
                caseNo: row.caseNo,
                caseAmount: row.claimAmount,
                caseStep: row.caseStep,
                schedulingType: 0
            };
            // 获取指派人员列表
            this.getUserList(true);
        },
        // 指派按钮
        handleAssignCase(row) {
            let jsonData = {
                caseNo: this.assignData.caseNo,
                schedulingMobile: row.mobile,
                schedulingType: '0',
                schedulingUser: row.nickName,
                schedulingUserId: row.userId
            };
            this.instance.post('/tpa/api/case/task/scheduling', jsonData).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    data.data && this.$message.success('任务调度成功');
                    this.dialogFormVisible = false;
                    this.resetDialogTable();
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        closeDialog() {
            this.tmpUserData = [];
            this.dialogFormVisible = false;
            this.$refs.dialogForm.resetFields();
        },
        // 查看案件详情
        showCaseInfo(row) {
            this.tmpTabsValue = this.editableTabsValue;
            let detailStr = 0;
            // 40待定损 41核损中 42查勘 43查勘中 49 定损 -- 复用定损
            let lossPageArr = [40, 41, 42, 43, 49];
            if (lossPageArr.indexOf(row.caseStep) != -1) {
                detailStr = 1;
            }
            // 50待核赔 70结案 90已支付 -- 复用结案
            let closeCasePageArr = [50, 70, 90];
            if (closeCasePageArr.indexOf(row.caseStep) != -1) {
                detailStr = 2;
            }
            // 15预定损 45 75预报案 -- 复用预报案
            let forecastCasePageArr = [15, 45, 75];
            if (forecastCasePageArr.indexOf(row.caseStep) != -1) {
                detailStr = 3;
            }
            localStorage.setItem('detailShow', 'show');
            this.handleCase1({ caseNo: row.caseNo, menuTypeNum: this.menuTypeNum, detailStr: detailStr, detailShow: 'detailShow' });
        },
        //重置筛选项
        resetForm() {
            this.$refs.navForm.resetFields();
            this.navForm.caseStep = this.caseStepOption.length > 0 ? this.caseStepOption[1].code : '';
        },
        getTableData() {
            this.loading = true;
            let jsonData = {};
            var navForm = JSON.parse(JSON.stringify(this.navForm));
            jsonData.condition = navForm;
            jsonData.pageNo = this.pageNo;
            jsonData.pageSize = this.pageSize;
            this.instance.post('/tpa/api/case/paging', jsonData).then((res) => {
                this.loading = false;
                let data = res.data;
                if (res.data.code == 200 && res.data.data) {
                    this.tableData = res.data.data.list || [];
                    this.total = data.data.total;
                } else {
                    this.total = 0;
                }
            });
        },
        getUserList(queryFlag) {
            let jsonData = {};
            jsonData = queryFlag ? this.assignData : { schedulingType: 2 };
            this.instance.post('/tpa/api/case/task/searchUser', jsonData).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    if (!queryFlag) {
                        this.userTableData = data.data;
                    } else {
                        this.queryUserData = data.data;
                        this.tmpUserData = data.data;
                        this.dialogUserOption = [];
                        this.queryUserData.length > 0 &&
                            this.queryUserData.forEach((item) => {
                                this.dialogUserOption.push({
                                    username: item.username,
                                    userId: item.userId
                                });

                                item.roleDtoList.forEach((i) => {
                                    if (!i.attributes || !i.attributes.hasOwnProperty('minAmount')) {
                                        i.attributes = {
                                            minAmount: null,
                                            maxAmount: null
                                        };
                                    }
                                });

                                item.roleDtoList = this.handleAmountLevel(item.roleDtoList);
                            });
                    }
                }
            });
        },
        // 权限金额角色等级处理
        handleAmountLevel(roleDtoList) {
            if (roleDtoList && roleDtoList.length > 0) {
                roleDtoList.forEach((item, index) => {
                    item.tmpName = item.roleName;
                    this.amountLevelArr.forEach((res) => {
                        if (item.tmpName.indexOf(res.level) != -1) {
                            item.tmpName = item.tmpName.slice(2, item.tmpName.length);
                            item.level = res.level;
                            item.levelColor = res.levelColor;
                        }
                    });
                });
            }
            return roleDtoList;
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val;
            this.getTableData();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange(val) {
            this.pageNo = val;
            this.getTableData();
        },
        // 改派弹窗处理当前页
        handleDialogCurrentChange(val) {
            this.dialogForm.currentPage = val;
        },
        // 改派弹窗处理每页条数
        handleDialogSizeChange(val) {
            this.dialogForm.pageSize = val;
        }
    },
    watch: {
        editableTabsValue: {
            handler(val) {
                switch (val) {
                    case '1':
                        // this.getSchedulingCaseStep('getReportCaseStepEnum');
                        this.getSearchCaseStep();
                        break;
                    case '2':
                        this.getSchedulingCaseStep('getPreCaseStepEnum');
                        break;

                    default:
                        break;
                }
            },
            immediate: true
        }
    }
};
