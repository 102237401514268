
import OSS from 'ali-oss';
import * as echarts from 'echarts';
export default {
    props: {
        isCollapse: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            resetOssNum: 0,
            download0: false,
            download1: false,
            download2: false,
            download3: false,
            download4: false,
            download5: false,
            download6: false,
            download7: false,
            download8: false,
            caseSummaryLoading: false,
            monthlyClaimLoading: false,
            claimDataLoading: false,
            lossReasonDataLoading: false,
            reduceLossReasonDataLoading: false,
            riskShippingSiteLoading: false,
            riskDeliverySiteLoading: false,
            riskCaseAndCaseINflowLoading: false,
            navForm: {
                startTime: '',
                endTime: ''
            },
            pickerOptions: {
                disabledDate(time) {
                    const one = 24 * 3600 * 1000; //  一个月的时间间隔（毫秒数）
                    return time.getTime() > new Date().getTime() - one;
                }
            },
            caseSummartData: {
                totalCaseCount: null,
                totalClaimAmount: null,
                closedClaimAmount: null,
                closedAmount: null,
                lossAmount: null
            },
            monthlyClaimData: {},
            wanshangMonthlyClaimData: {},
            wanxiaMonthlyClaimData: {},
            monthlyClaimForm: {
                condition: {
                    startTime: null,
                    endTime: null
                },
                pageNo: 1,
                pageSize: 10
            },
            wanshangMonthlyClaimForm: {
                condition: {
                    startTime: null,
                    endTime: null
                },
                pageNo: 1,
                pageSize: 10
            },
            wanxiaMonthlyClaimForm: {
                condition: {
                    startTime: null,
                    endTime: null
                },
                pageNo: 1,
                pageSize: 10
            },
            chartForm: {
                condition: {
                    startTime: null,
                    endTime: null
                },
                pageNo: 1,
                pageSize: 10
            },
            pieForm: {
                startTime: null,
                endTime: null,
                riskName: null
            },
            lossReasonData: {},
            reduceLossReasonData: {},
            highRiskShippingSiteTop10Data: {},
            highRiskDeliverySiteTop10Data: {},
            riskTypeTitle: [],
            caseRiskData: {},
            caseOperationStatisticsData: {},
            caseOperationStatisticsMonthData: {},
            riskCaseAndCaseInflowData: {},
            activeName: '0',
            activeChart7Name: '按周',
            activeChart8Name: '按周',
            fileTime: 0,
            fileFLag: false,
            downloadType: 0,
            tmpOSSClient: null,
            myChartId0: null,
            myChartId1: null,
            myChartId2: null,
            myChartId3: null,
            myChartId4: null,
            myChartId5: null,
            myChartId6: null,
            myChartId7: null,
            myChartId8: null
        };
    },
    mounted() {
        this.initOSSClient();
        let today = new Date();
        today.setDate(today.getDate() - 1);
        this.navForm.startTime = '2023-06-01';
        this.navForm.endTime = this.formatDate(today);

        this.queryData();

        window.addEventListener('resize', () => {
            this.chartsResize();
        });
    },
    methods: {
        chartsResize() {
            this.largeStyle = window.innerHeight > 714;
            setTimeout(() => {
                this.myChartId0 && this.myChartId0.resize();
                this.myChartId1 && this.myChartId1.resize();
                this.myChartId2 && this.myChartId2.resize();
                this.myChartId3 && this.myChartId3.resize();
                this.myChartId4 && this.myChartId4.resize();
                this.myChartId5 && this.myChartId5.resize();
                this.myChartId6 && this.myChartId6.resize();
                this.myChartId7 && this.myChartId7.resize();
                this.myChartId8 && this.myChartId8.resize();
            }, 500);
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        queryData() {
            let tmpForm = JSON.parse(JSON.stringify(this.navForm));
            this.chartForm.condition = {
                startTime: tmpForm.startTime,
                endTime: tmpForm.endTime
            };
            this.monthlyClaimForm.pageNo = 1;
            this.wanshangMonthlyClaimForm.pageNo = 1;
            this.wanxiaMonthlyClaimForm.pageNo = 1;
            this.getCaseSummary();
            // 月度理赔数据
            this.getMonthlyClaimData();
            // 万上月度理赔数据
            this.getWanshangMonthlyClaimData();
            // 万下月度理赔数据
            this.getWanxiaMonthlyClaimData();
            // 定损原因数据统计
            this.getLossReasonData();
            // 减损原因数据统计
            this.getReduceLossReasonData();
            // 高风险发货站点
            this.getHighRiskShippingSiteTop10();
            // 高风险收货站点
            this.getHighRiskDeliverySiteTop10();
            // 获取风险类型标题
            this.getRiskTypeTitle();
            // 作业量总统计
            this.getCaseOperationStatistics();
            // 作业量按月统计
            this.getCaseOperationStatisticsByMonth();
        },
        downloadXlsx(type) {
            let instanceUrl = '';
            let instanceForm = {};
            this.fileFLag = false;
            switch (type) {
                case 0:
                    // 月理赔数据统计表格
                    instanceUrl = '/tpa/api/dashboard/fixed/monthlyClaimDataExcel';
                    instanceForm = this.monthlyClaimForm;
                    break;
                case 1:
                    // 定损原因表格
                    instanceUrl = '/tpa/api/dashboard/fixed/lossReasonDataExcel';
                    instanceForm = this.chartForm;
                    break;
                case 2:
                    // 核损原因表格
                    instanceUrl = '/tpa/api/dashboard/fixed/reduceLossReasonDataExcel';
                    instanceForm = this.chartForm;

                    break;
                case 3:
                    // 高风险发货站点表格
                    instanceUrl = '/tpa/api/dashboard/fixed/highRiskShippingSiteTop10Excel';
                    instanceForm = this.chartForm;

                    break;
                case 4:
                    // 高风险收货站点表格
                    instanceUrl = '/tpa/api/dashboard/fixed/highRiskDeliverySiteTop10Excel';
                    instanceForm = this.chartForm;

                    break;
                case 5:
                    // 风险汇总统计表格
                    instanceUrl = '/tpa/api/dashboard/fixed/caseRiskSummaryReportExcel';
                    instanceForm = this.chartForm;

                    break;
                case 6:
                    // 作业量统计表格
                    instanceUrl = '/tpa/api/dashboard/fixed/getMonthlyWorkloadStatisticsExcel';
                    instanceForm = this.navForm;

                    break;
                case 7:
                    // 万上月理赔数据统计表格
                    instanceUrl = '/tpa/api/dashboard/fixed/wanshangMonthlyClaimDataExcel';
                    instanceForm = this.wanshangMonthlyClaimForm;

                    break;
                case 8:
                    // 万下月理赔数据统计表格
                    instanceUrl = '/tpa/api/dashboard/fixed/wanxiaMonthlyClaimDataExcel';
                    instanceForm = this.wanxiaMonthlyClaimForm;

                    break;

                default:
                    break;
            }
            this['download' + type] = true;
            this.instance.post(instanceUrl, instanceForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.downloadFile(data.data, 'download' + type);
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        getCaseSummary() {
            let tmpForm = JSON.parse(JSON.stringify(this.navForm));
            this.caseSummartData = {};
            this.caseSummaryLoading = true;
            this.instance.post('/tpa/api/dashboard/fixed/caseSummary', tmpForm).then((res) => {
                this.caseSummaryLoading = false;
                let data = res.data;
                if (data.code == 200 && data.data) {
                    this.caseSummartData = data.data.data.list[0];
                    this.setChart0(this.caseSummartData);
                }
            });
        },
        getMonthlyClaimData() {
            this.claimDataLoading = true;
            let tmpForm = JSON.parse(JSON.stringify(this.navForm));
            this.monthlyClaimForm.condition = {
                startTime: tmpForm.startTime,
                endTime: tmpForm.endTime
            };
            this.monthlyClaimData = {};
            this.monthlyClaimLoading = true;
            this.instance.post('/tpa/api/dashboard/fixed/monthlyClaimData', this.monthlyClaimForm).then((res) => {
                this.monthlyClaimLoading = false;
                let data = res.data;
                this.claimDataLoading = false;
                if (data.code == 200 && data.data) {
                    this.monthlyClaimData = data.data;
                    this.monthlyClaimForm.pageTotal = data.data.data.total;
                }
            });
        },

        getWanshangMonthlyClaimData() {
            this.claimDataLoading = true;
            let tmpForm = JSON.parse(JSON.stringify(this.navForm));
            this.wanshangMonthlyClaimForm.condition = {
                startTime: tmpForm.startTime,
                endTime: tmpForm.endTime
            };
            this.wanshangMonthlyClaimData = {};
            this.monthlyClaimLoading = true;
            this.instance.post('/tpa/api/dashboard/fixed/wanshangMonthlyClaimData', this.wanshangMonthlyClaimForm).then((res) => {
                this.monthlyClaimLoading = false;
                let data = res.data;
                this.claimDataLoading = false;
                if (data.code == 200 && data.data) {
                    this.wanshangMonthlyClaimData = data.data;
                    this.wanshangMonthlyClaimForm.pageTotal = data.data.data.total;
                }
            });
        },

        getWanxiaMonthlyClaimData() {
            this.claimDataLoading = true;
            let tmpForm = JSON.parse(JSON.stringify(this.navForm));
            this.wanxiaMonthlyClaimForm.condition = {
                startTime: tmpForm.startTime,
                endTime: tmpForm.endTime
            };
            this.wanxiaMonthlyClaimData = {};
            this.monthlyClaimLoading = true;
            this.instance.post('/tpa/api/dashboard/fixed/wanxiaMonthlyClaimData', this.wanxiaMonthlyClaimForm).then((res) => {
                this.monthlyClaimLoading = false;
                let data = res.data;
                this.claimDataLoading = false;
                if (data.code == 200 && data.data) {
                    this.wanxiaMonthlyClaimData = data.data;
                    this.wanxiaMonthlyClaimForm.pageTotal = data.data.data.total;
                }
            });
        },

        getLossReasonData() {
            this.lossReasonDataLoading = true;
            this.instance.post('/tpa/api/dashboard/fixed/lossReasonData', this.chartForm).then((res) => {
                this.lossReasonDataLoading = false;
                let data = res.data;
                if (data.code == 200 && data.data) {
                    this.lossReasonData = data.data;
                    this.setChart1(this.lossReasonData);
                }
            });
        },
        getReduceLossReasonData() {
            this.reduceLossReasonDataLoading = true;
            this.instance.post('/tpa/api/dashboard/fixed/reduceLossReasonData', this.chartForm).then((res) => {
                this.reduceLossReasonDataLoading = false;
                let data = res.data;
                if (data.code == 200 && data.data) {
                    this.reduceLossReasonData = data.data;
                    this.setChart2(this.reduceLossReasonData);
                }
            });
        },
        getHighRiskShippingSiteTop10() {
            this.riskShippingSiteLoading = true;
            this.instance.post('/tpa/api/dashboard/fixed/highRiskShippingSiteTop10', this.chartForm).then((res) => {
                this.riskShippingSiteLoading = false;
                let data = res.data;
                if (data.code == 200 && data.data) {
                    this.highRiskShippingSiteTop10Data = data.data;
                    this.setChart3(this.highRiskShippingSiteTop10Data);
                }
            });
        },
        getHighRiskDeliverySiteTop10() {
            this.riskDeliverySiteLoading = true;
            this.instance.post('/tpa/api/dashboard/fixed/highRiskDeliverySiteTop10', this.chartForm).then((res) => {
                this.riskDeliverySiteLoading = false;
                let data = res.data || null;
                if (data.code == 200 && data.data) {
                    this.highRiskDeliverySiteTop10Data = data.data;
                    this.setChart4(this.highRiskDeliverySiteTop10Data);
                }
            });
        },
        getRiskTypeTitle() {
            this.instance.post('/tpa/api/dashboard/fixed/riskTypeTitle', {}).then((res) => {
                let data = res.data;
                if (data.code == 200 && data.data) {
                    this.riskTypeTitle = data.data;
                    // 根据选择的风险类型获取数据
                    this.getCaseRiskData();
                    // 风险类型占比
                    this.getRiskCaseAndCaseInflow();
                }
            });
        },
        getCaseRiskData() {
            let tmpForm = JSON.parse(JSON.stringify(this.navForm));
            this.chartForm.condition = {
                startTime: tmpForm.startTime,
                endTime: tmpForm.endTime,
                riskName: this.riskTypeTitle[this.activeName]
            };
            this.chartForm.pageSize = 100;
            this.instance.post('/tpa/api/dashboard/fixed/caseRiskStatistics', this.chartForm).then((res) => {
                let data = res.data;
                if (data.code == 200 && data.data) {
                    this.caseRiskData = data.data;
                    this.setChart5(this.caseRiskData);
                }
            });
        },
        getRiskCaseAndCaseInflow() {
            this.riskCaseAndCaseINflowLoading = true;

            let tmpForm = JSON.parse(JSON.stringify(this.navForm));
            this.pieForm = {
                startTime: tmpForm.startTime,
                endTime: tmpForm.endTime,
                riskName: this.riskTypeTitle[this.activeName]
            };
            this.instance.post('/tpa/api/dashboard/fixed/riskCaseAndCaseInflow', this.pieForm).then((res) => {
                this.riskCaseAndCaseINflowLoading = false;

                // let rightEl = document.querySelector('.el-icon-arrow-right');
                // if (rightEl) {
                //     let tabRightEl = document.querySelector('.tab_right');
                //     tabRightEl.style.top = '0px';
                // }
                let data = res.data;
                if (data.code == 200 && data.data) {
                    this.riskCaseAndCaseInflowData = data.data;
                    this.setChart6(this.riskCaseAndCaseInflowData);
                }
            });
        },
        getCaseOperationStatistics() {
            this.instance.post('/tpa/api/dashboard/fixed/caseOperationStatistics', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 200 && data.data) {
                    this.caseOperationStatisticsData = data.data;
                    this.setChart7(this.caseOperationStatisticsData);
                }
            });
        },
        getCaseOperationStatisticsByMonth() {
            this.chartForm.pageSize = 100;
            this.instance.post('/tpa/api/dashboard/fixed/caseOperationStatisticsByMonth', this.chartForm).then((res) => {
                let data = res.data;
                if (data.code == 200 && data.data) {
                    this.caseOperationStatisticsMonthData = data.data;
                    this.setChart8(this.caseOperationStatisticsMonthData);
                }
            });
        },
        // 初始化OSS
        initOSSClient() {
            this.tmpOSSClient = null;
            this.resetOssNum++;
            this.instance
                .get('/tpa/api/aliyun/oss/access')
                .then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.tmpOSSClient = new OSS({
                            accessKeyId: data.data.accessKeyId,
                            accessKeySecret: data.data.accessKeySecret,
                            stsToken: data.data.securityToken,
                            // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                            region: data.data.region,
                            bucket: data.data.bucket
                        });
                    } else {
                        console.log('oss初始化失败，正在重试！');

                        if (this.resetOssNum < 20) {
                            setTimeout(() => {
                                this.initOSSClient();
                            }, 1000);
                        } else {
                            console.log('oss初始化超过20s，失败!');
                        }
                    }
                })
                .catch((err) => {
                    console.log('oss初始化失败-');
                    console.log(err);
                });
        },
        downloadFile(fileKey, btnLoading) {
            if (this.tmpOSSClient) {
                this.isExistObject(fileKey);
                if (this.fileTime > 200) {
                    this.$message.error('文件下载失败，请稍后重试！');
                    return;
                }

                if (!this.fileFLag) {
                    setTimeout(() => {
                        this.fileTime++;
                        this.downloadFile(fileKey, btnLoading);
                    }, 3000);
                } else {
                    this.fileFLag = false;
                    this.fileTime = 0;
                    let fileUrl = this.tmpOSSClient.signatureUrl(fileKey);
                    this[btnLoading] = false;
                    location.href = fileUrl;
                }
            }
        },
        async isExistObject(name, options = {}) {
            try {
                await this.tmpOSSClient.head(name, options);
                this.fileFLag = true;
            } catch (error) {
                if (error.code === 'NoSuchKey') {
                    this.fileFLag = false;
                }
            }
        },
        handleCurrentChange(val) {
            this.monthlyClaimForm.pageNo = val;
            this.getMonthlyClaimData();
        },
        handleSizeChange(val) {
            this.monthlyClaimForm.pageSize = val;
            this.getMonthlyClaimData();
        },

        handleWanshangCurrentChange(val) {
            this.wanshangMonthlyClaimForm.pageNo = val;
            this.getWanshangMonthlyClaimData();
        },
        handleWanshangSizeChange(val) {
            this.wanshangMonthlyClaimForm.pageSize = val;
            this.getWanshangMonthlyClaimData();
        },

        handleWanxiaCurrentChange(val) {
            this.wanxiaMonthlyClaimForm.pageNo = val;
            this.getWanxiaMonthlyClaimData();
        },
        handleWanxiaSizeChange(val) {
            this.wanxiaMonthlyClaimForm.pageSize = val;
            this.getWanxiaMonthlyClaimData();
        },

        setChart0(response) {
            let chartDom = document.getElementById('chartId0');
            if (!chartDom) {
                return;
            }
            this.myChartId0 = echarts.init(chartDom);

            let option = {
                title: [
                    {
                        // 设置第一个图表标题
                        show: true,
                        text: `${response.lossRate ? response.lossRate : '--'}`,
                        left: 'center',
                        bottom: '10px',
                        textStyle: {
                            fontSize: 22,
                            color: '#14D087'
                        }
                    },
                    {
                        // 设置第二个图表标题
                        show: true,
                        text: '减损比',
                        left: 'center', // 设置为 'center' 使得标题在饼图上方
                        top: '30%',
                        textStyle: {
                            fontSize: 17
                        }
                    }
                ],
                tooltip: {
                    trigger: 'item'
                },
                color: ['#14D087', '#D2F6EA'],
                series: [
                    {
                        type: 'pie',
                        radius: ['110%', '140%'],
                        center: ['50%', '70%'],
                        startAngle: 180,
                        endAngle: 360,
                        label: {
                            show: false
                        },
                        data: [
                            { value: response.lossAmount ? Number(response.lossAmount.replace(/,/g, '')) : 0, name: '减损金额' },
                            { value: response.closedClaimAmount ? Number(response.closedClaimAmount.replace(/,/g, '')) : 0, name: '已结案索赔金额' }
                        ]
                    }
                ]
            };

            this.myChartId0.setOption(option);
        },
        setChart1(response) {
            let chartDom = document.getElementById('chartId1');
            if (!chartDom) {
                return;
            }
            this.myChartId1 = echarts.init(chartDom);

            let xAxisData = [];
            let seriesData1 = [];
            let seriesData2 = [];
            let seriesData3 = [];
            let seriesData4 = [];
            let yAxisMax = 0;
            if (response.data && response.data.list) {
                response.data.list.forEach((res) => {
                    xAxisData.push(res.lossReason);
                    // 结案金额
                    seriesData1.push(res.closeAmount);
                    // 差异金额
                    seriesData2.push(res.diffAmount);
                    // 案件数
                    seriesData3.push(res.caseCount);
                    // 索赔金额
                    seriesData4.push(res.claimAmount);
                });
            }
            yAxisMax = Math.max(...seriesData3);

            let axisLabelItem = {
                show: true,
                interval: 0 // 使x轴文字显示全
            };

            if (xAxisData.length > 8) {
                axisLabelItem = {
                    show: true,
                    interval: 0, // 使x轴文字显示全
                    formatter: function (params) {
                        var newParamsName = '';
                        var paramsNameNumber = params.length;
                        var provideNumber = 5; // 一行显示几个字
                        var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 计算需要几行显示
                        for (var i = 0; i < rowNumber; i++) {
                            var tempStr = '';
                            for (var j = 0; j < provideNumber; j++) {
                                var index = i * provideNumber + j;
                                if (index < paramsNameNumber) {
                                    tempStr += params[index];
                                }
                            }
                            newParamsName += tempStr + '\n'; // 换行
                        }
                        return newParamsName;
                    }
                };
            }

            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: xAxisData,
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel: axisLabelItem
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '金额（元）',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    }
                ],
                color: ['#7B80FE', '#6AC0FD', '#74C487'],
                series: [
                    {
                        name: '结案金额',
                        type: 'bar',
                        stack: 'total',
                        barMaxWidth: '28px',
                        data: seriesData1
                    },
                    {
                        name: '差异金额',
                        type: 'bar',
                        stack: 'total',
                        barMaxWidth: '28px',
                        data: seriesData2
                    },
                    {
                        name: '索赔金额',
                        type: 'bar',
                        barMaxWidth: '28px',
                        data: seriesData4
                    }
                ]
            };

            this.myChartId1.setOption(option);
        },
        setChart2(response) {
            let chartDom = document.getElementById('chartId2');
            if (!chartDom) {
                return;
            }
            this.myChartId2 = echarts.init(chartDom);

            let xAxisData = [];
            let seriesData1 = [];
            let seriesData2 = [];
            let seriesData3 = [];
            let seriesData4 = [];
            let yAxisMax = 0;
            if (response.data && response.data.list) {
                response.data.list.forEach((res) => {
                    xAxisData.push(res.lossReason);
                    // 结案金额
                    seriesData1.push(res.closeAmount);
                    // 差异金额
                    seriesData2.push(res.diffAmount);
                    // 案件数
                    seriesData3.push(res.caseCount);
                    // 索赔金额
                    seriesData4.push(res.claimAmount);
                });
            }
            yAxisMax = Math.max(...seriesData3);

            let axisLabelItem = {
                show: true,
                interval: 0 // 使x轴文字显示全
            };

            if (xAxisData.length > 8) {
                axisLabelItem = {
                    show: true,
                    interval: 0, // 使x轴文字显示全
                    formatter: function (params) {
                        var newParamsName = '';
                        var paramsNameNumber = params.length;
                        var provideNumber = 5; // 一行显示几个字
                        var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 计算需要几行显示
                        for (var i = 0; i < rowNumber; i++) {
                            var tempStr = '';
                            for (var j = 0; j < provideNumber; j++) {
                                var index = i * provideNumber + j;
                                if (index < paramsNameNumber) {
                                    tempStr += params[index] + ' ';
                                }
                            }
                            newParamsName += tempStr + '\n'; // 换行
                        }
                        return newParamsName;
                    }
                };
            }

            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: xAxisData,
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel: axisLabelItem
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '金额（元）',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    }
                ],
                color: ['#8375C4', '#6AC0FD', '#FAB533'],
                series: [
                    {
                        name: '结案金额',
                        type: 'bar',
                        stack: 'total',
                        barMaxWidth: '28px',
                        data: seriesData1
                    },
                    {
                        name: '差异金额',
                        type: 'bar',
                        stack: 'total',
                        barMaxWidth: '28px',
                        data: seriesData2
                    },
                    {
                        name: '索赔金额',
                        type: 'bar',
                        barMaxWidth: '28px',
                        data: seriesData4
                    }
                ]
            };

            this.myChartId2.setOption(option);
        },
        setChart3(response) {
            let chartDom = document.getElementById('chartId3');
            if (!chartDom) {
                return;
            }
            this.myChartId3 = echarts.init(chartDom);

            let xAxisData = [];
            let seriesData1 = [];
            let seriesData2 = [];
            let seriesData3 = [];
            let yAxisMax = 0;
            if (response.data && response.data.list) {
                response.data.list.forEach((res) => {
                    xAxisData.push(res.siteName);
                    // 报案量
                    seriesData1.push(res.reportCount);
                    // 投保量
                    seriesData2.push(res.insuredCount);
                    // 出险率
                    seriesData3.push(res.riskRate);
                });
            }
            yAxisMax = Math.max(...seriesData3);

            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '17%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: xAxisData,
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel: {
                            show: true,
                            interval: 0, // 使x轴文字显示全
                            formatter: function (params) {
                                var newParamsName = '';
                                var paramsNameNumber = params.length;
                                var provideNumber = 5; // 一行显示几个字
                                var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 计算需要几行显示
                                for (var i = 0; i < rowNumber; i++) {
                                    var tempStr = '';
                                    for (var j = 0; j < provideNumber; j++) {
                                        var index = i * provideNumber + j;
                                        if (index < paramsNameNumber) {
                                            tempStr += params[index];
                                        }
                                    }
                                    newParamsName += tempStr + '\n'; // 换行
                                }
                                return newParamsName;
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '案件量',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    },
                    {
                        type: 'value',
                        name: '出险率',
                        min: 0,
                        max: yAxisMax,
                        interval: 5,
                        axisLabel: {
                            formatter: '{value}%'
                        },
                        splitLine: {
                            show: false // 不显示Y轴网格线
                        }
                    }
                ],
                color: ['#74C487', '#2F7DE7', '#FF9A05'],
                series: [
                    {
                        name: '报案量',
                        type: 'bar',
                        barMaxWidth: '28px',
                        data: seriesData1
                    },
                    {
                        name: '投保量',
                        type: 'bar',
                        barMaxWidth: '28px',
                        data: seriesData2
                    },
                    {
                        name: '出险率',
                        type: 'line',
                        barMaxWidth: '28px',
                        yAxisIndex: 1,
                        data: seriesData3
                    }
                ]
            };

            this.myChartId3.setOption(option);
        },
        setChart4(response) {
            let chartDom = document.getElementById('chartId4');
            if (!chartDom) {
                return;
            }
            this.myChartId4 = echarts.init(chartDom);

            let xAxisData = [];
            let seriesData1 = [];
            let seriesData2 = [];
            let seriesData3 = [];
            let yAxisMax = 0;
            if (response.data && response.data.list) {
                response.data.list.forEach((res) => {
                    xAxisData.push(res.siteName);
                    // 报案量
                    seriesData1.push(res.reportCount);
                    // 投保量
                    seriesData2.push(res.insuredCount);
                    // 出险率
                    seriesData3.push(res.riskRate);
                });
            }
            yAxisMax = Math.max(...seriesData3);

            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    axisLabel: {
                        show: true,
                        interval: 0 // 使x轴文字显示全
                    }
                },
                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '17%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: xAxisData,
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel: {
                            show: true,
                            interval: 0, // 使x轴文字显示全
                            formatter: function (params) {
                                var newParamsName = '';
                                var paramsNameNumber = params.length;
                                var provideNumber = 5; // 一行显示几个字
                                var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 计算需要几行显示
                                for (var i = 0; i < rowNumber; i++) {
                                    var tempStr = '';
                                    for (var j = 0; j < provideNumber; j++) {
                                        var index = i * provideNumber + j;
                                        if (index < paramsNameNumber) {
                                            tempStr += params[index];
                                        }
                                    }
                                    newParamsName += tempStr + '\n'; // 换行
                                }
                                return newParamsName;
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '案件量',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    },
                    {
                        type: 'value',
                        name: '出险率',
                        min: 0,
                        max: yAxisMax,
                        interval: 5,
                        axisLabel: {
                            formatter: '{value}%'
                        },
                        splitLine: {
                            show: false // 不显示Y轴网格线
                        }
                    }
                ],
                color: ['#74C487', '#2F7DE7', '#FF9A05'],
                series: [
                    {
                        name: '报案量',
                        type: 'bar',
                        barMaxWidth: '28px',
                        data: seriesData1
                    },
                    {
                        name: '投保量',
                        type: 'bar',
                        barMaxWidth: '28px',
                        data: seriesData2
                    },
                    {
                        name: '出险率',
                        type: 'line',
                        barMaxWidth: '28px',
                        yAxisIndex: 1,
                        data: seriesData3
                    }
                ]
            };

            this.myChartId4.setOption(option);
        },
        tabPaneClick() {
            // 根据选择的风险类型获取数据
            this.getCaseRiskData();
            // 风险类型占比
            this.getRiskCaseAndCaseInflow();
        },
        setChart5(response) {
            let chartDom = document.getElementById('chartId5');
            if (!chartDom) {
                return;
            }
            this.myChartId5 = echarts.init(chartDom);

            let xAxisData = [];
            let weekSeriesObj = {};
            let tmpSeries = [];
            let tmpSeriesItem1 = [];
            let tmpSeriesItem2 = [];
            let seriesData1 = [];
            let seriesData2 = [];
            let seriesData3 = [];
            let monthWeekData = [];
            let maxWeekNum = 'null';
            let hoverStr = '';
            if (response && response.columns) {
                response.columns.forEach((forItem, forIndex) => {
                    if (forItem.title.indexOf('月') == -1) {
                        hoverStr = forItem.title;
                    }
                });
            }
            if (response.data && response.data.list) {
                response.data.list.forEach((res) => {
                    xAxisData.push(res.month);
                    weekSeriesObj[res.month] = {};
                    if (this.activeChart7Name == '按月') {
                        seriesData1.push(res.caseCount);
                    } else {
                        if (res.weekStatistics) {
                            // 找出每月最后一周
                            let tmpWeekData = [];
                            res.weekStatistics.forEach((itemData) => {
                                tmpWeekData.push(Number(itemData.week));
                            });
                            weekSeriesObj[res.month].weekData = tmpWeekData;
                            weekSeriesObj[res.month].weekMax = Math.max(...tmpWeekData);
                            weekSeriesObj[res.month].weekStatistics = res.weekStatistics;
                        }
                    }
                });

                // 周，最终处理数据
                if (this.activeChart7Name == '按周' && response.data.list.length > 0) {
                    // 找出数据中最大周数字
                    for (const key in weekSeriesObj) {
                        monthWeekData.push(weekSeriesObj[key].weekMax);
                    }
                    maxWeekNum = Math.max(...monthWeekData);

                    // 以最大的周为准，补全每周数据
                    for (const key in weekSeriesObj) {
                        seriesData1 = [];
                        seriesData2 = [];
                        for (let index = 0; index < maxWeekNum; index++) {
                            if (weekSeriesObj[key].weekData.indexOf(index + 1) != -1) {
                                weekSeriesObj[key].weekStatistics.forEach((weekItem) => {
                                    if (Number(weekItem.week) == index + 1) {
                                        seriesData1.push(weekItem.caseCount || 0);
                                    }
                                });
                            } else {
                                seriesData1.push(0);
                                seriesData2.push(0);
                            }
                            weekSeriesObj[key].seriesData1 = seriesData1;
                        }
                    }
                    // 处理数据 end

                    // 组合图表数据 - tmpSeries
                    for (let index = 0; index < maxWeekNum; index++) {
                        // 接案量
                        tmpSeriesItem1.push({
                            name: `第${index + 1}周`,
                            type: 'bar',
                            barMaxWidth: '28px',
                            stack: 'seriesData1',
                            data: []
                        });
                    }
                    for (const key in weekSeriesObj) {
                        weekSeriesObj[key].seriesData1.forEach((dataItem, dataIndex) => {
                            tmpSeriesItem1.forEach((tmpSeriesItem, tmpSeriesIndex) => {
                                if (tmpSeriesIndex == dataIndex) {
                                    tmpSeriesItem1[tmpSeriesIndex].data.push(dataItem);
                                }
                            });
                        });
                    }

                    tmpSeries = tmpSeriesItem1;
                } else {
                    tmpSeries = [
                        {
                            // name: '接案量',
                            type: 'bar',
                            barMaxWidth: '28px',
                            emphasis: {
                                focus: 'series'
                            },
                            data: seriesData1
                        }
                    ];
                }
            }
            let _this = this;
            let tooltipItem = {
                trigger: 'axis',
                formatter: function (params) {
                    let htmlStr = `
                            <p>${params[0].axisValue}<span style="margin-right:20px;"></span>${hoverStr}</p>
                        `;

                    if (_this.activeChart7Name == '按月') {
                        htmlStr = `
                            <p>${hoverStr}</p>
                        `;
                        params.forEach((resItem, resIndex) => {
                            htmlStr += `
                        <p><span style="display:inline-block;width:10px;height:10px;border-radius:50%;margin-right:10px;background:${resItem.color}"></span>${params[0].axisValue}<span style="margin-right:20px;"></span> ${resItem.value}</p>`;
                        });
                    } else {
                        params.forEach((resItem, resIndex) => {
                            htmlStr += `
                        <p><span style="display:inline-block;width:10px;height:10px;border-radius:50%;margin-right:10px;background:${resItem.color}"></span>${resItem.seriesName}<span style="margin-right:20px;"></span> ${resItem.value}</p>`;
                        });
                    }

                    return htmlStr;
                }
            };

            let option = {
                tooltip: tooltipItem,
                legend: {},
                xAxis: [
                    {
                        type: 'category',
                        data: xAxisData,
                        axisPointer: {
                            type: 'shadow'
                        },
                        axisLabel: {
                            interval: 0, // 确保所有标签都显示
                            textStyle: {
                                align: 'center' // 文本居中显示
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                grid: {
                    right: '2%'
                },
                color: ['#8375C4', '#6AC0FD', '#FAB533', '#5CAF33', '#C194F8', '#9BECFB'],
                series: tmpSeries
            };
            this.myChartId5.clear();
            this.myChartId5.setOption(option);
        },
        setChart6(response) {
            let chartDom = document.getElementById('chartId6');
            if (!chartDom) {
                return;
            }
            this.chartId6 = echarts.init(chartDom);

            let seriesData = [];
            let titleStr = '';
            if (response && response.columns) {
                response.columns.forEach((resItem, resIndex) => {
                    if (resItem.title.indexOf('占比') == -1) {
                        seriesData.push({
                            value: 0,
                            labelCode: resItem.code,
                            index: resIndex,
                            name: resItem.title
                        });
                    } else {
                        titleStr = response.data.list[0][resItem.code];
                    }
                });
            }
            if (response.data && response.data.list && response.data.list.length > 0) {
                seriesData.forEach((resItem, resIndex) => {
                    resItem.value = response.data.list[0][resItem.labelCode] ? response.data.list[0][resItem.labelCode] : 0;
                });
            }

            let option = {
                graphic: {
                    type: 'text',
                    left: 'center',
                    top: '85px',
                    style: {
                        text: titleStr,
                        textAlign: 'center',
                        fill: '#000',
                        fontSize: 18
                    }
                },
                tooltip: {
                    trigger: 'item'
                },
                color: ['#F7F7F7', '#448CE0'],
                series: [
                    {
                        type: 'pie',
                        center: ['50%', '30%'],
                        radius: ['30%', '40%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false
                        },
                        data: seriesData
                    }
                ]
            };

            this.chartId6.setOption(option);
        },
        setChart7(response) {
            let chartDom = document.getElementById('chartId7');
            if (!chartDom) {
                return;
            }
            this.myChartId7 = echarts.init(chartDom);

            let seriesData1 = [];
            if (response.data && response.data.list) {
                response.data.list.forEach((res) => {
                    // 接案量
                    seriesData1.push({
                        value: res.assignmentCaseCount,
                        itemStyle: {
                            color: '#FF9A05'
                        }
                    });
                    // // 定损案件量
                    // seriesData1.push({
                    //     value: res.assessmentCaseCount,
                    //     itemStyle: {
                    //         color: '#2F7DE7'
                    //     }
                    // });
                    // 结案量
                    seriesData1.push({
                        value: res.closeCaseCount,
                        itemStyle: {
                            color: '#5CAF33'
                        }
                    });
                });
            }

            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                xAxis: [
                    {
                        type: 'category',
                        data: ['接案量', '结案量'],
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                grid: {
                    left: '70px',
                    right: '2%',
                    bottom: 20
                },
                color: ['#FF9A05', '#2F7DE7', '#007F00'],
                series: [
                    {
                        type: 'bar',
                        barMaxWidth: '28px',
                        data: seriesData1
                    }
                ]
            };

            this.myChartId7.setOption(option);
        },
        radioClick(str, val) {
            this[str] = val;
            switch (str) {
                case 'activeChart7Name':
                    this.setChart5(this.caseRiskData);
                    break;
                case 'activeChart8Name':
                    this.setChart8(this.caseOperationStatisticsMonthData);

                    break;

                default:
                    break;
            }
        },
        setChart8(response) {
            let chartDom = document.getElementById('chartId8');
            if (!chartDom) {
                return;
            }
            this.myChartId8 = echarts.init(chartDom);

            let xAxisData = [];
            let weekSeriesObj = {};
            let tmpSeries = [];
            let tmpSeriesItem1 = [];
            let tmpSeriesItem2 = [];
            let tmpSeriesItem1Data = [];
            let tmpSeriesItem2Data = [];
            let seriesData1 = [];
            let seriesData2 = [];
            let seriesData3 = [];
            let monthWeekData = [];
            let maxWeekNum = 'null';
            if (response.data && response.data.list) {
                response.data.list.forEach((res) => {
                    xAxisData.push(res.month);
                    weekSeriesObj[res.month] = {};
                    if (this.activeChart8Name == '按月') {
                        // 接案量
                        seriesData1.push(res.assignmentCaseCount);
                        // 定损案件量
                        seriesData2.push(res.assessmentCaseCount);
                        // 结案量
                        seriesData3.push(res.closeCaseCount);
                    } else {
                        if (res.weekStatistics) {
                            // 找出每月最后一周
                            let tmpWeekData = [];
                            res.weekStatistics.forEach((itemData) => {
                                tmpWeekData.push(Number(itemData.week));
                            });
                            weekSeriesObj[res.month].weekData = tmpWeekData;
                            weekSeriesObj[res.month].weekMax = Math.max(...tmpWeekData);
                            weekSeriesObj[res.month].weekStatistics = res.weekStatistics;
                        }
                    }
                });

                // 周，最终处理数据
                if (this.activeChart8Name == '按周' && response.data.list.length > 0) {
                    // 找出数据中最大周数字
                    for (const key in weekSeriesObj) {
                        monthWeekData.push(weekSeriesObj[key].weekMax);
                    }
                    maxWeekNum = Math.max(...monthWeekData);

                    // 以最大的周为准，补全每周数据
                    for (const key in weekSeriesObj) {
                        seriesData1 = [];
                        seriesData2 = [];
                        for (let index = 0; index < maxWeekNum; index++) {
                            if (weekSeriesObj[key].weekData.indexOf(index + 1) != -1) {
                                weekSeriesObj[key].weekStatistics.forEach((weekItem) => {
                                    if (Number(weekItem.week) == index + 1) {
                                        seriesData1.push(weekItem.assignmentCaseCount || 0);
                                        seriesData2.push(weekItem.closeCaseCount || 0);
                                    }
                                });
                            } else {
                                seriesData1.push(0);
                                seriesData2.push(0);
                            }
                            weekSeriesObj[key].seriesData1 = seriesData1;
                            weekSeriesObj[key].seriesData2 = seriesData2;
                        }
                    }
                    // 处理数据 end

                    // 组合图表数据 - tmpSeries
                    for (let index = 0; index < maxWeekNum; index++) {
                        // 接案量
                        tmpSeriesItem1.push({
                            name: `第${index + 1}周`,
                            index: index,
                            type: 'bar',
                            barMaxWidth: '28px',
                            stack: 'seriesData1',
                            data: []
                        });

                        // 结案量
                        tmpSeriesItem2.push({
                            name: `第${index + 1}周`,
                            index: index,
                            type: 'bar',
                            barMaxWidth: '28px',
                            stack: 'seriesData2',
                            data: []
                        });
                    }
                    for (const key in weekSeriesObj) {
                        weekSeriesObj[key].seriesData1.forEach((dataItem, dataIndex) => {
                            tmpSeriesItem1.forEach((tmpSeriesItem, tmpSeriesIndex) => {
                                if (tmpSeriesIndex == dataIndex) {
                                    tmpSeriesItem1[tmpSeriesIndex].data.push(dataItem);
                                }
                            });
                        });
                        weekSeriesObj[key].seriesData2.forEach((dataItem, dataIndex) => {
                            tmpSeriesItem2.forEach((tmpSeriesItem, tmpSeriesIndex) => {
                                if (tmpSeriesIndex == dataIndex) {
                                    tmpSeriesItem2[tmpSeriesIndex].data.push(dataItem);
                                }
                            });
                        });
                    }

                    tmpSeriesItem1Data = JSON.parse(JSON.stringify(tmpSeriesItem1));
                    tmpSeriesItem2Data = JSON.parse(JSON.stringify(tmpSeriesItem2));
                    tmpSeriesItem1.push(...tmpSeriesItem2);
                    tmpSeries = tmpSeriesItem1;
                } else {
                    tmpSeries = [
                        {
                            name: '接案量',
                            type: 'bar',
                            barMaxWidth: '28px',
                            emphasis: {
                                focus: 'series'
                            },
                            data: seriesData1
                        },
                        // {
                        //     name: '定损案件量',
                        //     type: 'bar',
                        //     barMaxWidth: '28px',
                        //     emphasis: {
                        //         focus: 'series'
                        //     },
                        //     data: seriesData2
                        // },
                        {
                            name: '结案量',
                            type: 'bar',
                            barMaxWidth: '28px',
                            emphasis: {
                                focus: 'series'
                            },
                            data: seriesData3
                        }
                    ];
                }
            }
            let colorArr = ['#8375C4', '#6AC0FD', '#FAB533', '#5CAF33', '#C194F8', '#9BECFB'];
            let tooltipItem = {
                trigger: 'item',
                formatter: function (params) {
                    // hover 标题
                    let queryValFlag = '1';
                    tmpSeriesItem2Data.forEach((resitem2Data, resitem2Index) => {
                        if (resitem2Data.data.indexOf(params.value) != -1) {
                            queryValFlag = '2';
                        }
                    });

                    let htmlStr = `
                            <p>${params.name}<span style="margin-right:20px;"></span>${queryValFlag == '1' ? '接案量' : '结案量'}</p>   
                        `;

                    // hover 内容
                    if (queryValFlag == '1') {
                        for (let index = 0; index < maxWeekNum; index++) {
                            tmpSeriesItem1Data.forEach((resitem1Data, resitem1Index) => {
                                if (index == resitem1Index) {
                                    htmlStr += `
                                        <p><span style="display:inline-block;width:10px;height:10px;border-radius:50%;margin-right:10px;background:${colorArr[index]}"></span>${resitem1Data.name}<span style="margin-right:20px;"></span> ${resitem1Data.data[index]}</p>
                                    `;
                                }
                            });
                        }
                    } else {
                        for (let index = 0; index < maxWeekNum; index++) {
                            tmpSeriesItem2Data.forEach((resitem2Data, resitem2Index) => {
                                if (index == resitem2Index) {
                                    htmlStr += `
                                        <p><span style="display:inline-block;width:10px;height:10px;border-radius:50%;margin-right:10px;background:${colorArr[index]}"></span>${resitem2Data.name}<span style="margin-right:20px;"></span> ${resitem2Data.data[index]}</p>
                                    `;
                                }
                            });
                        }
                    }
                    return htmlStr;
                }
            };

            if (this.activeChart8Name == '按月') {
                tooltipItem = {
                    trigger: 'item'
                };
            }

            let option = {
                tooltip: tooltipItem,
                legend: {},
                xAxis: [
                    {
                        type: 'category',
                        data: xAxisData,
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                grid: {
                    right: '4%',
                    bottom: 20
                },
                color: colorArr,
                series: tmpSeries
            };
            this.myChartId8.clear();
            this.myChartId8.setOption(option);
        }
    },
    watch: {
        isCollapse: {
            handler(val) {
                this.chartsResize();
            },
            immediate: true
        }
    }
};
