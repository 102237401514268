
import OSS from 'ali-oss';
import ImgDeal from '@/components/ImgDeal.vue';
export default {
    name: 'myTask',
    props: {
        handleData: {
            type: Object,
            default: {}
        }
    },
    components: {
        ImgDeal
    },
    data() {
        return {
            stepActive: 1,
            caseFlowStep: [],
            caseDetail: {
                caseRiskVos: []
            },
            dialogVisibleRisk: false,
            riskTitle: '',
            OSSClient: '',
            checkedLookRisk: {
                imageList: []
            },
            materialTypeForm: {
                code: ''
            },
            innerVisible: false,
            fileTypeList: [],
            allPriceTableData: [],
            guidanceTableData: [],
            uploadList: [],
            allPriceVisible: false,
            drawer: false,
            caseLoading: false,
            checkedCase: {},
            caseMaterialsType: [], //材料类型
            goodsModeForm: {
                processMethod: 10,
                otherExpenses: []
            },
            dialogVisible: true,
            checkedInvoice: {
                voList: []
            },
            queryFileLoading: false,
            input: '',
            damagedProcessMethod: [],
            commodityLossList: [
                {
                    id: 1,
                    name: '商品名称',
                    num: '2',
                    type: '',
                    type1: 20,
                    type2: '200',
                    type3: '300',
                    type4: '30',
                    type5: '30%',
                    type6: '30%',
                    type7: '400'
                }
            ],
            damagedDegreeOption: [],
            suspensionBtnObj: {
                sort: 1,
                step: '立案'
            },
            submitDialogVisible: false,
            submitRuleForm: {
                action: '',
                reason: '',
                remark: ''
            },
            submitRules: {
                resource: [{ required: true, message: '请选择活动资源', trigger: 'change' }],
                region: [{ required: true, message: '请选择活动区域', trigger: 'change' }]
            },
            radioGroupList: [
                {
                    value: 'APPROVE',
                    label: '通过'
                },
                {
                    value: 'REJECT',
                    label: '不通过'
                }
            ],
            reasonOptionList: []
        };
    },
    mounted() {
        this.baseURL = this.$store.getters.baseURL;
        // 初始化阿里云
        this.initOSSClient();
        // 获取损失处理方式枚举
        this.getPublicEnum('getDamagedProcessMethodEnum', 'damagedProcessMethod');
        // 获取所有材料类型
        this.getFileTypeList();
        // 获取其他费用选项
        this.getOtherSelect();
        // 获取损失类型
        this.getDamagedDegreeEnum();
        // 获取案件详情
        this.handleData && this.handleCaseList(this.handleData.caseNo);
    },
    methods: {
        //获取案件录入信息
        getCaseEnter() {
            this.instance.get('/tpa/api/case/enter/adjust/getCaseEnter', { params: { caseNo: this.caseDetail.caseNo } }).then((res) => {
                if (res.data.code == 200) {
                    this.caseEnterData = res.data.data || {};
                    if (this.caseEnterData.caseNo) {
                        this.drawer = true;
                        this.goodsModeForm.compensationUnitPrice = this.caseEnterData.compensationUnitPrice;
                        this.goodsModeForm.processMethod = this.caseEnterData.processMethod;
                        this.calculateData();
                        this.goodsModeForm.damagedNum = this.caseEnterData.damagedNum;
                        this.goodsModeForm.damagedExtent = this.caseEnterData.damagedExtent;
                        this.goodsModeForm.deductibleRatio = this.caseEnterData.deductibleRatio;
                        this.goodsModeForm.damagedRatio = this.caseEnterData.damagedRatio;
                        this.goodsModeForm.accessoriesAmount = this.caseEnterData.accessoriesAmount;
                        this.goodsModeForm.repairAmount = this.caseEnterData.repairAmount;
                        this.goodsModeForm.otherExpenses = this.caseEnterData.otherExpenses;
                    }
                }
            });
        },
        // 定损计算
        lossCalculation() {
            let goodsTotalAmount = '1000';
            let detailAmount = '';
            let tmpGoodsDtoList = this.caseDetail.goodsDtoList;
            tmpGoodsDtoList &&
                tmpGoodsDtoList.forEach((item, index) => {
                    detailAmount += index != tmpGoodsDtoList.length - 1 ? item.adjustAmount + '+' : item.adjustAmount;
                });
            this.caseDetail.tmpTotalAmount = detailAmount.length > 0 ? detailAmount + ' = ' + goodsTotalAmount : '';
            this.$forceUpdate();
        },
        scrollFn() {
            // offsetTop距离上方或上层控件的位置
            let h = document.querySelector('#orderForm').offsetTop;
            console.log(h);
            // scrollTop滚动到指定位置
            // document.querySelector('#orderForm').scrollTo(0, 100)
            document.documentElement.scrollTop = h;
        },
        // 初始化OSS
        initOSSClient() {
            this.instance
                .get('/tpa/api/aliyun/oss/access')
                .then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        console.log('oss初始化成功');
                        this.OSSClient = new OSS({
                            accessKeyId: data.data.accessKeyId,
                            accessKeySecret: data.data.accessKeySecret,
                            stsToken: data.data.securityToken,
                            // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                            region: data.data.region,
                            bucket: data.data.bucket
                        });
                    } else {
                        console.log('oss初始化失败，正在重试！');
                        this.initOSSClient();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    console.log('oss初始化失败-');
                    this.$message.error('阿里云初始化失败，请稍后刷新页面重试！');
                });
        },
        //点击案件风险 查看按钮
        handleLookRisk(item, caseStep) {
            this.riskTitle = caseStep;
            this.dialogVisibleRisk = true;
            this.initImgUrl(item);
        },
        //初始化图片地址
        initImgUrl(item) {
            if (item.imageList) {
                item.imageList.forEach((val) => {
                    val.url = val.materialPath ? this.OSSClient.signatureUrl(val.materialPath) : val.materialOriginalPath;
                });
            }
            this.checkedLookRisk = item;
        },
        // 案件详情
        handleCaseList(caseNo) {
            this.allPriceTableData = [];
            this.guidanceTableData = [];
            this.uploadList = [];
            this.allPriceVisible = false;
            this.drawer = false;
            this.caseLoading = true;
            // this.checkedCase = item
            this.caseDetail = {};
            // this.goodsModeForm = this.$options.data().goodsModeForm // todo
            this.adjustmentForm = this.$options.data().adjustmentForm;
            this.adjustResult = this.$options.data().adjustResult;
            this.caseEnterData = this.$options.data().caseEnterData;
            this.instance
                .get('/tpa/api/case/detail/' + caseNo, {})
                .then((res) => {
                    this.caseLoading = false;
                    if (res.data.code === 200) {
                        this.initCaseDetail(res.data.data);
                    }
                })
                .catch((res) => {
                    this.caseLoading = false;
                    console.log(res);
                    this.$message.error('请求失败, 请稍后重试');
                });
        },
        //处理案件详情信息
        initCaseDetail(response) {
            response.materialsList = response.materialsList || [];
            response.caseRiskVos = response.caseRiskVos || [];
            this.caseDetail = response;
            this.submitRuleForm.caseNo = response.caseNo;

            // todo 模拟结构
            // 理赔信息 商品信息
            this.caseDetail.commodityList = [
                {
                    name: '商品分类',
                    name1: '商品名称',
                    name2: '新旧程度',
                    name3: '品牌型号',
                    name4: '丢损数量'
                },
                {
                    name: '商品分类',
                    name1: '商品名称',
                    name2: '新旧程度',
                    name3: '品牌型号',
                    name4: '丢损数量'
                }
            ];

            // 案件定损 商品损失信息
            this.caseDetail.goodsDtoList = [
                {
                    adjustAmount: '234', //理算金额
                    adjustLossAmount: '', //	定损金额
                    crippledVal: '', //残值
                    exemptRate: '', //免赔率
                    goodsName: '', //商品名称
                    goodsNum: '', //商品数量
                    injureType: '', //	损伤类型
                    insureRate: '', //投保比例
                    lossAmount: '', //损伤金额
                    lossRate: '', //损失率
                    price: '' //商品价格
                },
                {
                    adjustAmount: '123', //理算金额
                    adjustLossAmount: '', //	定损金额
                    crippledVal: '', //残值
                    exemptRate: '', //免赔率
                    goodsName: '', //商品名称
                    goodsNum: '', //商品数量
                    injureType: '', //	损伤类型
                    insureRate: '', //投保比例
                    lossAmount: '', //损伤金额
                    lossRate: '', //损失率
                    price: '' //商品价格
                }
            ];

            // 案件定损 其他费用
            // this.goodsModeForm.otherExpenses

            // 京东谈赔信息
            this.caseDetail.tanList = [{ name: '谈赔1' }, { name: '谈赔2' }];

            this.queryCaseNo = response.caseNo;
            this.caseDetail.goodsInfo && (this.goodsModeForm = Object.assign(this.goodsModeForm, this.caseDetail.goodsInfo));
            // this.getPublicEnum('getActionEnum', 'actionOption', response.caseStep) //获取案件步action
            this.getCaseStep(); //获取案件步骤条
            // this.getGoodsPriceRelations()
            this.getDamagedDegreeEnum();
            // this.getCaseEnter()

            // 初始化案件索赔材料
            this.caseDetail.materialsList.length > 0 && this.initMaterialsList();
            //过滤审核风险，组合原因
            if (this.caseDetail.caseRiskVos.length > 0) {
                let arr = [];
                this.caseDetail.caseRiskVos.forEach((value) => {
                    value.highRiskInfos && (arr = arr.concat(value.highRiskInfos));
                    value.mediumRiskInfos && (arr = arr.concat(value.mediumRiskInfos));
                    value.lowRiskInfos && (arr = arr.concat(value.lowRiskInfos));

                    // 数字为null时重置为0
                    value.highCount = value.highCount && value.highCount > 0 ? value.highCount : 0;
                    value.lowCount = value.lowCount && value.lowCount > 0 ? value.lowCount : 0;
                    value.mediumCount = value.mediumCount && value.mediumCount > 0 ? value.mediumCount : 0;
                    value.riskCount = value.riskCount && value.riskCount > 0 ? value.riskCount : 0;
                });
            }
        },
        //获取案件步骤条
        getCaseStep() {
            this.instance.get('/tpa/api/enum/getCaseStep', {}).then((res) => {
                if (res.data.code == 200) {
                    let caseStep = res.data.data;
                    let stepActive = 0;
                    if (caseStep.length > 0) {
                        caseStep.forEach((val, key) => {
                            val.caseStepList.forEach((value) => {
                                if (value.code == this.caseDetail.caseStep) {
                                    stepActive = key + 1;
                                }
                            });
                        });
                        stepActive > 0 && (this.stepActive = stepActive);
                    }
                    this.caseFlowStep = caseStep;

                    // 底部悬浮按钮
                    this.suspensionBtnObj = this.caseFlowStep[this.stepActive - 1];
                    this.suspensionBtnObj = {
                        sort: '',
                        step: '预报案'
                    };
                }
            });
        },
        //初始化案件索赔材料
        initMaterialsList() {
            this.caseMaterialsType.forEach((value) => {
                value.files = [];
                this.caseDetail.materialsList.forEach((val, key) => {
                    if (val.materialParentType == value.parentCode) {
                        value.enumList.forEach((o) => {
                            if (o.code == val.materialType) {
                                val.desc = o.desc;
                            }
                        });
                        val.url = val.materialPath ? this.OSSClient.signatureUrl(val.materialPath) : val.materialOriginalPath;
                        value.files.push(val);
                    }
                });
            });
        },
        //获取所有的材料类型
        getFileTypeList() {
            this.instance.get('/tpa/api/enum/getCaseMaterialsTypeEnum', {}).then((res) => {
                if (res.data.code == 200) {
                    let data = res.data.data;
                    let arr = [];
                    data.forEach((value) => {
                        value.remark = '';
                        value.enumList.forEach((val) => {
                            value.remark += val.desc + '、';
                        });
                        value.remark && (value.remark = '所有' + value.remark.slice(0, -1));
                        arr = arr.concat(value.enumList);
                    });
                    this.caseMaterialsType = data;
                    this.fileTypeList = arr;
                }
            });
        },
        // 立案处理
        handleRegister() {
            console.log('立案处理');
            console.log(this.handleData);
        },
        materialDialog() {
            console.log('tan');
            this.dialogVisible = true;
        },
        switchCarousel(data) {
            this.checkedInvoice.voList = data.files;
        },
        // 获取其他费用选项
        getOtherSelect() {
            this.instance.get('/tpa/api/enum/getOtherExpense', {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.otherSelectOption = data.data;
                }
            });
        },
        editOtherList(index, btnStr) {
            if (btnStr == 'del') {
                this.goodsModeForm.otherExpenses.splice(index, 1);
            } else if (this.goodsModeForm.otherExpenses.length < 16) {
                this.goodsModeForm.otherExpenses.push({
                    code: '',
                    expenseAmount: '',
                    expenseName: ''
                });
            }

            if (this.goodsModeForm.otherExpenses.length > 15) {
                this.$message.error('其他费用，目前仅支持添加15个费用');
            }
        },
        otherSelectChange(val) {
            var tmpIndex = this.otherSelectOption.findIndex((res) => {
                return val == res.code;
            });
            tmpIndex != -1 &&
                this.goodsModeForm.otherExpenses.forEach((item) => {
                    if (item.code == val) {
                        item.expenseName = this.otherSelectOption[tmpIndex].desc;
                    }
                });
        },
        //获取受损层度
        getDamagedDegreeEnum() {
            let json = {
                caseNo: this.caseDetail.caseNo
            };
            this.instance.get('/tpa/api/enum/getDamagedDegreeEnum', { params: json }).then((res) => {
                if (res.data.code == 200) {
                    this.damagedDegreeOption = res.data.data;
                }
            });
        },
        // 提交案件按钮
        submitCase(submitFlag) {
            // sort：3 step：定损
            // this.suspensionBtnObj.sort
            console.log(submitFlag);
            if (!submitFlag) {
                this.submitDialogVisible = true;
            } else {
                console.log(this.submitRuleForm);
                // action:  "APPROVE"
                // json.caseNo = this.caseDetail.caseNo
                // reason: ["通过"]
                // remark: ""
                this.instance.post('/tpa/api/case/action', json).then((res) => {
                    if (res.data.code == 200) {
                        this.submitDialogVisible = false;
                        // this.addAdjust()
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
            }
        }
    },
    watch: {
        handleData(val) {
            console.log(val);
            // 1立案处理 2案件详情 3定损处理 4理算处理
            switch (val.handleType) {
                case 1:
                    this.handleRegister();
                    break;

                default:
                    break;
            }
        }
    }
};
