
var _this = '';
import { v4 as uuidv4 } from 'uuid';
export default {
    name: 'caseEntry',
    components: {},
    data() {
        return {
            loading: false,
            modeForm: {
                //储存搜索框的检索条件信息
                materialsList: [],
                orderNo: '',
                insuranceNo: '',
                accTime: '',
                accAddress: '',
                branchPoint: '',
                businessType: null,
                accCause: '',
                accProcess: '',
                goodsCategoryId: null,
                goodsName: '',
                goodsNum: '',
                goodsAmount: '',
                insuredAmount: '',
                claimAmount: '',
                reporter: '',
                reportTime: '',
                signFor: null, // 1已签收 0未签收
                packingWhole: null, // 10完整 20轻微破损 30严重破损 99无外包装
                goodsLost: null, // 1丢失 0未丢失
                residualType: null, // 10客户 20保司
                damagedDuty: null // 0否 1是
            },
            materialsList: [],
            caseDetail: {},
            dialogImageUrl: '',
            dialogVisibleBigImg: false,
            provinceOptions: [],
            baseURL: '',
            unloaderHeader: { token: '' }, //上传的请求头
            loading: false,
            checkedCase: '1',
            casePageList: [],
            beforeUploadData: {},
            checkedUploadItem: '',
            businessTypeOption: [],
            receiveStatusOption: [],
            packingStatusOption: [],
            goodsLostStatusOption: [],
            residualProcessOption: [],
            damagedDutyOption: [],
            categoryTreeOption: [],
            uploadData: [
                {
                    id: 1,
                    title: '上传材料：',
                    src: require('../../../public/images/car-damage/success_icon.png'),
                    remark: '',
                    fileList: []
                }
            ],
            menuType: 'searchReviewPage',
            backstageFlag: false,
            backstageNum: 0
        };
    },
    methods: {
        backstageClick() {
            this.backstageNum++;
            if (this.backstageNum > 3) {
                this.backstageFlag = true;
                this.setFormData();
            }
            if (this.backstageNum > 5) {
                this.backstageNum = 0;
                this.backstageFlag = false;
            }
        },
        setFormData() {
            this.modeForm = {
                orderNo: 'JD202301723987637',
                insuranceNo: 'P202301312228787260003',
                accTime: '2023-01-21 12:55:23',
                accAddress: '陕西渭南市合阳县城关街道108号',
                branchPoint: '陕西渭南市合阳县城关街道网点',
                businessType: 30,
                accCause: '货运过程不当',
                accProcess: '运送过程磕碰导致商品受损',
                goodsCategoryId: 193,
                goodsName: 'iPhone13PROMAX',
                goodsNum: '1',
                goodsAmount: '4599',
                insuredAmount: '3000',
                claimAmount: '4599',
                reporter: '4599',
                reportTime: '2023-01-30 10:55:23',
                signFor: 1, // 1已签收 0未签收
                packingWhole: 10, // 10完整 20轻微破损 30严重破损 99无外包装
                goodsLost: 0, // 1丢失 0未丢失
                residualType: 10, // 10客户 20保司
                damagedDuty: 0 // 0否 1是
            };
        },
        //数据回显
        getEcho() {
            this.instance.get('/caseInfo/echo', {}).then((res) => {
                this.modeForm = res.data;
            });
        },
        //点击提交按钮 1
        handleSubmit() {
            let json = JSON.parse(JSON.stringify(this.modeForm));
            if (this.modeForm.goodsCategoryId && this.modeForm.goodsCategoryId.length > 1) {
                let tmpId = this.modeForm.goodsCategoryId[this.modeForm.goodsCategoryId.length - 1];
                json.goodsCategoryId = tmpId;
            }

            json.materialsList = this.materialsList;
            this.instance.post('/tpa/api/case/report', json).then((res) => {
                if (res.data.code == 200) {
                    this.$message.success(res.data.message);
                    // 录入成功后跳转页面
                    // if (!this.backstageFlag) {
                    //     this.$router.push({ path: '/workbench/firstAudit', query: {} })
                    // }
                } else {
                    this.$message(this.$$messageLineFeed(res.data.message));
                }
            });
        },
        //点击的哪个上传
        handleUpload(item) {
            this.checkedUploadItem = item;
        },
        //索赔资料处照片删除的回调
        handleRemove(file, fileList) {
            let materialsList = this.materialsList;
            let arr = [];
            materialsList.forEach((val) => {
                fileList.forEach((value) => {
                    if (value.name == val.materialName) {
                        arr.push(val);
                    }
                });
            });
            this.materialsList = arr;
        },
        //索赔资料处照片点击文件列表中已上传的文件时的钩子
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisibleBigImg = true;
        },
        //文件上传成功时的钩子
        handleAvatarSuccess(res, file) {
            console.log('上传成功的钩子');
            this.fullscreenLoading.close();
        },
        //上传文件之前的钩子
        beforeAvatarUpload(file) {
            if (file.size / 1024 > 5000) {
                this.$message.error('抱歉，上传文件不得大于5M！');
                return false;
            }
            this.fullscreenLoading = this.$loading({
                lock: true,
                text: '上传中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        //OSS直传
        ossUpload(e) {
            var _this = this;
            console.log(e.file);
            const OSS = require('ali-oss');
            let file = e.file;
            let storeAs = 'upload-file';
            let protocol = location.protocol;
            // OSS.urllib是SDK内部封装的发送请求的逻辑，开发者可以使用任何发送请求的库向sts-server发送请求。
            OSS.urllib.request(protocol + _this.baseURL + '/tpa/api/aliyun/oss/access', { method: 'GET' }, (err, response) => {
                try {
                    var result = JSON.parse(response).data;
                } catch (e) {
                    console.log('parse sts response info error: ' + e.message);
                }
                let client = new OSS({
                    accessKeyId: result.accessKeyId,
                    accessKeySecret: result.accessKeySecret,
                    stsToken: result.securityToken,
                    // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                    region: result.region,
                    bucket: result.bucket
                });
                // storeAs可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
                // file可以自定义为File对象、Blob数据以及OSS Buffer。
                storeAs = result.path + '/' + uuidv4() + '.' + file.name.split('.')[1];
                client
                    .multipartUpload(storeAs, file)
                    .then(function (result) {
                        _this.fullscreenLoading.close();
                        _this.materialsList.push({
                            materialName: file.name,
                            materialPath: storeAs
                        });
                    })
                    .catch(function (err) {
                        console.log(err);
                    });
            });
        }
    },
    mounted() {
        _this = this;
        this.baseURL = this.$store.getters.baseURL;
        this.getPublicEnum('getBusinessTypeEnum', 'businessTypeOption'); //获取业务类型
        this.getPublicEnum('getReceiveStatusEnum', 'receiveStatusOption'); //获取签收option
        this.getPublicEnum('getPackingStatusEnum', 'packingStatusOption'); //获取包装完好option
        this.getPublicEnum('getGoodsLostStatusEnum', 'goodsLostStatusOption'); //获取货物丢失option
        this.getPublicEnum('getResidualProcessEnum', 'residualProcessOption'); //获取残值归属option
        this.getPublicEnum('getDamagedDutyEnum', 'damagedDutyOption'); //获取冻伤责任option
        this.getCategoryTree(); //获取商品品类树option
    }
};
