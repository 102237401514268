
export default {
    data() {
        return {
            loading: true,
            iframeSrc1: '',
            iframeSrc2: '',
            resizeHeight: '653',
            editableTabsValue: '1',
            editableTabs: [
                {
                    title: '商品类别汇总分析',
                    menuTypeNum: '1'
                },
                {
                    title: '商品单个类别分析',
                    menuTypeNum: '2'
                }
            ],
            clickRefs: 'iframe1'
        };
    },
    mounted() {
        this.getDashboard();
        this.getHandlerDashboard();
        this.resizeHeight = window.innerHeight - 10 - 20 - 40 + 'px';
        window.onresize = () => {
            this.resizeHeight = window.innerHeight - 10 - 20 - 40 + 'px';
        };
    },
    methods: {
        getDashboard() {
            this.instance.get('/tpa/api/case/dashboard/caseGoodsCategorySummaryDashboard', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.iframeSrc1 = data.data;
                }
            });
        },
        getHandlerDashboard() {
            this.instance.get('/tpa/api/case/dashboard/caseGoodsCategoryDashboard', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.iframeSrc2 = data.data;
                }
            });
        },
        clickTab() {
            if (this.editableTabsValue == '1') {
                this.clickRefs = 'iframe1';
            } else {
                this.clickRefs = 'iframe2';
            }
        }
    },
    updated() {
        if (this.$refs[this.clickRefs]) {
            // IE
            if (this.$refs[this.clickRefs].attachEvent) {
                this.$refs[this.clickRefs].attachEvent('onload', () => {
                    // 加载成功
                    this.loading = false;
                });
            } else {
                this.$refs[this.clickRefs].onload = () => {
                    // 加载成功
                    this.loading = false;
                };
            }
        }
    }
};
