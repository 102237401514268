
//   import { listUser, getUser, delUser, addUser, updateUser, resetUserPwd, changeUserStatus, deptTreeSelect } from "@/api/system/user";
// import utils from '@/utils/auth';

export default {
    name: 'roleAdminHl',
    dicts: ['sys_normal_disable', 'sys_user_sex'],
    components: {},
    data() {
        return {
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 角色表格数据
            userList: null,
            // 弹出层标题
            title: '',
            // 部门树选项
            deptOptions: undefined,
            // 是否显示弹出层
            open: false,
            // 部门名称
            deptName: undefined,
            // 默认密码
            initPassword: undefined,
            // 日期范围
            dateRange: [],
            // 岗位选项
            postOptions: [],
            // 角色选项
            roleOptions: [],
            dictOption: [],
            // 表单参数
            form: {
                attributes: {
                    isOperator: '1',
                    minAmount: '',
                    maxAmount: ''
                },
                minAmount: '',
                maxAmount: ''
            },
            defaultProps: {
                children: 'children',
                label: 'menuName'
            },
            // 菜单列表
            menuOptions: [],
            // 角色导入参数
            upload: {
                // 是否显示弹出层（角色导入）
                open: false,
                // 弹出层标题（角色导入）
                title: '',
                // 是否禁用上传
                isUploading: false,
                // 是否更新已经存在的角色数据
                updateSupport: 0,
                // 设置上传的请求头部
                headers: { Authorization: 'Bearer ' + this.$$getToken() },
                // 上传的地址
                url: process.env.VUE_APP_BASE_API + '/system/user/importData'
            },
            dataScopeOptions: [
                {
                    value: '1',
                    label: '全部数据权限'
                },
                {
                    value: '2',
                    label: '自定数据权限'
                },
                {
                    value: '3',
                    label: '本部门数据权限'
                },
                {
                    value: '4',
                    label: '本部门及以下数据权限'
                },
                {
                    value: '5',
                    label: '仅本人数据权限'
                }
            ],
            menuExpand: false,
            menuNodeAll: false,
            // 是否显示弹出层（数据权限）
            openDataScope: false,
            // 查询参数
            queryParams: {
                pageNo: 1,
                pageSize: 10,
                startCreateTime: '',
                endCreateTime: '',
                roleName: undefined,
                roleKey: undefined,
                status: undefined,
                attributes: {
                    minAmount: '',
                    maxAmount: ''
                }
            },
            // 表单校验
            rules: {
                roleName: [
                    { required: true, message: '角色名称不能为空', trigger: 'blur' },
                    { min: 2, max: 20, message: '角色名称长度必须介于 2 和 20 之间', trigger: 'blur' }
                ],
                minAmount: [{ required: true, message: '最小金额不能为空', trigger: 'blur' }],
                maxAmount: [{ required: true, message: '最大金额不能为空', trigger: 'blur' }],
                nickName: [{ required: true, message: '角色昵称不能为空', trigger: 'blur' }],
                password: [
                    { required: true, message: '角色密码不能为空', trigger: 'blur' },
                    { min: 5, max: 20, message: '角色密码长度必须介于 5 和 20 之间', trigger: 'blur' }
                ],
                email: [
                    {
                        type: 'email',
                        message: '请输入正确的邮箱地址',
                        trigger: ['blur', 'change']
                    }
                ],
                phoneNumber: [
                    {
                        pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                        message: '请输入正确的手机号码',
                        trigger: 'blur'
                    }
                ]
            },
            roleList: [],
            menuKeyFlag: false,
            permissionAmount: '',
            amountObj: {},
            amountOptions: [
                {
                    value: '选项1',
                    label: '黄金糕'
                },
                {
                    value: '选项2',
                    label: '双皮奶'
                }
            ]
        };
    },
    watch: {},
    created() {
        this.getList();
        this.getMenuTreeselect();
    },
    mounted() {
        this.getAmountRange();
    },
    methods: {
        getAmountRange() {
            this.instance.get('/upms/api/role/attribute/amountRange').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.amountObj = {};
                    this.amountOptions = data.data;
                    data.data.forEach((item, index) => {
                        item.code = index;
                        this.amountObj[index] = item;
                    });
                }
            });
        },
        handleCheckedTreeNodeAll() {
            this.roleList = [];
            // 判断按钮的状态
            if (this.menuNodeAll) {
                this.form.menuIdList = [];
                this.$refs.menu.setCheckedNodes(this.menuOptions);
                for (var i = 0; i < this.$refs.menu.getCheckedNodes().length; i++) {
                    this.roleList.push(this.$refs.menu.getCheckedNodes()[i].menuId);
                    this.form.menuIdList.push(this.$refs.menu.getCheckedNodes()[i].menuId);
                }
                this.roleList = this.roleList.slice(0, this.roleList.length - 1);
            } else {
                this.$refs.menu.setCheckedNodes([]);
                this.form.menuIdList = this.roleList = [];
            }
        },
        handleCheckedTreeExpand() {
            if (this.menuExpand) {
                for (var i in this.$refs.menu.store.nodesMap) {
                    this.$refs.menu.store.nodesMap[i].expanded = true;
                }
            } else {
                for (var j in this.$refs.menu.store.nodesMap) {
                    this.$refs.menu.store.nodesMap[j].expanded = false;
                }
            }
        },
        /** 查询角色列表 */
        getList() {
            this.loading = true;
            this.tableData = []; //  清空table的值
            var queryParams = JSON.parse(JSON.stringify(this.queryParams));
            let json = {
                condition: queryParams,
                pageNo: queryParams.pageNo,
                pageSize: queryParams.pageSize
            };
            this.instance.post('/upms/api/role/paging', json).then((res) => {
                this.loading = false;
                this.userList = res.data.data.list;
                this.total = parseInt(res.data.data.total);
            });
        },
        // 角色状态修改
        handleStatusChange(row, index) {
            let text = row.status === '0' ? '启用' : '停用';
            this.$modal
                .confirm('确认要 "' + text + '" "' + row.roleName + '" 角色吗？')
                .then(() => {
                    let json = {
                        roleId: row.roleId,
                        status: row.status
                    };
                    this.instance.post('/upms/api/role/changeStatus', json).then((res) => {
                        let data = res.data;
                        if (data.code == 200) {
                            this.$message.success(data.message);
                        } else {
                            // 失败后还原状态
                            row.status = row.status == '1' ? '0' : '1';
                            this.$message.error(data.message);
                        }
                        this.$set(this.userList, index, row);
                    });
                })
                .catch(() => {
                    row.status = row.status == '1' ? '0' : '1';
                    this.$set(this.userList, index, row);
                });
        },
        /** 查询菜单树结构 */
        getMenuTreeselect() {
            this.instance.get('/upms/api/menu/getMenuTreeByTenantId', {}).then((res) => {
                if (res.data.code == 200) {
                    this.menuOptions = res.data.data;
                    this.menuOptions.forEach((item) => {
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((res) => {
                                if (res.menuName == '案件详情') {
                                    res.disabled = true;
                                }
                            });
                        }
                        if (item.menuName == '案件查询') {
                            item.disabled = true;
                        }
                    });
                }
            });
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 取消按钮（数据权限）
        cancelDataScope() {
            this.openDataScope = false;
            this.reset();
        },
        /** 选择角色权限范围触发 */
        dataScopeSelectChange(value) {
            if (value !== '2') {
                this.$refs.dept.setCheckedKeys([]);
            }
        },
        /** 提交按钮（数据权限） */
        submitDataScope() {
            if (this.form.roleId != undefined) {
                this.form.deptIds = this.getDeptAllCheckedKeys();
                dataScope(this.form).then((response) => {
                    this.$modal.msgSuccess('修改成功');
                    this.openDataScope = false;
                    this.getList();
                });
            }
        },
        // 表单重置
        reset() {
            (this.menuExpand = false),
                (this.menuNodeAll = false),
                (this.form = {
                    roleId: undefined,
                    roleName: undefined,
                    roleKey: undefined,
                    roleSort: 0,
                    status: '0',
                    menuIdList: [],
                    menuCheckStrictly: true,
                    deptCheckStrictly: true,
                    remark: undefined,
                    attributes: {
                        isOperator: '1',
                        minAmount: '',
                        maxAmount: ''
                    },
                    minAmount: '',
                    maxAmount: ''
                });
            setTimeout(() => {
                this.$refs.menu.setCheckedNodes([]);
                this.$refs.menu.setChecked(2055, true, false);
                this.$refs.menu.setChecked(2120, true, false);
            }, 200);
            this.resetForm('form');
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNo = 1;
            // 处理权限金额
            this.queryParams.attributes.minAmount = String(this.permissionAmount).length > 0 ? this.amountObj[this.permissionAmount].minAmount : '';
            this.queryParams.attributes.maxAmount = String(this.permissionAmount).length > 0 ? this.amountObj[this.permissionAmount].maxAmount : '';
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.dateRange = [];
            this.resetForm('queryForm');
            this.queryParams.deptId = undefined;
            this.permissionAmount = '';
            this.queryParams.attributes.minAmount = '';
            this.queryParams.attributes.maxAmount = '';
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.userId);
            this.single = selection.length != 1;
            this.multiple = !selection.length;
        },
        // 更多操作触发
        handleCommand(command, row) {
            switch (command) {
                case 'handleResetPwd':
                    this.handleResetPwd(row);
                    break;
                case 'handleAuthRole':
                    this.handleAuthRole(row);
                    break;
                default:
                    break;
            }
        },
        /** 新增按钮操作 */
        handleAdd() {
            this.reset();
            this.open = true;
            this.title = '添加角色';
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.instance.get('/upms/api/role/getRoleDetail', { params: { roleId: row.roleId } }).then((res) => {
                if (res.data.code == 200) {
                    let response = res.data.data;
                    this.form = response;
                    // 表单数据处理
                    response.attributes = response.attributes ? response.attributes : { isOperator: '1' };
                    response.minAmount = response.attributes ? response.attributes.minAmount : '';
                    response.maxAmount = response.attributes ? response.attributes.maxAmount : '';
                    this.form = JSON.parse(JSON.stringify(response));
                    this.open = true;
                    this.title = '修改角色';
                    if (response.menuIdList.length > 0) {
                        setTimeout(() => {
                            this.$refs.menu.setCheckedNodes([]);
                            response.menuIdList.forEach((val) => {
                                this.$refs.menu.setChecked(val, true, false);
                            });
                            this.$refs.menu.setChecked(2055, true, false);
                            this.$refs.menu.setChecked(2120, true, false);
                        }, 200);
                    }
                } else {
                    this.$modal.msgError(res.data.message);
                }
            });
        },
        /** 重置密码按钮操作 */
        handleResetPwd(row) {
            this.$prompt('请输入"' + row.userName + '"的新密码', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal: false,
                inputPattern: /^.{5,20}$/,
                inputErrorMessage: '角色密码长度必须介于 5 和 20 之间'
            })
                .then(({ value }) => {
                    resetUserPwd(row.userId, value).then((response) => {
                        this.$modal.msgSuccess('修改成功，新密码是：' + value);
                    });
                })
                .catch(() => {});
        },
        /** 分配角色操作 */
        handleAuthRole: function (row) {
            const userId = row.userId;
            this.$router.push('/system/user-auth/role/' + userId);
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs['form'].validate((valid) => {
                // 权限菜单校验
                this.form.menuIdList = this.getMenuAllCheckedKeys();
                this.form.attributes.minAmount = this.form.attributes.isOperator == '0' ? '' : this.form.minAmount;
                this.form.attributes.maxAmount = this.form.attributes.isOperator == '0' ? '' : this.form.maxAmount;
                this.menuKeyFlag = this.form.menuIdList.length > 0 ? false : true;
                // 表单校验结果
                if (valid && !this.menuKeyFlag) {
                    if (this.form.roleId != undefined) {
                        this.instance.post('/upms/api/role/edit', this.form).then((res) => {
                            if (res.data.code == 200) {
                                this.$modal.msgSuccess('修改成功');
                                this.open = false;
                                this.getList();
                            } else {
                                this.$modal.msgError(res.data.message);
                            }
                        });
                    } else {
                        this.instance.post('/upms/api/role/add', this.form).then((res) => {
                            if (res.data.code == 200) {
                                this.$modal.msgSuccess('新增成功');
                                this.open = false;
                                this.getList();
                            } else {
                                this.$modal.msgError(res.data.message);
                            }
                        });
                    }
                }
            });
        },
        // 所有菜单节点数据
        getMenuAllCheckedKeys() {
            // 目前被选中的菜单节点
            let checkedKeys = this.$refs.menu.getCheckedKeys();
            // 半选中的菜单节点
            let halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys();
            checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
            return checkedKeys;
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const userIds = row.userId || this.ids;
            this.$modal
                .confirm('是否确认删除角色编号为"' + userIds + '"的数据项？')
                .then(function () {
                    return delUser(userIds);
                })
                .then(() => {
                    this.getList();
                    this.$modal.msgSuccess('删除成功');
                })
                .catch(() => {});
        },
        /** 导出按钮操作 */
        handleExport() {
            this.download(
                'system/user/export',
                {
                    ...this.queryParams
                },
                `user_${new Date().getTime()}.xlsx`
            );
        },
        /** 导入按钮操作 */
        handleImport() {
            this.upload.title = '角色导入';
            this.upload.open = true;
        },
        /** 下载模板操作 */
        importTemplate() {
            this.download('system/user/importTemplate', {}, `user_template_${new Date().getTime()}.xlsx`);
        },
        // 文件上传中处理
        handleFileUploadProgress(event, file, fileList) {
            this.upload.isUploading = true;
        },
        // 文件上传成功处理
        handleFileSuccess(response, file, fileList) {
            this.upload.open = false;
            this.upload.isUploading = false;
            this.$refs.upload.clearFiles();
            this.$alert("<div style='overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px 0;'>" + response.msg + '</div>', '导入结果', { dangerouslyUseHTMLString: true });
            this.getList();
        },
        // 提交上传文件
        submitFileForm() {
            this.$refs.upload.submit();
        },
        // 最小金额 < 最大金额
        warNumber() {
            if (Number(this.form.minAmount) >= Number(this.form.maxAmount) && this.form.maxAmount > 0) {
                this.form.minAmount = '';
            }
        },
        handleCurrentChange(val) {
            this.queryParams.pageNo = val;
            this.getList();
        },
        handleSizeChange(val) {
            this.queryParams.pageSize = val;
            this.getList();
        }
    }
};
