
// @ is an alias to /src
import QualityTesting from '@/components/QualityTesting.vue';
import OSS from 'ali-oss';
import { v4 as uuidv4 } from 'uuid';

var _this = '';
export default {
    name: 'myTask',
    components: {
        QualityTesting
    },
    data() {
        let checkDamagedNum = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('不能为空'));
            }
            setTimeout(() => {
                if (!Number.isInteger(value)) {
                    callback(new Error('请输入数字值'));
                } else {
                    if (value > this.caseDetail.goodsInfo.goodsNum) {
                        callback(new Error('应该-小于等于-商品信息的数量/重量'));
                    } else if (value < 0) {
                        callback(new Error('应该-大于-0'));
                    } else {
                        callback();
                    }
                }
            }, 100);
        };
        return {
            materialFlag: false,
            caseLoading: false,
            height: '466',
            titleMaterialType: '修改材料类型',
            baseURL: '',
            adjustmentDisabled: false,
            dialogVisibleEntry: false,
            allPriceVisible: false,
            qualityTestingVisible: false,
            drawer: false,
            isQualityTesting: false,
            dialogVisible: false,
            dialogVisibleRisk: false,
            innerVisible: false,
            dialogVisibleAddGoods: false,
            dialogVisibleAddGoodsCategory: false,
            dialogVisibleMaterialType: false,
            dialogImageUrl: '',
            dialogVisibleBigImg: false,
            riskTitle: '立案风险',
            activeNames: '1',
            searchGoodsName: '',
            categoryRadio: 1,
            unloaderHeader: { token: '', caseNo: 'CASE1646054994054553600' }, //上传的请求头
            loading: false,
            checkedCase: '1',
            casePageList: [],
            beforeUploadData: {},
            checkedUploadItem: '',
            checkedSubmitSign: '',
            totalPage: 1,
            currentPage: 1,
            pageSize: 10,
            caseDetail: {
                caseRiskVos: [],
                stepLogList: [],
                insuranceInfo: {},
                waybillInfo: {},
                bankInfo: {},
                goodsInfo: {}
            },
            tabsActiveName: 'second',
            queryCaseNo: '',
            checkedMaterialType: {},
            provinceOptions: [],
            checkedLookRisk: {},
            unidentificationFile: [],
            guidanceTableData: [],
            uploadData: [
                {
                    id: 1,
                    title: '证件材料：',
                    src: require('../../../public/images/car-damage/success_icon.png'),
                    remark: '包括驾驶证、行驶本、身份证',
                    fileList: []
                },
                {
                    id: 2,
                    title: '现场/事故材料、标的损失材料：',
                    src: require('../../../public/images/car-damage/success_icon.png'),
                    fileList: [],
                    remark: '包括货物损失证明材料、事故/现场照片'
                },
                {
                    id: 3,
                    title: '标准化理赔材料：',
                    src: require('../../../public/images/car-damage/success_icon.png'),
                    fileList: [],
                    remark: '包括索赔申请书、运单、货物价值证明、残值处理证明、支付信息证明'
                },
                {
                    id: 4,
                    title: '非标理赔材料：',
                    src: require('../../../public/images/car-damage/success_icon.png'),
                    fileList: [],
                    remark: '包括货物交接记录、事故证明文件、承运人事故签证、索赔权益转让书、向责任方的索赔函'
                },
                {
                    id: 5,
                    title: '未识别文件：',
                    src: require('../../../public/images/car-damage/success_icon.png'),
                    fileList: [],
                    remark: ''
                }
            ],
            addGoodsForm: {
                mall: '',
                price: '',
                shopName: '',
                goodsCategoryId: '',
                goodsName: ''
            },
            adjustmentForm: {
                claimAmount: '',
                insuredAmount: '',
                goodsTotalAmount: '',
                deductibleRatio: '',
                damagedRatio: '',
                payoutRatioOne: '',
                payoutRatioTwo: '',
                residualValue: '',
                trialAmount: '',
                confirmAmount: ''
            },
            addGoodsCategoryForm: {
                name: '',
                parentId: ''
            },
            materialTypeForm: {
                code: ''
            },
            claimForm: {
                reason: [],
                remark: ''
            },
            goodsModeForm: {
                goodsCategoryName: '',
                goodsName: '',
                compensationUnitPrice: '',
                damagedNum: '',
                damagedExtent: '',
                deductibleRatio: '',
                damagedRatio: '',
                accessoriesAmount: null,
                repairAmount: null,
                processMethod: 10,
                //储存搜索框的检索条件信息
                materialsList: []
            },
            materialsList: [],
            navForm: {
                caseNo: '',
                caseStep: '31'
            },
            OSSClient: '',
            actionOption: [],
            caseMaterialsType: [], //材料类型
            uploadList: [], // 补传文件列表
            adjustCaseRisk: [],
            adjustResult: {},
            stepActive: 0,
            caseFlowStep: [],
            multipleSelection: [],
            goodsPriceRelations: {},
            damagedDegreeOption: [],
            caseEnterData: {},
            fileTypeList: [],
            categoryTreeOption: [],
            firstCategoryOption: [],
            mallOption: [],
            reasonAudit: [],
            packingStatusOption: [],
            goodsLostStatusOption: [],
            receiveStatusOption: [],
            residualProcessOption: [],
            damagedDutyOption: [],
            fristReasonAudit: [],
            reviewReasonAudit: [],
            enterReasonAudit: [],
            damagedProcessMethod: [],
            goodsModeFormRules: {
                damagedNum: [{ validator: checkDamagedNum, trigger: 'blur' }],
                deductibleRatio: [
                    { required: true, message: '请输入商品免赔比例', trigger: 'blur' },
                    { min: 0, max: 100, type: 'number', message: '免赔比例应该在 0-100 之间', trigger: 'blur' }
                ],
                damagedRatio: [
                    { required: true, message: '请输入商品损失比例', trigger: 'blur' },
                    { min: 0, max: 100, type: 'number', message: '损失比例应该在 0-100 之间', trigger: 'blur' }
                ]
                // accessoriesAmount:[
                // 	{ required: true, message: '请输入配件费用', trigger: 'blur' },
                // 	{ min:0,max:900000000, type:'number', message: '配件费用为 0-900000000', trigger: 'blur' },
                // ],
                // repairAmount:[
                // 	{ required: true, message: '请输入人工费用', trigger: 'blur' },
                // 	{ min:0,max:9, type:'number', message: '人工费用为 0-900000', trigger: 'blur' },
                // ]
            },
            tmpLimitDto: null,
            subLisuan: false
        };
    },
    methods: {
        onlyNumber(key, value) {
            this.goodsModeForm[key] = this.onlyNumOnePoint(value);
        },
        onlyNumOnePoint(number_only) {
            //先把非数字的都替换掉，除了数字和小数点
            number_only = number_only.replace(/[^\d.]/g, '');
            //必须保证第一个为数字而不是小数点
            number_only = number_only.replace(/^\./g, '');
            //保证只有出现一个小数点而没有多个小数点
            number_only = number_only.replace(/\.{2,}/g, '.');
            //保证小数点只出现一次，而不能出现两次以上
            number_only = number_only.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
            //保证只能输入两个小数
            number_only = number_only.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
            return Number(number_only);
        },
        //点击质检结论按钮
        handleQualityTesting() {
            this.qualityTestingVisible = true;
        },
        closeDialog() {
            this.qualityTestingVisible = false;
        },
        //审核原因change事件
        reasonListChange(reason) {
            let riskDesc = '';
            if (reason.length > 0) {
                this.claimForm.remark = '';
                reason.forEach((val) => {
                    this.reasonAudit.forEach((value) => {
                        if (val == value.riskName) {
                            riskDesc += value.riskDesc ? value.riskDesc + '；' : '';
                        }
                    });
                });
            } else {
                this.claimForm.remark = '';
            }
            riskDesc ? (this.claimForm.remark = riskDesc) : riskDesc;
        },
        //搜索商品change事件
        searchGoodsNameChange(val) {
            console.log(val);
        },
        //获取商品品类第一级
        getFirstCategory() {
            this.instance.get('/tpa/api/goods/category/getFirstCategory', {}).then((res) => {
                if (res.data.code == 200) {
                    this.firstCategoryOption = res.data.data;
                }
            });
        },
        //获取受损层度
        getDamagedDegreeEnum() {
            let json = {
                caseNo: this.caseDetail.caseNo
            };
            this.instance.get('/tpa/api/enum/getDamagedDegreeEnum', { params: json }).then((res) => {
                if (res.data.code == 200) {
                    this.damagedDegreeOption = res.data.data;
                }
            });
        },
        //获取案件核赔信息
        getAdjust() {
            let json = {
                caseNo: this.caseDetail.caseNo
            };
            this.instance.get('/tpa/api/case/enter/adjust/getAdjust', { params: json }).then((res) => {
                if (res.data.code == 200) {
                    this.adjustResult = res.data.data;
                }
            });
        },
        //获取案件录入信息
        getCaseEnter() {
            let json = {
                caseNo: this.caseDetail.caseNo
            };
            this.instance.get('/tpa/api/case/enter/adjust/getCaseEnter', { params: json }).then((res) => {
                if (res.data.code == 200) {
                    this.caseEnterData = res.data.data || {};
                    if (this.caseEnterData.caseNo) {
                        this.drawer = true;
                        this.goodsModeForm.compensationUnitPrice = this.caseEnterData.compensationUnitPrice;
                        this.goodsModeForm.processMethod = this.caseEnterData.processMethod;
                        this.calculateData();
                        this.goodsModeForm.damagedNum = this.caseEnterData.damagedNum;
                        this.goodsModeForm.damagedExtent = this.caseEnterData.damagedExtent;
                        this.goodsModeForm.deductibleRatio = this.caseEnterData.deductibleRatio;
                        this.goodsModeForm.damagedRatio = this.caseEnterData.damagedRatio;
                        this.goodsModeForm.accessoriesAmount = this.caseEnterData.accessoriesAmount;
                        this.goodsModeForm.repairAmount = this.caseEnterData.repairAmount;
                    }
                }
            });
        },
        //通过商品名称获取商品比价
        getGoodsPriceRelations() {
            let json = {
                goodsName: this.goodsModeForm.goodsName,
                goodsCategoryId: this.goodsModeForm.goodsCategoryId
            };
            this.instance.get('/tpa/api/goods/getGoodsPriceRelations', { params: json }).then((res) => {
                if (res.data.code == 200) {
                    let response = res.data.data;
                    if (response) {
                        this.goodsPriceRelations = response;
                        let arr = [];
                        let obj = {
                            name: '均价',
                            price: response.averagePrice
                        };
                        response.highestPrice.name = '最高价';
                        response.lowestPrice.name = '最低价';
                        arr.push(response.highestPrice);
                        arr.push(response.lowestPrice);
                        arr.push(obj);
                        this.goodsModeForm.compensationUnitPrice = response.lowestPrice.price;
                        this.guidanceTableData = arr;
                    }
                }
            });
        },
        //通过商品名称获取商品列表
        getGoodsList() {
            this.allPriceVisible = true;
            let json = {
                goodsName: this.goodsModeForm.goodsName
            };
            this.searchGoodsName && (json.goodsName = this.searchGoodsName);
            this.instance.get('/tpa/api/goods/getGoodsList', { params: json }).then((res) => {
                if (res.data.code == 200) {
                    this.allPriceTableData = res.data.data;
                }
            });
        },
        //获取所有的材料类型
        getFileTypeList() {
            this.instance.get('/tpa/api/enum/getCaseMaterialsTypeEnum', {}).then((res) => {
                if (res.data.code == 200) {
                    let data = res.data.data;
                    let arr = [];
                    data.forEach((value) => {
                        value.remark = '';
                        value.enumList.forEach((val) => {
                            value.remark += val.desc + '、';
                        });
                        value.remark && (value.remark = '所有' + value.remark.slice(0, -1));
                        arr = arr.concat(value.enumList);
                    });
                    this.caseMaterialsType = data;
                    this.fileTypeList = arr;
                }
            });
        },
        changeCompensationUnitPrice(val) {
            this.goodsModeForm.compensationUnitPrice = val = this.onlyNumOnePoint(val);
        },
        changeConfirmAmount(val) {
            this.adjustmentForm.confirmAmount = val = this.onlyNumOnePoint(val);
        },
        //赔付比例1 change事件
        payoutRatioOneChange(val) {
            this.adjustmentForm.payoutRatioOne = val = this.onlyNumOnePoint(val);
            if (this.adjustmentForm.payoutRatioOne <= this.tmpLimitDto.payoutRatioLimit) {
                this.subLisuan = true;
                this.adjustmentDisabled = true;
                this.instance.post('/enter/adjust/adjustCalculate', this.adjustmentForm).then((res) => {
                    if (res.data.code == 200) {
                        this.adjustmentForm = res.data.data;
                        this.adjustmentDisabled = false;
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
            }
        },
        //点击录入核赔的确认录入按钮
        handleSubmitEntry(elementRefs) {
            if (!this.goodsModeForm.compensationUnitPrice) {
                this.$message.error('抱歉，请输入货物价格基础！');
                return false;
            }
            let tmpGoodsModeForm = Object.assign({}, this.goodsModeForm);
            if (this.goodsModeForm.processMethod == 10) {
                tmpGoodsModeForm.damagedNum = tmpGoodsModeForm.damagedNum || 1;
                tmpGoodsModeForm.damagedExtent = tmpGoodsModeForm.damagedExtent || 10;
                tmpGoodsModeForm.deductibleRatio = tmpGoodsModeForm.deductibleRatio || 0;
                tmpGoodsModeForm.damagedRatio = tmpGoodsModeForm.damagedRatio || 100;
            } else {
                tmpGoodsModeForm.accessoriesAmount = tmpGoodsModeForm.accessoriesAmount || 0;
                tmpGoodsModeForm.repairAmount = tmpGoodsModeForm.repairAmount || 0;
            }
            let json = Object.assign(this.goodsPriceRelations, tmpGoodsModeForm);

            this.$refs[elementRefs].validate((valid) => {
                if (valid) {
                    const loadingCase = this.$loading({
                        lock: true,
                        text: '加载中，请稍后',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    this.submitAddCaseEnter(json, loadingCase);
                }
            });
        },
        //保存案件录入信息
        submitAddCaseEnter(jsonData, loadingCase) {
            this.instance.post('/tpa/api/case/enter/adjust/addCaseEnter', jsonData).then((res) => {
                if (res.data.code == 200) {
                    // this.$message.success(res.data.message);
                    let json1 = {
                        caseNo: this.caseDetail.caseNo
                    };
                    //获取核赔案件风险
                    this.instance.get('/tpa/api/case/enter/adjust/getRiskInfo', { params: json1 }).then((res) => {
                        loadingCase.close();
                        if (res.data.code == 200) {
                            if (res.data.data) {
                                this.adjustCaseRisk = [res.data.data];

                                this.adjustCaseRisk.forEach((value) => {
                                    // 数字为null时重置为0
                                    value.highCount = value.highCount && value.highCount > 0 ? value.highCount : 0;
                                    value.lowCount = value.lowCount && value.lowCount > 0 ? value.lowCount : 0;
                                    value.mediumCount = value.mediumCount && value.mediumCount > 0 ? value.mediumCount : 0;
                                    value.riskCount = value.riskCount && value.riskCount > 0 ? value.riskCount : 0;
                                });
                                this.dialogVisibleEntry = true;
                            } else {
                                this.confirmedSubmitEntry();
                            }
                        } else {
                            this.$message.error(res.data.message);
                        }
                    });
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        //显示核赔结论
        drawerShow() {
            this.adjustmentDisabled = false;
            this.drawer = true;
            setTimeout(() => {
                this.goMaterials('drawer_box');
            }, 300);
            this.instance.post('/tpa/api/case/enter/adjust/calculate', this.caseEnterData).then((res) => {
                if (res.data.code == 200) {
                    this.adjustmentForm = res.data.data;
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        calculateData() {
            this.instance.post('/tpa/api/case/enter/adjust/calculate', this.caseEnterData).then((res) => {
                if (res.data.code == 200) {
                    this.adjustmentForm = res.data.data;
                    this.tmpLimitDto = res.data.data.limitDto || null;
                } else {
                    this.$message.error(res.data.message ? res.data.message : '接口出错，请联系管理员');
                }
            });
        },
        //点击录入核赔风险dialog的确认按钮
        confirmedSubmitEntry() {
            //提交核赔风险
            this.dialogVisibleEntry = false;
            this.adjustmentDisabled = false;
            setTimeout(() => {
                this.goMaterials('drawer_box');
            }, 300);
            let json = Object.assign(this.goodsPriceRelations, this.goodsModeForm);
            //案件试算
            this.instance.post('/tpa/api/case/enter/adjust/calculate', json).then((res) => {
                if (res.data.code == 200) {
                    this.adjustmentForm = res.data.data;
                    // 确认录入提交案件试算后，展示核赔结论内容
                    this.drawer = true;
                    this.adjustmentForm = res.data.data;
                    this.tmpLimitDto = res.data.data.limitDto || null;
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        //锚点跳转到核赔材料
        goMaterials(event) {
            var id = '#' + event;
            document.querySelector(id).scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest'
            });
        },
        //点击添加商品的保存按钮
        handleAddGoods() {
            let json = JSON.parse(JSON.stringify(this.addGoodsForm));
            json.goodsCategoryId = json.goodsCategoryId[1];
            this.instance.post('/tpa/api/goods/addGoods', json).then((res) => {
                if (res.data.code == 200) {
                    this.$message.success('操作成功！');
                    this.dialogVisibleAddGoods = false;
                    this.getGoodsList();
                    this.addGoodsForm.mall = '';
                    this.addGoodsForm.price = '';
                    this.addGoodsForm.shopName = '';
                    this.addGoodsForm.goodsCategoryId = '';
                    this.addGoodsForm.goodsName = '';
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        //点击添加商品类目的保存按钮
        handleAddGoodsCategory() {
            this.instance.post('/tpa/api/goods/category/addGoodsCategory', this.addGoodsCategoryForm).then((res) => {
                if (res.data.code == 200) {
                    this.$message.success('操作成功！');
                    this.dialogVisibleAddGoodsCategory = false;
                    this.addGoodsCategoryForm.name = '';
                    this.addGoodsCategoryForm.parentId = '';
                    this.getGoodsList();
                    this.getCategoryTree();
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        //点击商品信息处的取消按钮
        cancelSelect() {
            this.allPriceVisible = false;
            this.multipleSelection = [];
        },
        //商品信息处table 的复选框change事件
        handleSelectionChange(val) {
            this.multipleSelection = [];
            if (val.length > 1) {
                this.$refs.multipleTable.clearSelection();
                this.$refs.multipleTable.toggleRowSelection(val[val.length - 1]);
            }
            this.multipleSelection = [val[val.length - 1]];
        },
        // 点击使用该商品信息按钮
        useGoodsInfo() {
            let multipleSelection = this.multipleSelection;
            if (multipleSelection.length > 0) {
                this.goodsModeForm.goodsName = multipleSelection[0].goodsName;
                this.goodsModeForm.compensationUnitPrice = multipleSelection[0].price;
                this.categoryTreeOption.forEach((val, key) => {
                    val.goodsCategoryDtoList.forEach((value, index) => {
                        if (value.id == multipleSelection[0].goodsCategoryId) {
                            this.goodsModeForm.goodsCategoryName = val.name;
                        }
                    });
                });
                this.cancelSelect();
            } else {
                this.$message.error('抱歉，请先选择商品！');
            }
        },
        //点击修改材料类型按钮
        handleEditType(item, par) {
            this.materialTypeForm.code = '';
            this[par] = true;
            this.checkedMaterialType = item ? item : null;
            this.titleMaterialType = item ? '修改材料类型' : '选择材料类型';
        },
        //提交修改材料类型
        submitMaterialType(sign) {
            if (this.materialTypeForm.code && this.checkedMaterialType) {
                let json = JSON.parse(JSON.stringify(this.checkedMaterialType));
                this.fileTypeList.forEach((val, key) => {
                    if (val.code == this.materialTypeForm.code) {
                        json.materialType = val.code;
                        json.materialParentType = val.parentCode;
                    }
                });
                this.instance.post('/tpa/api/case/materials/edit', json).then((res) => {
                    if (res.data.code == 200) {
                        this.$message.success('操作成功！');
                        this.innerVisible = false;
                        this.dialogVisibleMaterialType = false;
                        this.dialogVisibleRisk = false;
                        this.handleCaseList(this.checkedCase);
                    } else {
                        this.$message.error(res.data.message);
                    }
                });
            } else if (!this.materialTypeForm.code) {
                this.$message.error('抱歉，请先选择材料类型！');
            } else {
                this.fileTypeList.forEach((item) => {
                    if (item.code == this.materialTypeForm.code) {
                        this.materialTypeForm = item;
                        console.log('--1');
                        console.log(this.materialTypeForm);
                    }
                });
                this.materialFlag = true;
                this.innerVisible = false;
                this.dialogVisibleMaterialType = false;
                this.dialogVisibleRisk = false;
            }
        },
        //点击案件风险 查看按钮
        handleLookRisk(item, caseStep) {
            this.riskTitle = caseStep;

            this.dialogVisibleRisk = true;
            this.initImgUrl(item);
        },
        //初始化图片地址
        initImgUrl(item) {
            if (item.imageList) {
                item.imageList.forEach((val) => {
                    val.url = val.materialPath ? this.OSSClient.signatureUrl(val.materialPath) : val.materialOriginalPath;

                    let imgType = ['.bmp', '.dib', '.pcp', '.dif', '.wmf', '.gif', '.jpg', '.tif', '.eps', '.psd', '.cdr', '.iff', '.tga', '.pcd', '.mpt', '.png', '.jpeg', '.psd', '.svg', '.ufo', '.dng', '.tga', '.eps', '.pcx', '.cdr'];
                    // 判断图片类型
                    let tmpUrlArr = val.url.split('?');
                    tmpUrlArr = tmpUrlArr[0];
                    let tmpLastIndex = tmpUrlArr.lastIndexOf('.');
                    let suffix = tmpLastIndex != -1 ? tmpUrlArr.slice(tmpLastIndex, tmpUrlArr.length).toLowerCase() : -1;
                    if (suffix.length > 6 && suffix.indexOf('%') != -1) {
                        let tmpSuffix = suffix.split('%')[0];
                        suffix = tmpSuffix ? (tmpSuffix == '.com' ? true : false) : '未识别';
                    }

                    // 图片后缀
                    val.isImg = Boolean(suffix != -1);
                    val.suffix = suffix;
                });
            }
            this.checkedLookRisk = item;
        },
        //初始化案件索赔材料
        initMaterialsList() {
            this.caseMaterialsType.forEach((value) => {
                value.files = [];
                this.caseDetail.materialsList.forEach((val, key) => {
                    if (val.materialParentType == value.parentCode) {
                        value.enumList.forEach((o) => {
                            if (o.code == val.materialType) {
                                val.desc = o.desc;
                            }
                        });
                        val.url = val.materialPath ? this.OSSClient.signatureUrl(val.materialPath) : val.materialOriginalPath;
                        value.files.push(val);
                    }
                });
            });
        },
        // 初始化OSS
        initOSSClient() {
            this.instance
                .get('/tpa/api/aliyun/oss/access')
                .then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        console.log('oss初始化成功');
                        this.OSSClient = new OSS({
                            accessKeyId: data.data.accessKeyId,
                            accessKeySecret: data.data.accessKeySecret,
                            stsToken: data.data.securityToken,
                            // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                            region: data.data.region,
                            bucket: data.data.bucket
                        });
                    } else {
                        console.log('oss初始化失败，正在重试！');
                        this.initOSSClient();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    console.log('oss初始化失败-');
                    this.$message.error('阿里云初始化失败，请稍后刷新页面重试！');
                });
        },
        lookBigImage(url) {
            this.dialogImageUrl = url;
            this.dialogVisibleBigImg = true;
        },
        //点击审核弹出的dialog里的确定按钮
        handleSubmitClaim() {
            let json = JSON.parse(JSON.stringify(this.claimForm));
            if (json.reason.length == 0) {
                this.$message.error('抱歉，请选择原因！');
                return false;
            }
            if (json.reason[0] == '原因见备注' && !json.remark) {
                this.$message.error('抱歉，请输入备注！');
                return false;
            }
            json.caseNo = this.caseDetail.caseNo;
            json.action = this.checkedSubmitSign;
            this.instance.post('/tpa/api/case/action', json).then((res) => {
                if (res.data.code == 200) {
                    this.dialogVisible = false;
                    if (this.caseFlowStep[this.stepActive - 1].step == '复审' || this.caseFlowStep[this.stepActive - 1].step == '录入核赔') {
                        this.addAdjust();
                    } else {
                        this.handleClaimNextCase(this.checkedCase);
                    }
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        //操作认领下一个案件
        handleClaimNextCase() {
            this.instance.post('/tpa/api/case/next/' + this.navForm.caseStep, {}).then((res) => {
                if (res.data.code === 200) {
                    this.$message.success('操作成功！');
                    if (res.data.data) {
                        this.getCasePage('next');
                        this.initCaseDetail(res.data.data);
                    } else {
                        this.casePageList = [];
                    }
                } else {
                    this.casePageList = [];
                    this.getCasePage();
                    this.$message.error(res.data.message);
                }
            });
        },
        //保存案件核赔结果
        addAdjust() {
            this.instance.post('/tpa/api/case/enter/adjust/addAdjust', this.adjustmentForm).then((res) => {
                if (res.data.code == 200) {
                    this.drawer = false;
                    this.handleClaimNextCase();
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        //点击审核按钮
        handleClaimSettlement(sign) {
            this.claimForm.reason = [];
            this.claimForm.remark = '';

            if (sign == 'REJECT' || sign == 'CANCEL' || this.caseFlowStep[this.stepActive - 1].step == '复审') {
                this.dialogVisible = true;
            }

            if (this.caseFlowStep[this.stepActive - 1].step == '立案') {
                this.dialogVisible = true;
            }

            if (this.caseFlowStep[this.stepActive - 1].step == '录入核赔') {
                if (this.goodsModeForm.processMethod == 10) {
                    // 赔款金额校验
                    let tmpTwo = false;
                    if (this.adjustmentForm.confirmAmount > this.tmpLimitDto.payoutLimitAmount) {
                        this.$message.error(`注：赔款金额上限为 ${this.tmpLimitDto.payoutLimitAmount}`);
                    } else if (0 <= this.adjustmentForm.confirmAmount && this.adjustmentForm.confirmAmount <= this.tmpLimitDto.payoutLimitAmount) {
                        tmpTwo = true;
                    } else {
                        this.$message.error(`注：赔款金额应该 大于0 并且 小于等于 ${this.tmpLimitDto.payoutLimitAmount}`);
                    }

                    // 赔付比例1校验
                    let tmpOne = false;
                    if (this.adjustmentForm.payoutRatioOne > this.tmpLimitDto.payoutRatioLimit) {
                        this.$message.error(`注：赔付比例1上限为 ${this.tmpLimitDto.payoutRatioLimit}%`);
                    } else {
                        tmpOne = true;
                    }

                    if (tmpOne && tmpTwo) {
                        this.dialogVisible = true;
                    }
                } else {
                    this.dialogVisible = true;
                }
            }

            if (sign == 'APPROVE') {
                this.reasonAudit = [
                    {
                        riskName: '通过',
                        id: 999
                    }
                ];

                // 立案提交弹窗原因push
                const tmpCaseRiskVos = this.caseDetail.caseRiskVos;
                tmpCaseRiskVos.forEach((item) => {
                    if (item.highRiskInfos) {
                        for (let i = 0; i < item.highRiskInfos.length; i++) {
                            const element = item.highRiskInfos[i];
                            this.reasonAudit.push({
                                riskName: element.riskName,
                                id: element.id,
                                riskDesc: element.riskDesc
                            });
                        }
                    }
                });

                this.claimForm.reason = ['通过'];
            }

            if (this.reasonAudit.length == 0) {
                this.reasonAudit = [
                    {
                        riskName: '原因见备注',
                        id: 99
                    }
                ];

                // 立案提交弹窗原因push
                const tmpCaseRiskVos = this.caseDetail.caseRiskVos;
                tmpCaseRiskVos.forEach((item) => {
                    if (item.highRiskInfos) {
                        for (let i = 0; i < item.highRiskInfos.length; i++) {
                            const element = item.highRiskInfos[i];
                            this.reasonAudit.push({
                                riskName: element.riskName,
                                id: element.id,
                                riskDesc: element.riskDesc
                            });
                        }
                    }
                });

                this.claimForm.reason = ['原因见备注'];
            }
            this.checkedSubmitSign = sign;
        },
        //获取案件步骤条
        getCaseStep() {
            this.instance.get('/tpa/api/enum/getCaseStep', {}).then((res) => {
                if (res.data.code == 200) {
                    let caseStep = res.data.data;
                    let stepActive = 0;
                    if (caseStep.length > 0) {
                        caseStep.forEach((val, key) => {
                            val.caseStepList.forEach((value) => {
                                if (value.code == this.caseDetail.caseStep) {
                                    stepActive = key + 1;
                                }
                            });
                        });
                        stepActive > 0 && (this.stepActive = stepActive);
                    }
                    this.caseFlowStep = caseStep;
                    this.caseFlowStep[this.stepActive - 1].step == '复审' && this.getAdjust();
                }
            });
        },
        //操作选中案件列表某一案件
        handleCaseList(item) {
            this.allPriceTableData = [];
            this.guidanceTableData = [];
            this.uploadList = [];
            this.allPriceVisible = false;
            this.drawer = false;
            this.caseLoading = true;
            this.checkedCase = item;
            this.caseDetail = {};
            this.goodsModeForm = this.$options.data().goodsModeForm;
            this.adjustmentForm = this.$options.data().adjustmentForm;
            this.adjustResult = this.$options.data().adjustResult;
            this.caseEnterData = this.$options.data().caseEnterData;
            this.instance
                .get('/tpa/api/case/detail/' + item.caseNo, {})
                .then((res) => {
                    this.caseLoading = false;
                    if (res.data.code === 200) {
                        this.initCaseDetail(res.data.data);
                    }
                })
                .catch((res) => {
                    this.caseLoading = false;
                    console.log(res);
                    this.$message.error('请求失败, 请稍后重试');
                });
        },
        //处理案件详情信息
        initCaseDetail(response) {
            response.materialsList = response.materialsList || [];
            response.caseRiskVos = response.caseRiskVos || [];
            this.caseDetail = response;
            this.queryCaseNo = response.caseNo;
            // this.caseFlowStep=this.caseDetail.caseFlowStep||[];
            this.caseDetail.goodsInfo && (this.goodsModeForm = Object.assign(this.goodsModeForm, this.caseDetail.goodsInfo));
            this.getCaseStep(); //获取案件步骤条
            this.getGoodsPriceRelations();
            this.getDamagedDegreeEnum();
            this.getCaseEnter(); //获取录入核赔信息

            if (this.caseDetail.materialsList.length > 0) {
                this.initMaterialsList();
            }
            //过滤审核风险，组合原因
            if (this.caseDetail.caseRiskVos.length > 0) {
                let arr = [];
                this.caseDetail.caseRiskVos.forEach((value) => {
                    value.highRiskInfos && (arr = arr.concat(value.highRiskInfos));
                    value.mediumRiskInfos && (arr = arr.concat(value.mediumRiskInfos));
                    value.lowRiskInfos && (arr = arr.concat(value.lowRiskInfos));

                    // 数字为null时重置为0
                    value.highCount = value.highCount && value.highCount > 0 ? value.highCount : 0;
                    value.lowCount = value.lowCount && value.lowCount > 0 ? value.lowCount : 0;
                    value.mediumCount = value.mediumCount && value.mediumCount > 0 ? value.mediumCount : 0;
                    value.riskCount = value.riskCount && value.riskCount > 0 ? value.riskCount : 0;
                });
                // this.reasonAudit=arr;
            }
            // this.editEmunName();
        },
        //修改商品处其他损失情况的枚举名称
        editEmunName() {
            this.packingStatusOption.length > 0 &&
                this.packingStatusOption.forEach((val) => {
                    if (this.caseDetail.packingWhole == val.code) {
                        this.caseDetail.packingWholeName = val.desc;
                    }
                });
            this.goodsLostStatusOption.length > 0 &&
                this.goodsLostStatusOption.forEach((val) => {
                    if (this.caseDetail.goodsLost == val.code) {
                        this.caseDetail.goodsLostName = val.desc;
                    }
                });
            this.receiveStatusOption.length > 0 &&
                this.receiveStatusOption.forEach((val) => {
                    if (this.caseDetail.signFor == val.code) {
                        this.caseDetail.signForName = val.desc;
                    }
                });
            this.residualProcessOption.length > 0 &&
                this.residualProcessOption.forEach((val) => {
                    if (this.caseDetail.residualType == val.code) {
                        this.caseDetail.residualTypeName = val.desc;
                    }
                });
            this.damagedDutyOption.length > 0 &&
                this.damagedDutyOption.forEach((val) => {
                    if (this.caseDetail.damagedDuty == val.code) {
                        this.caseDetail.damagedDutyName = val.desc;
                    }
                });
        },
        //点击查询
        searchCase() {
            this.currentPage = 1;
            this.getTbodyData();
        },
        //获取认领案件列表
        getCasePage(sign) {
            this.loading = true;
            this.casePageList = []; //  清空table的值
            let json = {};
            let navForm = JSON.parse(JSON.stringify(this.navForm));
            json.condition = navForm;
            json.pageNo = this.currentPage;
            json.pageSize = this.pageSize;
            this.instance.post('/tpa/api/case/paging', json).then((res) => {
                this.loading = false;
                if (res.data.code === 200) {
                    this.casePageList = res.data.data.list;
                    this.totalPage = parseInt(res.data.data.total);
                    let queryCaseNo = this.queryCaseNo;
                    if (this.casePageList.length > 0 && sign !== 'next') {
                        if (queryCaseNo) {
                            let isCaseNo = false;
                            this.casePageList.forEach((val, key) => {
                                if (val.caseNo === queryCaseNo) {
                                    isCaseNo = true;
                                    this.handleCaseList(val);
                                }
                            });
                            !isCaseNo && this.handleCaseList(this.casePageList[0]);
                        } else {
                            this.handleCaseList(this.casePageList[0]);
                        }
                    }
                }
            });
        },
        //自动认领案件
        // getCollectFirstCase(){
        // 	let userId = JSON.parse(localStorage.getItem("userInfo")).userId;
        // 	var json={
        // 		caseNo:row.caseNo,
        // 		userId:userId
        // 	}
        // 	this.instance.post('/caseInfo/collectFirstCase',json).then(res=>{
        // 		// console.log(res);

        // 	});
        // },
        //获取tbody里的数据
        getTbodyData() {
            this.loading = true;
            this.tableData = []; //  清空table的值
            var json = JSON.parse(JSON.stringify(this.navForm));
            json.page = this.currentPage;
            json.size = this.pageSize;
            this.instance.post('/caseInfo/' + this.menuType, json).then((res) => {
                this.loading = false;
                this.tableData = res.data.data.list;
                this.totalPage = parseInt(res.data.data.total);
            });
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val;
            this.getCasePage();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange(val) {
            this.currentPage = Number(val);
            this.getCasePage();
        },
        //点击的哪个上传
        handleUpload(item) {
            this.checkedUploadItem = item;
        },
        //索赔资料处照片删除的回调
        handleRemove(file, fileList) {
            let materialsList = this.materialsList;
            let arr = [];
            materialsList.forEach((val) => {
                fileList.forEach((value) => {
                    if (value.name == val.materialName) {
                        arr.push(val);
                    }
                });
            });
            this.materialsList = arr;
        },
        //索赔资料处照片点击文件列表中已上传的文件时的钩子
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisibleBigImg = true;
        },
        //文件上传成功时的钩子
        handleAvatarSuccess(res, file) {
            this.fullscreenLoading.close();
        },
        //上传文件之前的钩子
        beforeAvatarUpload(file) {
            if (file.size / 1024 > 5000) {
                this.$message.error('抱歉，上传文件不得大于5M！');
                return false;
            }
            this.fullscreenLoading = this.$loading({
                lock: true,
                text: '上传中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        },
        //OSS直传
        ossUpload(e) {
            var _this = this;
            const OSS = require('ali-oss');
            let file = e.file;
            let storeAs = 'upload-file';
            let protocol = location.protocol;
            // OSS.urllib是SDK内部封装的发送请求的逻辑，开发者可以使用任何发送请求的库向sts-server发送请求。
            OSS.urllib.request(protocol + _this.baseURL + '/tpa/api/aliyun/oss/access', { method: 'GET' }, (err, response) => {
                try {
                    var result = JSON.parse(response).data;
                } catch (e) {
                    console.log('parse sts response info error: ' + e.message);
                }
                let client = new OSS({
                    accessKeyId: result.accessKeyId,
                    accessKeySecret: result.accessKeySecret,
                    stsToken: result.securityToken,
                    // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                    region: result.region,
                    bucket: result.bucket
                });
                // storeAs可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
                // file可以自定义为File对象、Blob数据以及OSS Buffer。
                storeAs = result.path + '/' + uuidv4() + '.' + file.name.split('.')[1];
                client
                    .multipartUpload(storeAs, file)
                    .then(function (result) {
                        _this.fullscreenLoading.close();
                        let tmpUploadData = {
                            caseNo: _this.caseDetail.caseNo,
                            materialPath: storeAs,
                            materialType: _this.materialTypeForm.code,
                            materialParentType: _this.materialTypeForm.parentCode,
                            materialName: _this.materialTypeForm.desc
                        };
                        _this.instance.post('/tpa/api/case/materials/upload', tmpUploadData).then((res) => {
                            _this.materialFlag = false;
                            _this.materialTypeForm.code = '';
                        });
                        _this.materialsList.push({
                            materialName: file.name,
                            materialPath: storeAs
                        });
                        _this.uploadList.push({
                            materialName: _this.materialTypeForm.desc,
                            materialType: file.name,
                            url: _this.OSSClient.signatureUrl(storeAs)
                        });
                    })
                    .catch(function (err) {
                        console.log(err);
                    });
            });
        }
    },
    watch: {
        queryCaseNo() {
            if (this.queryCaseNo == 'KN1622460077743767552') {
                this.isQualityTesting = true;
            } else {
                this.isQualityTesting = false;
            }
        },
        height(val) {
            this.height = val;
        }
    },
    mounted() {
        this.initOSSClient();

        // 获取案件详情
        if (this.$route.query.caseData) {
            this.handleCaseList(JSON.parse(this.$route.query.caseData));
        }

        // 案件详情-右侧case高度
        window.screenHeight = document.body.clientHeight;
        this.height = window.screenHeight - 239;
        window.onresize = () => {
            return (() => {
                window.screenHeight = document.body.clientHeight;
                this.height = window.screenHeight - 239;
            })();
        };
    }
};
