
import OSS from 'ali-oss';
import ImgDeal from '@/components/ImgDeal.vue';
import Claimant from './Claimant.vue';
import ReportCase from './ReportCase.vue';
import CaseDetail from './CaseInfo.vue';
import CaseRisk from './CaseRisk.vue';
import GuaranteeSlip from './GuaranteeSlip.vue';
import Waybill from './Waybill.vue';
import CaseLog from './CaseLog.vue';
import JdPay from './JdPay.vue';
import LossAssessment from './LossAssessment.vue';
import CaseStep from './CaseStep.vue';
import QualityConclusion from './QualityConclusion.vue';
import CloseCase from './CloseCase.vue';
import SuspendNav from './suspendNav.vue';
export default {
    name: 'myTask',
    props: {
        handleData: {
            type: Object,
            default: {}
        }
    },
    components: {
        ImgDeal,
        Claimant,
        ReportCase,
        CaseDetail,
        CaseRisk,
        GuaranteeSlip,
        Waybill,
        CaseLog,
        JdPay,
        LossAssessment,
        CaseStep,
        QualityConclusion,
        CloseCase,
        SuspendNav
    },
    data() {
        return {
            resetOssNum: 0,
            scrollFlag: false,
            hideCaseLog: false,
            caseDetail: {
                caseRiskVos: []
            },
            fileTypeList: [],
            allPriceVisible: false,
            drawer: false,
            caseLoading: false,
            checkedCase: {},
            suspensionBtnObj: {
                sort: 1,
                step: '立案',
                tmpStep: {}
            },
            qualityForm: {
                caseNo: '',
                qualityRiskLevel: '',
                conclusion: '',
                emphasisCase: true,
                qualityRemark: ''
            },
            submitDialogVisible: false,
            submitRuleForm: {
                action: 'APPROVE',
                reason: [],
                remark: '',
                supplyFileType: []
            },
            submitRules: {
                resource: [{ required: true, message: '请选择活动资源', trigger: 'change' }],
                region: [{ required: true, message: '请选择活动区域', trigger: 'change' }]
            },
            qualityRules: {
                qualityRiskLevel: [{ required: true, message: '请选择类型', trigger: 'change' }],
                conclusion: [{ required: true, message: '请选择问题', trigger: 'change' }],
                emphasisCase: [{ required: true, message: '请选择', trigger: 'change' }]
            },
            dialogVisible: false,
            radioGroupList: [
                {
                    value: 'APPROVE',
                    label: '通过'
                },
                {
                    value: 'REJECT',
                    label: '不通过'
                }
            ],
            reasonOptionList: [],
            caseMaterialsType: [], //材料类型
            reasonOption: [],
            isBtnShow: false,
            submitDisabled: false,
            disappearFlag: false,
            tmpOSSClient: null,
            suspensionList: [],
            conclusionOption: [],
            riskLevelOption: [],
            detailHeight: '300px',
            caseRiskLists: [],
            detailsRadio: '0',
            detailsRadioArr: [
                {
                    label: '案件定损'
                },
                {
                    label: '京东谈赔信息'
                },
                {
                    label: '结案信息'
                },
                {
                    label: '案件信息'
                },
                {
                    label: '保单信息'
                },
                {
                    label: '运单信息'
                },
                {
                    label: '质检结论'
                }
                // {
                //     label: '索赔信息'
                // },
                // {
                //     label: '案件风险'
                // },
                // {
                //     label: '报案信息'
                // },
            ]
        };
    },
    mounted() {
        this.initOSSClient();
        this.isBtnShow = this.handleData.detailShow != 'detailShow';

        window.onresize = () => {
            this.calcHeight();
        };
        // 绑定卡片tab 与 卡片 关联关系
        this.detailsRadioArr.forEach((item, index) => {
            item.code = index;
        });

        // 获取其他费用选项
        this.getOtherSelect();
        // 获取损失程度
        this.getDamagedDegreeEnum();
        // 获取材料类型
        this.getFileTypeList();
        // 获取审核原因
        this.getReason();
        this.getRiskLevelEnum();
    },
    methods: {
        handleScroll(e) {
            this.scrollFlag = e.target.scrollTop > 1;
        },
        hiddenCaseLog() {
            this.hideCaseLog = !this.hideCaseLog;
        },
        saveForm() {
            localStorage.setItem(String(this.caseDetail.caseNo), JSON.stringify(this.qualityForm));
        },
        calcHeight() {
            let headerHeight = document.querySelector('.header') ? document.querySelector('.header').clientHeight : 0;
            let registerTabsHeight = document.querySelector('.register_tabs') ? document.querySelector('.register_tabs').clientHeight : 0;
            let caseStepHeight = document.querySelector('.case-step') ? document.querySelector('.case-step').clientHeight : 0;
            let guHeight = 50;
            this.detailHeight = window.innerHeight - headerHeight - registerTabsHeight - caseStepHeight - guHeight + 'px';
        },
        // 初始化OSS
        initOSSClient() {
            this.tmpOSSClient = null;
            this.resetOssNum++;
            this.instance
                .get('/tpa/api/aliyun/oss/access')
                .then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.tmpOSSClient = new OSS({
                            accessKeyId: data.data.accessKeyId,
                            accessKeySecret: data.data.accessKeySecret,
                            stsToken: data.data.securityToken,
                            // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                            region: data.data.region,
                            bucket: data.data.bucket
                        });
                        this.tmpOSSClient.resetOss = true;
                    } else {
                        console.log('oss初始化失败，正在重试！');

                        if (this.resetOssNum < 20) {
                            setTimeout(() => {
                                this.initOSSClient();
                            }, 1000);
                        } else {
                            console.log('oss初始化超过20s，失败!');
                        }
                    }
                })
                .catch((err) => {
                    console.log('oss初始化失败-');
                    console.log(err);
                });
        },
        handleProblem(flagStr) {
            if (flagStr == 'show') {
                this.handleData = this.caseDetail;
                this.handleFlag = true;
                this.editableTabs.length == 1 && this.addTab();
                this.editableTabsValue = '99';
            } else {
                this.dialogVisible = true;
                this.conclusionOption = this.caseRiskLists;
                this.conclusionOption.push({ riskName: '其他', id: 9999991 });
                this.conclusionOption.push({ riskName: '无', id: 9999992 });
                this.$refs.qualityRef && this.$refs.qualityRef.resetFields();
                this.qualityForm = {
                    caseNo: this.caseDetail.caseNo,
                    qualityRiskLevel: '',
                    conclusion: '',
                    emphasisCase: true,
                    qualityRemark: ''
                };
            }
        },
        //操作保存
        handleSubmit(submitFlag, saveFlag) {
            submitFlag &&
                this.$refs.qualityRef.validate((valid) => {
                    if (valid) {
                        this.instance.post('/tpa/api/case/quality/add', this.qualityForm).then((res) => {
                            this.dialogVisible = false;
                            if (res.data.code == 200) {
                                localStorage.removeItem(String(this.caseDetail.caseNo));
                                this.$refs.qualityRef.resetFields();
                                this.$message.success('质检成功');
                                this.handleCaseList(this.handleData.caseNo);
                                this.$emit('getTbodyData');
                            } else {
                                this.$message.error(res.data.message);
                            }
                        });
                    }
                });

            if (!submitFlag) {
                this.dialogVisible = false;
                this.conclusionOption = [];
                this.$refs.qualityRef.resetFields();
                saveFlag == 'true' && localStorage.removeItem(String(this.caseDetail.caseNo));
                if (localStorage.getItem(String(this.caseDetail.caseNo))) {
                    this.qualityForm = JSON.parse(localStorage.getItem(String(this.caseDetail.caseNo)));
                }
            }
        },
        getRiskLevelEnum() {
            this.instance.post('/tpa/api/enum/getRiskLevelEnum', {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.riskLevelOption = data.data;
                }
            });
        },
        closeCaseDetail(data) {
            this.$emit('closeCaseDetail', data);
        },
        handleRadioGroup(data) {
            this.suspensionBtnObj = data ? data : {};
            if (data && data.step == '核赔') {
                this.radioGroupList = [
                    {
                        value: 'APPROVE',
                        label: '通过'
                    }
                ];
            }

            if (data && data.step == '定损') {
                this.radioGroupList = [
                    {
                        value: 'APPROVE',
                        label: '通过'
                    },
                    {
                        value: 'REJECT',
                        label: '补充材料'
                    }
                ];
            }
        },
        getRiskInfo(caseNo) {
            this.instance.get('/tpa/api/case/risk/getRiskInfo?caseNo=' + caseNo).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.caseRiskLists = data.data || [];
                }
            });
        },
        // 案件详情
        handleCaseList(caseNo) {
            this.allPriceVisible = false;
            this.drawer = false;
            this.caseLoading = true;
            this.caseDetail = {};
            this.adjustmentForm = this.$options.data().adjustmentForm;
            this.adjustResult = this.$options.data().adjustResult;
            this.caseEnterData = this.$options.data().caseEnterData;
            this.instance
                .get('/tpa/api/case/detail/' + caseNo, {})
                .then((res) => {
                    this.caseLoading = false;
                    if (res.data.code === 200) {
                        this.initCaseDetail(res.data.data);
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((res) => {
                    this.caseLoading = false;
                    console.log(res);
                    this.$message.error('请求失败, 请稍后重试');
                });
        },
        //处理案件详情信息
        initCaseDetail(response) {
            var _this = this;
            response.materialsList = response.materialsList || [];
            response.caseRiskVos = response.caseRiskVos || [];
            this.caseDetail = response;
            this.submitRuleForm.caseNo = response.caseNo;
            this.queryCaseNo = response.caseNo;

            //过滤审核风险，组合原因
            if (this.caseDetail.caseRiskVos.length > 0) {
                let arr = [];
                this.caseDetail.caseRiskVos.forEach((value) => {
                    value.highRiskInfos && (arr = arr.concat(value.highRiskInfos));
                    value.mediumRiskInfos && (arr = arr.concat(value.mediumRiskInfos));
                    value.lowRiskInfos && (arr = arr.concat(value.lowRiskInfos));

                    // 数字为null时重置为0
                    value.highCount = value.highCount && value.highCount > 0 ? value.highCount : 0;
                    value.lowCount = value.lowCount && value.lowCount > 0 ? value.lowCount : 0;
                    value.mediumCount = value.mediumCount && value.mediumCount > 0 ? value.mediumCount : 0;
                    value.riskCount = value.riskCount && value.riskCount > 0 ? value.riskCount : 0;
                });
            }

            // 右侧悬浮窗
            if (response.closeCaseTime) {
                this.suspensionList = [{ name: '索赔信息' }, { name: '结案信息' }, { name: '运单信息' }, { name: '谈赔信息' }, { name: '案件风险' }, { name: '案件定损' }, { name: '报案信息' }, { name: '案件信息' }, { name: '质检结论' }, { name: '案件日志' }];
            } else {
                this.suspensionList = [{ name: '索赔信息' }, { name: '运单信息' }, { name: '谈赔信息' }, { name: '案件风险' }, { name: '案件定损' }, { name: '报案信息' }, { name: '案件信息' }, { name: '质检结论' }, { name: '案件日志' }];
            }
        },
        // 获取其他费用选项
        getOtherSelect() {
            this.instance.get('/tpa/api/enum/getOtherExpense', {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.otherSelectOption = data.data;
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        editOtherList(index, btnStr) {
            if (btnStr == 'del') {
                this.goodsModeForm.otherExpenses.splice(index, 1);
            } else if (this.goodsModeForm.otherExpenses.length < 16) {
                this.goodsModeForm.otherExpenses.push({
                    code: '',
                    expenseAmount: '',
                    expenseName: ''
                });
            }

            if (this.goodsModeForm.otherExpenses.length > 15) {
                this.$message.error('其他费用，目前仅支持添加15个费用');
            }
        },
        otherSelectChange(val) {
            var tmpIndex = this.otherSelectOption.findIndex((res) => {
                return val == res.code;
            });
            tmpIndex != -1 &&
                this.goodsModeForm.otherExpenses.forEach((item) => {
                    if (item.code == val) {
                        item.expenseName = this.otherSelectOption[tmpIndex].desc;
                    }
                });
        },
        //获取受损程度
        getDamagedDegreeEnum() {
            let json = {
                caseNo: this.caseDetail.caseNo
            };
            this.instance.get('/tpa/api/enum/getDamagedDegreeEnum', { params: json }).then((res) => {
                if (res.data.code == 200) {
                    this.damagedDegreeOption = res.data.data;
                }
            });
        },
        // 提交案件按钮
        submitCase(submitFlag) {
            let _this = this;
            if (!submitFlag) {
                this.submitDialogVisible = true;
            } else {
                this.$refs.ruleForm.validate((valid) => {
                    if (valid && !this.disappearFlag) {
                        this.submitRuleForm.reason = [this.submitRuleForm.reason];
                        var json = this.submitRuleForm;
                        json.caseNo = this.caseDetail.caseNo;
                        this.submitDisabled = true;
                        this.disappearFlag = true;
                        this.instance.post('/tpa/api/case/action', json).then((res) => {
                            this.submitDisabled = false;
                            if (res.data.code == 200) {
                                this.$message.success('提交成功');
                                setTimeout(() => {
                                    this.submitDialogVisible = false;
                                    this.$refs.ruleForm.resetFields();
                                    this.$emit('closeCaseDetail');
                                    this.disappearFlag = false;
                                }, 500);
                                this.handleCaseList(this.handleData.caseNo);
                            } else {
                                this.disappearFlag = false;
                                this.$message.error(res.data.message);
                            }
                        });
                    }
                });
            }
        },
        //获取所有的材料类型
        getFileTypeList() {
            this.instance.get('/tpa/api/enum/getCaseMaterialsTypeEnum', {}).then((res) => {
                if (res.data.code == 200) {
                    let data = res.data.data;
                    let arr = [];
                    data.forEach((value) => {
                        value.remark = '';
                        value.enumList.forEach((val) => {
                            value.remark += val.desc + '、';
                        });
                        value.remark && (value.remark = '所有' + value.remark.slice(0, -1));
                        arr = arr.concat(value.enumList);
                    });
                    this.caseMaterialsType = data;
                    this.fileTypeList = arr;
                }
            });
        },
        // 获取审核原因
        getReason() {
            this.instance.post('/tpa/api/enum/getDict', { dictName: this.handleData.menuTypeNum, dictType: 'ACTION_REASON_DICT' }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.reasonOption = data.data;
                }
            });
        },
        closeSubmitDialog(saveFlag) {
            this.submitDialogVisible = false;
            this.$refs.ruleForm.resetFields();
            saveFlag == 'true' && localStorage.removeItem(String(this.caseDetail.caseNo));
            this.submitDisabled = false;
        }
    },
    watch: {
        OSSClient: {
            handler(val) {
                if (val) {
                    this.tmpOSSClient = val;
                }
            },
            immediate: true
        },
        handleData: {
            handler(val) {
                if (val.caseNo) {
                    this.handleCaseList(this.handleData.caseNo);
                    this.getRiskInfo(this.handleData.caseNo);
                    this.calcHeight();
                }
            },
            immediate: true
        }
    }
};
