
// @ is an alias to /src
export default {
    data() {
        return {
            tableData: [],
            dialogTitle: '编辑-特别约定',
            dialogFormVisible: false,
            addFormFlag: false,
            form: {
                goodsCategory: '',
                agreement: ''
            },
            typeList: []
        };
    },
    mounted() {
        this.getAllSpecialAgreement();
        this.getGoodsCategoryEnum();
    },
    methods: {
        getGoodsCategoryEnum() {
            this.instance.post('/tpa/api/enum/getGoodsCategoryEnum', {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.typeList = data.data;
                }
            });
        },
        getAllSpecialAgreement() {
            this.instance.post('/tpa/api/special/agreement/getAllSpecialAgreement', {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.tableData = data.data;
                }
            });
        },
        editInfo(editFlag, row) {
            this.addFormFlag = Boolean(row == '1');
            if (row != '1') {
                this.form = JSON.parse(JSON.stringify(row));
            } else {
                this.form = {
                    goodsCategory: '',
                    agreement: ''
                };
            }
            this.form.editFlag = editFlag;
            this.dialogTitle = Boolean(row == '1') ? '添加-特别约定' : editFlag ? '编辑-特别约定' : '查看-特别约定';
            this.dialogFormVisible = true;
        },
        submit(subFlag) {
            if (!subFlag) {
                this.dialogFormVisible = false;
                this.$refs.elForm.resetFields();
            } else {
                if (this.addFormFlag) {
                    this.instance.post('/tpa/api/special/agreement/addSpecialAgreement', { goodsCategoryCode: this.form.goodsCategory, agreement: this.form.agreement }).then((res) => {
                        let data = res.data;
                        if (data.code == 200) {
                            this.getAllSpecialAgreement();
                            this.$message.success('添加成功');
                            this.dialogFormVisible = false;
                        } else {
                            this.$message.error(data.message);
                        }
                    });
                } else {
                    this.instance
                        .post('/tpa/api/special/agreement/updateSpecialAgreement', {
                            goodsCategoryCode: this.form.goodsCategoryCode,
                            goodsCategory: this.form.goodsCategory,
                            id: this.form.id,
                            agreement: this.form.agreement
                        })
                        .then((res) => {
                            let data = res.data;
                            if (data.code == 200) {
                                this.getAllSpecialAgreement();
                                this.$message.success('编辑成功');
                                this.dialogFormVisible = false;
                            } else {
                                this.$message.error(data.message);
                            }
                        });
                }
            }
        }
    }
};
