
// @ is an alias to /src
import * as OLAPOptions from './mock';
export default {
    name: 'qualityTestingAnalysis',
    components: {},
    data() {
        return {
            value1: [new Date(2022, 11, 1), new Date(2022, 12, 31)],
            value: '选项1',
            options: [
                {
                    value: '选项1',
                    label: '立案问题'
                },
                {
                    value: '选项2',
                    label: '录入核赔问题'
                },
                {
                    value: '选项3',
                    label: '复审问题'
                }
            ],
            questionTypeChart: OLAPOptions.questionTypeChart,
            goodsTaskOLAP: OLAPOptions.goodsTaskOLAP,
            operatorTestingOLAP: OLAPOptions.operatorTestingOLAP
        };
    },
    methods: {
        getOptions() {}
    },
    mounted() {
        //
    }
};
