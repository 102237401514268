export const ruleTableOptions = {
    columns: [
        { label: '规则编号', prop: 'ruleCode'},
        { label: '规则名称', prop: 'ruleName'},
        { label: '规则类别', prop: 'scene'},
        { label: '规则描述', prop: 'ruleDesc'},
        { label: '操作', prop: 'operators'},
        { label: '是否启用', prop: 'status'},
    ],
}

export const getDataType = (code) => {
    switch (code) {
        case 20:
        case 30:
        case 40:
        case 50:
            return 'number';
        case 70:
            return 'boolean';
        default:
            return 'string';
    }
}

export const getLogicOperatorEnum = (operator) => {
    if(operator == 'and') {
        return 100;
    } else {
        return 110;
    }
}


export const employeeData = [{
    'EmployeeID': 1,
    'FirstName': 'Nancy',
    'Title': 'Sales Representative',
    'TitleOfCourtesy': 'Ms.',
    'HireDate': '22/07/2001',
    'City': 'Seattle',
    'Country': 'USA'
  }];

  
  export const RuleDatas = {
    "operatorType": 110,
    "leftChild": {
        "operatorType": 100,
        "leftChild": {
            "operatorType": 140,
            "leftChild": {
                "operatorType": 0,
                "leftChild": null,
                "rightChild": null,
                "value": {
                    "isConstant": false,
                    "code": "Feature12345677656",
                    "constantValue": null
                },
                "expression": null,
                "hitCondition": "10"
            },
            "rightChild": {
                "operatorType": 0,
                "leftChild": null,
                "rightChild": null,
                "value": {
                    "isConstant": false,
                    "code": "Feature12345677657",
                    "constantValue": null
                },
                "expression": null,
                "hitCondition": "10"
            },
            "hitCondition": "20"
        },
        "rightChild": {
            "operatorType": 140,
            "leftChild": {
                "operatorType": 0,
                "leftChild": null,
                "rightChild": null,
                "value": {
                    "isConstant": false,
                    "code": "Feature12345677656",
                    "constantValue": null
                },
                "expression": null,
                "hitCondition": "10"
            },
            "rightChild": {
                "operatorType": 0,
                "leftChild": null,
                "rightChild": null,
                "value": {
                    "isConstant": false,
                    "code": "Feature12345677657",
                    "constantValue": null
                },
                "expression": null,
                "hitCondition": "10"
            }
        },
        "value": null,
        "expression": null,
        "hitCondition": "10"
    },
    "rightChild": {
        "operatorType": 140,
            "leftChild": {
                "operatorType": 0,
                "leftChild": null,
                "rightChild": null,
                "value": {
                    "isConstant": false,
                    "code": "Feature12345677656",
                    "constantValue": null
                },
                "expression": null,
                "hitCondition": "10"
            },
            "rightChild": {
                "operatorType": 0,
                "leftChild": null,
                "rightChild": null,
                "value": {
                    "isConstant": false,
                    "code": "Feature12345677657",
                    "constantValue": null
                },
                "expression": null,
                "hitCondition": "10"
            }
    },
    "value": null,
    "expression": null,
    "hitCondition": "10"
}