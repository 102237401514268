
import OSS from 'ali-oss';
import CaseList from '@/components/CaseList.vue';
import LossPage from '@/components/caseInfo/lossPage.vue';
import AdjustmentPage from '@/components/caseInfo/adjustmentPage.vue';
import ForecastCasePage from '@/components/caseInfo/ForecastCasePage.vue';

export default {
    name: 'casePage',
    components: {
        CaseList,
        LossPage,
        AdjustmentPage,
        ForecastCasePage
    },
    data() {
        return {
            tmpActiveName: '',
            menuType: 'staging',
            menuTypeNum: null,
            editableTabsValue: '15',
            editableTabs: [
                {
                    title: '立案任务',
                    menuTypeNum: '0'
                }
            ],
            tabDictionary: {
                立案任务: '30',
                定损任务: '40',
                核赔任务: '50',
                预报案任务: '15'
            },
            tabIndex: 2,
            handleFlag: false,
            handleData: {},
            left: 0,
            top: 0
        };
    },
    mounted() {
        this.renderTable();
        if (this.$route.query.type == 'handle') {
            this.handleCase(this.$route.query);
        }
    },
    methods: {
        renderTable() {
            localStorage.removeItem('editConfirmAmount');
            localStorage.removeItem('detailShow');
            let tmpTabsArr = [];
            let stagingArr = localStorage.getItem('stagingArr') ? JSON.parse(localStorage.getItem('stagingArr')) : [];
            stagingArr.length > 0 &&
                stagingArr.forEach((item) => {
                    tmpTabsArr.push({
                        title: item.name,
                        menuTypeNum: item.type
                    });
                });
            this.editableTabs = tmpTabsArr;

            let numStr = '';
            if (this.$route.query.menuType) {
                switch (this.$route.query.menuType) {
                    case '10':
                        numStr = '40';
                        break;
                    case '40':
                        numStr = '40';
                        break;
                    case '49':
                        numStr = '50';
                        break;
                    case '15':
                        numStr = '15';
                        break;

                    default:
                        numStr = '40';
                        break;
                }
            } else {
                numStr = stagingArr[0].type;
            }

            this.editableTabsValue = numStr;
            this.menuTypeNum = Number(numStr);
        },
        addTab() {
            // 30立案 40定损 50核赔 90预立案
            this.editableTabs.push({
                title: '案件详情',
                menuTypeNum: '99'
            });
        },
        removeTab(targetName) {
            localStorage.removeItem('editConfirmAmount');
            let tabs = this.editableTabs;

            this.handleFlag = false;
            this.editableTabsValue = this.tmpActiveName;
            this.clickTab({ name: this.tmpActiveName, clickType: 'remove' });
            if (targetName == '99') {
                localStorage.removeItem('detailShow');
                this.editableTabs = tabs.filter((tab) => tab.menuTypeNum !== targetName);
            }
        },
        clickTab(tabRow) {
            if (tabRow.clickType != 'remove') {
                localStorage.removeItem('tmpCaseStep');
            }
            if (tabRow.name == 99) {
                this.handleFlag = true;
            } else {
                let tmpIndex = this.editableTabs.findIndex((item) => {
                    return item.menuTypeNum == '99';
                });
                this.handleFlag = tabRow.name == 99 ? true : false;
                this.menuTypeNum = Number(tabRow.name);
                if (tmpIndex == -1) {
                    this.handleData.menuTypeNum = Number(tabRow.name);
                }
            }
        },
        handleCase(data) {
            this.handleData = JSON.parse(JSON.stringify(data));
            this.handleData.menuType = this.menuType;

            // 添加详情tab
            let tmpIndex = this.editableTabs.findIndex((item) => item.menuTypeNum == '99');
            this.tmpActiveName = this.handleData.tmpHandleData ? this.handleData.tmpHandleData.menuTypeNum + '' : this.handleData.menuTypeNum + '';
            tmpIndex == -1 && this.addTab();
            this.editableTabsValue = '99';
            this.handleFlag = true;
        },
        closeCaseDetail(switchData) {
            switchData ? this.handleCase(switchData) : this.removeTab('99');
        }
    }
};
