export const accidentTypeProportion = [
	{
		title: {
			text: '破损 73%',
			textStyle:{
				fontSize:14
			},
			left: 'center',
			top: 'center'
		},
		series: [{
			type: 'pie',
			lable: {
				show: false,
			},
			labelLine: {
				show: false
			  },
			data: [{
				value: 73,
			},{
				value: 27,
				itemStyle: {
					color: '#E4E7ED'
				}
			}],
			radius: ['30%', '55%']
		}]
	},
	{
		title: {
			text: '遗失 17%',
			left: 'center',
			textStyle:{
				fontSize:14
			},
			top: 'center'
		},
		series: [{
			type: 'pie',
			data: [{
				value: 17,
			},{
				value: 83,
				itemStyle: {
					color: '#E4E7ED'
				}
			}],
			labelLine: {
				show: false
			  },
			radius: ['30%', '55%']
		}]
	},
	{
		title: {
			text: '污染 10%',
			textStyle:{
				fontSize:14
			},
			left: 'center',
			top: 'center'
		},
		series: [{
			type: 'pie',
			labelLine: {
				show: false
			  },
			data: [{
				value: 10,
			},{
				value: 90,
				itemStyle: {
					color: '#E4E7ED'
				}
			}],
			radius: ['30%', '55%']
		}]
	}
]

export const reportedGoodsProportion = {
  legend: {
    orient: 'vertical',
    left: 'left'
  },
  series: [{
    type: 'pie',
    data: [{
      value: 74,
      name: '普货 94%'
    },{
      value: 3,
      name: '易碎品 6%'
    }],
    radius: ['30%', '55%']
  }]
};
export const provincePayOlap = {
	xAxis: {
		type: 'category',
		data: [ '天津市', '河南省', '重庆市', '四川省', '山东省', '江苏省', '浙江省', '上海市', '北京市', '广东省']
	},
	tooltip:{
		show:true,
		formatter: '{b0}: {c0} 元'
	},
	yAxis: {
		type: 'value',
		axisLabel: {
			showMaxLabel: true,
		  	formatter: '{value}元',
	  	}
	},
	grid:{
		top:30,
		bottom:30,
		right:30,
		left:20,
		containLabel: true
	},
	series: [
		{
		data: [ 398, 412, 433, 614, 623, 800, 1000, 1021, 1033, 1233],
		type: 'bar',
		barWidth:'45%'
		}
	]
}

export const payPerCaseProportion = {
  legend: {
    orient: 'vertical',
    left: 'left',
  },
  series: [{
    type: 'pie',
	top:50,
    data: [{
      value: '2',
      name: '500元以上'
    },{
      value: '12',
      name: '300-500元'
    },{
      value: '59',
      name: '100-300元'
    }, {
      value: '18',
      name: '50-100元'
    }, {
      value: '9',
      name: '0-50元'
    }],
	radius: ['30%', '55%']
  }]
};

export const customerOLAP = {
	columns: [
		{ label: '客户编号', prop: 'no'},
		{ label: '报案数量', prop: 'caseNo'},
		{ label: '结案总数', prop: 'endCaseNo'},
		{ label: '未决数量', prop: 'undoCaseNo'},
		{ label: '结案率', prop: 'endCaseRatio'},
		{ label: '索赔金额', prop: 'claimPayment'},
		{ label: '已决赔款', prop: 'payment'},
		{ label: '未决估损金额', prop: 'undoPayment'},
	],
	data: [
		{no:"KH2322782", caseNo:"2件", endCaseNo:"1件", undoCaseNo:"1件", endCaseRatio:"50%", claimPayment:"687元", payment:"132元", undoPayment:"542元"},
		{no:"KH2321123", caseNo:"1件", endCaseNo:"0件", undoCaseNo:"1件", endCaseRatio:"0%", claimPayment:"143元", payment:"0元", undoPayment:"143元"},
		{no:"KH2321787", caseNo:"3件", endCaseNo:"1件", undoCaseNo:"2件", endCaseRatio:"33.33%", claimPayment:"1267元", payment:"1098元", undoPayment:"0元"},
		{no:"KH2321898", caseNo:"1件", endCaseNo:"1件", undoCaseNo:"0件", endCaseRatio:"100%", claimPayment:"4589元", payment:"0元", undoPayment:"4078元"},
		{no:"KH2321222", caseNo:"1件", endCaseNo:"1件", undoCaseNo:"0件", endCaseRatio:"100%", claimPayment:"45元", payment:"45元", undoPayment:"0元"}
	]
}

export const regionOLAP = {
	columns: [
		{ label: '省份', prop: 'province'},
		{ label: '报案数量', prop: 'caseNo'},
		{ label: '结案总数', prop: 'endCaseNo'},
		{ label: '已决总赔款', prop: 'payment'},
		{ label: '未决估损金额', prop: 'undoPayment'},
		{ label: '结案率', prop: 'endCaseRatio'}
	],
	data: [
		{province:"北京市", caseNo:"1723件", endCaseNo:"1701件", endCaseRatio:"98.7%", payment:"345278元", undoPayment:"3789元"},
		{province:"海南省", caseNo:"605件", endCaseNo:"567件", endCaseRatio:"93.5%", payment:"45278元", undoPayment:"13789元"},
		{province:"河北省", caseNo:"123件", endCaseNo:"101件", endCaseRatio:"95.7%", payment:"5278元", undoPayment:"3789元"},
		{province:"广东省", caseNo:"1523件", endCaseNo:"1001件", endCaseRatio:"86.3%", payment:"345278元", undoPayment:"63789元"},
	]
}

export const siteOLAP = {
	columns: [
		{ label: '站点名称', prop: 'site'},
		{ label: '报案数量', prop: 'caseNo'},
		{ label: '结案总数', prop: 'endCaseNo'},
		{ label: '已决总赔款', prop: 'payment'},
		{ label: '未决估损金额', prop: 'undoPayment'},
		{ label: '结案率', prop: 'endCaseRatio'}
	],
	data: [
		{site:"北京市站点", caseNo:"1743件", endCaseNo:"1657件", endCaseRatio:"95.3%", payment:"377689元", undoPayment:"143332元"},
		{site:"天津市站点", caseNo:"335件", endCaseNo:"301件", endCaseRatio:"93.5%", payment:"24563元", undoPayment:"1731元"},
		{site:"重庆1号站", caseNo:"897件", endCaseNo:"861件", endCaseRatio:"97.2%", payment:"52783元", undoPayment:"4733元"},
		{site:"上海5号站", caseNo:"1312件", endCaseNo:"1107件", endCaseRatio:"91.4%", payment:"234128元", undoPayment:"24893元"},
	]
}

export const goodsOLAP = {
	columns: [
		{ label: '商品类别', prop: 'goodType'},
		{ label: '报案数量', prop: 'caseNo'},
		{ label: '结案总数', prop: 'endCaseNo'},
		{ label: '已决总赔款', prop: 'payment'},
		{ label: '未决估损金额', prop: 'undoPayment'},
		{ label: '结案率', prop: 'endCaseRatio'}
	],
	data: [
		{goodType:"生鲜", caseNo:"3523件", endCaseNo:"3103件", endCaseRatio:"89.4%", payment:"334354元", undoPayment:"22412元"},
		{goodType:"家电家具", caseNo:"478件", endCaseNo:"458件", endCaseRatio:"92.4%", payment:"7976元", undoPayment:"467元"},
		{goodType:"服装", caseNo:"356件", endCaseNo:"335件", endCaseRatio:"93.6%", payment:"9033元", undoPayment:"1908元"},
		{goodType:"3C", caseNo:"223件", endCaseNo:"198件", endCaseRatio:"92.9%", payment:"4298元", undoPayment:"301元"},
	]
}


export const auditTimeDistribute = {
	xAxis: {
		type: 'category',
		data: ['0-3s', '3-5s', '5-10s', '10-20s', '20s以上']
	},
	yAxis: {
		type: 'value',
		axisLabel: {
			showMaxLabel: true,
		  	formatter: '{value}%',
	  	}
	},
	tooltip:{
		show:true,
		formatter: '{b0} : {c0} %'
	},
	grid:{
		top:30,
		bottom:30
	},
	series: [
		{
		data: [5, 12, 36, 28, 19],
		type: 'bar',
		barWidth:40
		}
	]
}
export const questionTypeChart = {
	xAxis: {
		type: 'category',
		data: ['问题1', '问题2', '问题3', '问题4', '问题5', '问题6', '问题7', '问题8']
	},
	yAxis: {
		type: 'value',
		axisLabel: {
			showMaxLabel: true,
		  	formatter: '{value}%',
	  	}
	},
	tooltip:{
		show:true,
		formatter: '{b0} : {c0} %'
	},
	grid:{
		top:30,
		bottom:30,
		left:30,
		right:30,
		containLabel: true
	},
	series: [
		{
			data: [5, 12, 36, 28, 19,8,13,45],
			type: 'bar',
			barWidth:40
		}
	]
}
export const firstAuditAvgTimeTop5 = [
	{ no: 1, label:"生鲜", seconds: 134 },
	{ no: 2, label:"酒类", seconds: 121 },
	{ no: 3, label:"服装", seconds: 78 },
	{ no: 4, label:"3C", seconds: 34 },
	{ no: 5, label:"文件/资料", seconds: 17 },
]
export const riskRuleHitRate = {
	xAxis: {
		type: 'category',
		data: ['生鲜', '家电', '3C', '酒类', '化妆品', '药品',   '服装', '卫浴', '奶粉', ]
	  },
	  tooltip:{
		show:true,
		formatter: '{b0} : {c0} 个'
	},
	  grid:{
		top:30,
		bottom:30
	},
	  yAxis: {
		type: 'value',
		axisLabel: {
			showMaxLabel: true,
		  	formatter: '{value}个',
	  	}
	  },
	  series: [{
		data: [3, 6, 5, 3, 6, 2, 5, 1, 2, 3, 4, 4],
		type: 'bar',
		barWidth:'50%'
	}]
}

export const googsTaskOLAP = {
	columns: [
		{ label: '商品类别', prop: 'goodType'},
		{ label: '报案数量', prop: 'caseNo'},
		{ label: '结案总数', prop: 'endCaseNo'},
		{ label: '结案率', prop: 'endCaseRatio'},
		{ label: '案均赔款', prop: 'avgPayment'},
		{ label: '案均减损金额', prop: 'avgDPayment'},
		{ label: '减损率', prop: 'avgDPaymentRatio'},
		{ label: '结案平均用时', prop: 'endCaseAvgTime'},
		{ label: '案均风险点', prop: 'avgDanger'},

	],
	data: [
		{goodType:"生鲜", caseNo:"123件", endCaseNo:"113件", endCaseRatio:"92.4%", avgPayment:"132元", avgDPayment:"32元", avgDPaymentRatio: "2.00%", endCaseAvgTime: "190s", avgDanger:"8"},
		{goodType:"家电家具", caseNo:"231件", endCaseNo:"203件", endCaseRatio:"93.2%", avgPayment:"467元", avgDPayment:"67元", avgDPaymentRatio: "6.00%", endCaseAvgTime: "281s", avgDanger:"10"},
		{goodType:"服装", caseNo:"1876件", endCaseNo:"1593件", endCaseRatio:"91.5%", avgPayment:"234元", avgDPayment:"24元", avgDPaymentRatio: "28%", endCaseAvgTime: "232s", avgDanger:"7"},
		{goodType:"3C", caseNo:"299件", endCaseNo:"267件", endCaseRatio:"92.0%", avgPayment:"901元", avgDPayment:"91元", avgDPaymentRatio: "25.5%", endCaseAvgTime: "182s", avgDanger:"3"},
	]
}
export const goodsTaskOLAP = {
	columns: [
		{ label: '商品类别', prop: 'goodType'},
		{ label: '质检数量', prop: 'caseNo'},
		{ label: '问题总数', prop: 'endCaseNo'},
		{ label: '问题率', prop: 'endCaseRatio'},
		{ label: '案均赔款', prop: 'avgPayment'},
		{ label: '主要问题类别', prop: 'avgDPayment'}

	],
	data: [
		{goodType:"生鲜", caseNo:"143件", endCaseNo:"5件", endCaseRatio:"3%", avgPayment:"22元", avgDPayment:"流程规范"},
		{goodType:"家电家具", caseNo:"231件", endCaseNo:"14件", endCaseRatio:"6%", avgPayment:"467元", avgDPayment:"材料不完整"},
		{goodType:"服装", caseNo:"1876件", endCaseNo:"153件", endCaseRatio:"8%", avgPayment:"75元", avgDPayment:"价格评估不合理"},
		{goodType:"3C", caseNo:"299件", endCaseNo:"18件", endCaseRatio:"6%", avgPayment:"901元", avgDPayment:"价格评估不合理"},
	]
}
export const operatorOLAP = {
	columns: [
		{ label: '人员姓名', prop: 'operator'},
		{ label: '日均作业单量', prop: 'avgTaskPerDay'},
		{ label: '总作业单量', prop: 'taskNum'},
		{ label: '操作权限', prop: 'authority'},
		{ label: '平均审核时长', prop: 'avgAuditTime'},
		{ label: '考核评分', prop: 'score'},
		{ label: '案均处理风险点个数', prop: 'avgDanger'}
	],
	data: [
		{operator:"张萍", avgTaskPerDay:"18笔", taskNum:"239笔", authority: "立案", avgAuditTime: "17秒", score: "89分", avgDanger: "9个"},
		{operator:"李帅", avgTaskPerDay:"58笔", taskNum:"192笔", authority: "录入理算", avgAuditTime: "3分", score: "74分", avgDanger: "12个"},
		{operator:"王强强", avgTaskPerDay:"31笔", taskNum:"2901笔", authority: "复审", avgAuditTime: "12分", score: "56分", avgDanger: "3个"},
		{operator:"赵凯", avgTaskPerDay:"23笔", taskNum:"640笔", authority: "立案", avgAuditTime: "43秒", score: "98分", avgDanger: "4个"}
	]
}
export const operatorTestingOLAP = {
	columns: [
		{ label: '人员姓名', prop: 'operator'},
		{ label: '问题案件量', prop: 'avgTaskPerDay'},
		{ label: '质检抽查案件量', prop: 'taskNum'},
		{ label: '操作权限', prop: 'authority'},
		{ label: '问题率', prop: 'avgAuditTime'},
		{ label: '考核评分', prop: 'score'},
		{ label: '主要问题类别', prop: 'avgDanger'}
	],
	data: [
		{operator:"张磊", avgTaskPerDay:"18笔", taskNum:"239笔", authority: "立案", avgAuditTime: "9%", score: "89分", avgDanger: "流程规范"},
		{operator:"张倩倩", avgTaskPerDay:"28笔", taskNum:"192笔", authority: "录入理算", avgAuditTime: "12%", score: "74分", avgDanger: "材料不完整"},
		{operator:"王博", avgTaskPerDay:"310笔", taskNum:"2901笔", authority: "复审", avgAuditTime: "11%", score: "78分", avgDanger: "价格评估不合理"},
		{operator:"潇枫", avgTaskPerDay:"43笔", taskNum:"640笔", authority: "立案", avgAuditTime: "7%", score: "93分", avgDanger: "材料不完整"}
	]
}