
// @ is an alias to /src
import OSS from 'ali-oss';
import Papa from 'papaparse';

// import CsvWorker from 'worker-loader!./CsvParser.worker'

export default {
    data() {
        return {
            resetOssNum: 0,
            tmpOSSClient: [],
            unloaderHeader: { token: '' }, //上传的请求头
            baseURL: '',
            fullscreenLoading: false,
            loading: false,
            tableData: [],

            currentPage: 1,
            pageSize: 10,
            totalPage: 1,
            csvTableData: [],
            csvTableHeaders: [],
            arraysEqual: true,
            csvHeaders: ['POLICY_APPLICATION_NO', 'POLICY_STATUS', 'INCEPTION_DATE', 'PLANNED_END_DATE', 'POPULATION_TIMESTAMP', 'PREMIUM_AMOUNT', 'INSURED_AMOUNT', 'APPLICANT_NAME', 'PARTNER_ORDER_NO', 'BIGPOLICYNO', 'BIZ_DATA'],
            csvTableDataExample: [
                {
                    POLICY_APPLICATION_NO: '202312250802457330010002110128530001861679',
                    POLICY_STATUS: '00',
                    INCEPTION_DATE: '2023/12/21',
                    PLANNED_END_DATE: '2024/3/20',
                    POPULATION_TIMESTAMP: '2023/12/26',
                    PREMIUM_AMOUNT: '4.66',
                    INSURED_AMOUNT: '10000.00',
                    APPLICANT_NAME: '北京京讯递科技有限公司',
                    PARTNER_ORDER_NO: 'JDX022136209521',
                    BIGPOLICYNO: 'AHAZ4A004123FN00020I',
                    BIZ_DATA:
                        '{""originOutlets"":""北京和平街营业部"",""destinationProvince"":""福建"",""senderContactNumber"":""137*****626"",""destinationAddress"":""福建泉州市永春县五里街镇碧桂园二期鹏祥居15-2-1103"",""buyerContactNumber"":""159*****972"",""originAddress"":""北京朝阳区和平街街道北京市朝阳区青年沟东路5号煤科院"",""destinationOutlets"":""泉州永春营业部"",""expandField"":"""",""weight"":3.0,""buyer"":""黄*妹"",""destinationCity"":""泉州市"",""businessLine"":""快递快运"",""originCity"":""朝阳区"",""businessType"":""订单险-中小件"",""goodsNum"":1,""policyholderTaxNumber"":""91110115MA019EXPX2"",""originProvince"":""北京""}'
                }
            ]
        };
    },
    mounted() {
        this.baseURL = this.$store.getters.baseURL;
        this.uploadFileRecordPaging();
        window.addEventListener('beforeunload', this.handleBeforeUnload);
    },
    methods: {
        // 初始化OSS
        initOSSClient() {
            this.tmpOSSClient = null;
            this.resetOssNum++;
            this.instance
                .get('/tpa/api/aliyun/oss/access')
                .then((res) => {
                    let data = res.data;
                    if (data.code === 200) {
                        this.tmpOSSClient = new OSS({
                            accessKeyId: data.data.accessKeyId,
                            accessKeySecret: data.data.accessKeySecret,
                            stsToken: data.data.securityToken,
                            // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                            region: data.data.region,
                            bucket: data.data.bucket
                        });

                        this.tmpOSSClient.resetOss = true;
                    } else {
                        console.log('oss初始化失败，正在重试！');

                        if (this.resetOssNum < 20) {
                            setTimeout(() => {
                                this.initOSSClient();
                            }, 1000);
                        } else {
                            console.log('oss初始化超过20s，失败!');
                        }
                    }
                })
                .catch((err) => {
                    console.log('oss初始化失败-');
                    console.log(err);
                });
        },
        //OSS直传
        ossUpload(e) {
            var _this = this;
            const OSS = require('ali-oss');
            let file = e.file;
            let storeAs = 'upload-file';
            let protocol = location.protocol;
            // let tmpRowItem = JSON.parse(JSON.stringify(rowItem))
            let options = {
                // 获取分片上传进度、断点和返回值。
                progress: (p, cpt, res) => {
                    console.log(p);
                },
                // 设置并发上传的分片数量。
                parallel: 6,
                // 设置分片大小。默认值为1 MB，最小值为100 KB。
                partSize: 1024 * 1024,
                // headers,
                mime: 'text/plain'
            };

            // OSS.urllib是SDK内部封装的发送请求的逻辑，开发者可以使用任何发送请求的库向sts-server发送请求。
            OSS.urllib.request(protocol + _this.baseURL + '/tpa/api/aliyun/oss/access', { method: 'GET' }, (err, response) => {
                try {
                    var result = JSON.parse(response).data;
                } catch (e) {
                    console.log('parse sts response info error: ' + e.message);
                }
                let client = new OSS({
                    accessKeyId: result.accessKeyId,
                    accessKeySecret: result.accessKeySecret,
                    stsToken: result.securityToken,
                    // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                    region: result.region,
                    bucket: result.bucket
                });
                // storeAs可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
                // file可以自定义为File对象、Blob数据以及OSS Buffer。
                let timestampInMilliseconds = new Date().getTime();
                storeAs = result.path + '/CPIC/' + timestampInMilliseconds + '/' + file.name;
                setTimeout(() => {
                    if (_this.arraysEqual) {
                        client
                            .multipartUpload(storeAs, file, {
                                ...options
                            })
                            .then(function (result) {
                                console.log(result);
                                let path = result.name;
                                _this.ossUploadCallback(path, file.name);
                                _this.uploadFileRecordPaging();
                                window.removeEventListener('beforeunload', _this.handleBeforeUnload);
                                // storeAs - 阿里返回图片路径
                                _this.fullscreenLoading = false;
                            })
                            .catch(function (err) {
                                console.log(err);
                                _this.fullscreenLoading = false;
                                _this.$message.error('文件上传失败!请稍后重试!');
                                window.removeEventListener('beforeunload', _this.handleBeforeUnload);
                            });
                    }
                }, 500);
            });
        },
        //上传文件之前的钩子
        beforeUpload(file) {
            const validExtensions = ['.csv'];
            this.fullscreenLoading = true;
            let fileName = file.name;
            let isValidFile = validExtensions.some((extension) => fileName.toLowerCase().endsWith(extension));
            if (!isValidFile) {
                this.$message.error('上传文件格式不正确!只能上传CSV文件!');
                this.fullscreenLoading = false;
            }

            // this.parseCsv(file)
            //     .then((data) => {
            //         // this.csvTableData = data;
            //         // this.csvTableHeaders = Object.keys(data[0] || {});
            //         // this.arraysEqual = this.arraysAreEqual(this.csvHeaders, this.csvTableHeaders);
            //         // if (this.arraysEqual) {
            //         //     console.log(this.arraysEqual);
            //         //     return true;
            //         // } else {
            //         //     this.fullscreenLoading = false;
            //         //     this.csvTableData = [];
            //         //     this.csvTableHeaders = [];
            //         //     this.$message.error('上传文件内容格式不正确!请重新调整数据!');
            //         //     window.removeEventListener('beforeunload', this.handleBeforeUnload);
            //         //     return false;
            //         // }
            //     })
            //     .catch((error) => {
            //         this.$message.error('上传文件内容格式不正确!请重新调整数据!');
            //         console.error('CSV解析错误:', error.message);
            //     });
        },

        parseCsv(file) {
            return new Promise((resolve, reject) => {
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    encoding: 'utf-8',
                    complete: (results) => {
                        resolve(results.data);
                    },
                    error: (error) => {
                        reject(error);
                    }
                });
            });
        },

        arraysAreEqual(arr1, arr2) {
            return arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
        },
        //文件上传成功时的钩子
        handleSuccess(res, file) {
            console.log('上传成功的钩子');
            console.log(res);
            console.log(file);
            this.fullscreenLoading = false;
            this.uploadFileRecordPaging();
            this.csvTableHeaders = [];
            this.csvTableData = [];
        },
        //索赔资料处照片点击文件列表中已上传的文件时的钩子
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisibleBigImg = true;
            console.log('点击文件列表中已上传的文件时的钩子');
            console.log(file.url);
        },

        ossUploadCallback(path, name) {
            let param = {
                ossPath: path,
                fileName: name
            };
            this.instance.post('/tpa/api/aliyun/oss/saveUploadRecord', param).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.$message.success('文件上传成功');
                }
            });
        },

        uploadFileRecordPaging() {
            this.loading = true;
            this.tableData = []; //  清空table的值

            let json = {};
            json.pageNo = this.currentPage;
            json.pageSize = this.pageSize;

            this.instance.post('/tpa/api/aliyun/oss/paging', json).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.loading = false;
                    this.tableData = data.data.list;
                    this.totalPage = parseInt(res.data.data.total);
                }
            });
        },

        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val;
            this.uploadFileRecordPaging();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange(val) {
            this.currentPage = Number(val);
            this.uploadFileRecordPaging();
        },

        handleBeforeUnload(event) {
            // 在页面即将卸载时触发的处理函数
            console.log(event.message);
            // this.$confirm('您的文件尚未上传完成,确定要离开吗?', '提示', {
            //     confirmButtonText: '确认',
            //     cancelButtonText: '取消',
            //     type: 'warning'
            // }).then(() => {
            //     this.$destroy()
            // }).catch(() => {
            //
            // })
            const isConfirmed = window.confirm('您的文件尚未上传完成,确定要离开吗?');
            if (isConfirmed) {
                // 用户点击确认，取消 beforeunload 事件监听
                window.removeEventListener('beforeunload', this.handleBeforeUnload);
                // 手动刷新页面
                location.reload();
            } else {
                // 用户点击取消，阻止页面刷新
                event.preventDefault();
            }
        }
    },

    beforeDestroy() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }
};
