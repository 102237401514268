
import ExpressionBuilder from '@/components/ExpressionBuilder';
export default {
    name: 'RuleDetail',
    components: {
        ExpressionBuilder
    },
    props: {
        dialogVisibleRule: Boolean,
        activeName: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            scene: null,
            loading: false,
            innerVisible: false,
            dialogVisible: false,
            riskType: '',
            navForm: {
                ruleCode: '',
                ruleName: '',
                createBy: '',
                startCreateTime: '',
                endCreateTime: ''
            },
            riskForm: {
                name: '',
                desc: '',
                deductibleAmount: '',
                payoutLimitAmount: '',
                damageRatio: '',
                payoutRatioLimit: ''
            },
            caseList: [],
            testForm: { case: '' },
            caseEndStepOption: [],
            currentPage: 1, //当前页
            pageSize: 10, //一页多少条数据
            totalPage: 1, //总页数tableData:[],
            tableData: [],
            caseImport: false,
            features: [],
            query: {
                type: '',
                typeName: '',
                symbol: '',
                children: [
                    {
                        field: '',
                        fieldName: '',
                        operator: '',
                        operatorDesc: '',
                        symbol: '',
                        term: '',
                        termName: '',
                        constant: '',
                        children: {
                            operator: '',
                            operatorDesc: '',
                            symbol: '',
                            term: '',
                            termName: '',
                            constant: ''
                        }
                    }
                ]
            },
            formRules: {
                name: [{ required: true, message: '请输入规则名称', trigger: 'blur' }],
                desc: [{ required: true, message: '请输入规则描述', trigger: 'blur' }],
                deductibleAmount: [{ required: true, message: '请输入免赔金额', trigger: 'blur' }],
                payoutLimitAmount: [{ required: true, message: '请输入赔付限额', trigger: 'blur' }],
                damageRatio: [{ required: true, message: '请输入损失比例上限', trigger: 'blur' }],
                payoutRatioLimit: [{ required: true, message: '请输入赔付比例1上限', trigger: 'blur' }]
            },
            expressPassStatus: false,
            formulaShow: ''
        };
    },
    methods: {
        expressPass(status) {
            this.expressPassStatus = status;
            console.log('规则校验：' + status);
            console.log(this.expressPassStatus);
        },
        onlyNumber(key, value) {
            this.riskForm[key] = this.onlyNumOnePoint(value);
        },
        onlyNumOnePoint(number_only) {
            //先把非数字的都替换掉，除了数字和小数点
            number_only = number_only.replace(/[^\d.]/g, '');
            //必须保证第一个为数字而不是小数点
            number_only = number_only.replace(/^\./g, '');
            //保证只有出现一个小数点而没有多个小数点
            number_only = number_only.replace(/\.{2,}/g, '.');
            //保证小数点只出现一次，而不能出现两次以上
            number_only = number_only.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
            //保证只能输入两个小数
            number_only = number_only.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
            return number_only;
        },
        caseChange(val) {
            this.caseImport = val ? true : false;
        },
        // 获取特征
        getFeatureList() {
            this.instance.post('/risk/api/feature/definition/paging', { pageNo: 1, pageSize: 100 }).then(({ data }) => {
                const list = data.data.list;
                const columns = [];
                const values = list.map((item) => item.featureName);
                for (var i = 0; i < list.length; i++) {
                    const feature = list[i];
                    columns.push({
                        field: feature.featureCode,
                        label: feature.featureName,
                        type: this.getDataType(feature.dataType)
                    });
                }
                this.features = columns;
            });
        },
        getDataType(code) {
            switch (code) {
                case 20:
                case 30:
                case 40:
                case 50:
                    return 'number';
                case 70:
                    return 'boolean';
                default:
                    return 'string';
            }
        },
        beforeClose(done) {
            // console.log(done)
            this.$emit('beforeClose');
        },
        //点击添加或者修改按钮
        handleRisk(row) {
            this.$refs.riskForm && this.$refs.riskForm.resetFields();
            this.query = this.$options.data().query;
            this.dialogVisible = true;
        },
        //操作测试
        handleTest(row) {
            row.btnLoading = true;
            this.innerVisible = true;
            this.definition(row.ruleCode, row);
        },
        definition(ruleCode, row) {
            this.instance.post(`/risk/api/rule/definition/${ruleCode}`).then((res) => {
                row.btnLoading = false;
                let data = res.data;
                if (data.code == 200) {
                    this.formulaShow = data.data.formulaShow ? data.data.formulaShow : '--';
                }
            });
        },
        //点击提交
        handleAddRiskPiont(formRulesName) {
            let json = {
                output: {
                    failFeatureValue: '',
                    featureCode: ''
                }
            };
            let form = {
                deductibleAmount: this.riskForm.deductibleAmount,
                payoutLimitAmount: this.riskForm.payoutLimitAmount,
                damageRatio: this.riskForm.damageRatio,
                payoutRatioLimit: this.riskForm.payoutRatioLimit
            };
            json.scene = this.scene;
            json.name = this.riskForm.name;
            json.desc = this.riskForm.desc;
            json.formula = this.queryToSql(this.query);
            json.formulaShow = this.queryToSqlShow(this.query);
            json.output.featureValue = JSON.stringify(form);
            let sign = this.riskForm.code ? 'edit' : 'config';

            // 规则组校验
            // this.$refs['expression'].verificationRules()

            // 校验通过提交数据
            this.$refs[formRulesName].validate((valid) => {
                // console.log('校验')
                // console.log(valid && this.expressPassStatus)
                // if (valid && this.expressPassStatus) {
                if (valid) {
                    this.submitDefinition(sign, json);
                }
            });
        },
        submitDefinition(sign, json) {
            this.instance.post('/risk/api/rule/definition/' + sign, json).then((res) => {
                if (res.data.code == 200) {
                    this.$message.success('添加成功！');
                    this.dialogVisible = false;
                    this.getTbodyData();
                    this.$emit('getStatistics');
                }
            });
        },
        getTbodyData(riskType) {
            riskType && (this.riskType = riskType);
            this.scene = this.activeName == '全部' ? null : this.activeName;
            this.loading = true;
            this.tableData = []; //  清空table的值
            var condition = JSON.parse(JSON.stringify(this.navForm));
            condition.scene = this.scene;
            // condition.riskType=this.riskType;
            let json = {
                condition: condition,
                pageNo: this.currentPage,
                pageSize: this.pageSize
            };
            this.instance.post('/risk/api/rule/definition/paging', json).then((res) => {
                // console.log(res);
                this.loading = false;
                if (res.data.code == 200) {
                    let list = res.data.data.list;
                    list.length > 0 &&
                        list.forEach((val) => {
                            let ruleJson = JSON.parse(val.ruleJson);
                            val.formulaShow = ruleJson.formulaShow;
                            if (ruleJson.output) {
                                let featureValue = JSON.parse(ruleJson.output.featureValue);
                                let deductibleAmount = featureValue.deductibleAmount ? '免赔金额' + featureValue.deductibleAmount + '元，' : '';
                                let payoutLimitAmount = featureValue.payoutLimitAmount ? '赔付限额' + featureValue.payoutLimitAmount + '元，' : '';
                                let damageRatio = featureValue.damageRatio ? '损失比例上限' + featureValue.damageRatio + '%，' : '';
                                let payoutRatioLimit = featureValue.payoutRatioLimit ? '赔付比例1上限' + featureValue.payoutRatioLimit + '%' : '';
                                val.featureValue = deductibleAmount + payoutLimitAmount + damageRatio + payoutRatioLimit;
                                // console.log(ruleJson.output.featureValue )
                            }
                        });
                    this.tableData = list;
                    this.totalPage = parseInt(res.data.data.total);
                }
                // console.log(this.tableData);
            });
        },
        //点击查询
        searchTable() {
            this.currentPage = 1;
            this.getTbodyData();
        },
        //重置筛选项
        resetForm() {
            this.$refs.navForm.resetFields();
            this.navForm.endCreateTime = '';
        },
        onStatusChange: function (row) {
            this.$data.loading = true;
            this.instance
                .post('/risk/api/rule/definition/edit', { code: row.ruleCode, status: row.status })
                .then(({ data }) => {
                    this.$data.loading = false;
                    const operate = row.status == 0 ? '启用' : '停用';
                    if (data.code == 200) {
                        this.$message({ message: '规则' + row.ruleName + '已' + operate, type: 'success' });
                    } else {
                        this.$message.error('规则' + row.ruleName + operate + '失败！');
                    }
                })
                .catch((_) => (this.$data.loading = false));
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val;
            this.getTbodyData();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange(val) {
            // console.log(val);
            this.currentPage = Number(val);
            this.getTbodyData();
        }
    },
    mounted() {
        //
        // this.getTbodyData();
        this.getFeatureList();
    }
};
