
export default {
    name: 'featureList',
    components: {},
    props: {
        menuType: {
            type: String,
            default: '99'
        }
    },
    data() {
        return {
            loading: false,
            dialogVisible: false,
            dialogVisibleUser: false,
            tableData: [],
            sceneForm: {
                sceneName: '',
                parentSceneCode: '',
                sceneDesc: ''
            },
            parentData: [],
            rules: {
                sceneName: [{ required: true, message: '场景名称不能为空', trigger: 'change' }],
                parentSceneCode: [{ required: true, message: '父场景不能为空', trigger: 'change' }]
            }
        };
    },
    methods: {
        //点击添加按钮
        handleFeature() {
            this.$refs.sceneForm && this.$refs.sceneForm.resetFields();
            this.dialogVisible = true;
        },
        // 保存场景
        handleAddScene() {
            this.loading = true;
            this.$refs['sceneForm'].validate((valid) => {
                if (valid) {
                    let feature = JSON.parse(JSON.stringify(this.sceneForm));
                    this.instance
                        .post('/risk/api/scene/definition/add', feature)
                        .then(({ data }) => {
                            this.loading = false;
                            if (data.code === 200) {
                                this.$message({ message: '场景配置成功', type: 'success' });
                                this.sceneForm.visible = false;
                                this.getParentData();
                                this.dialogVisible = false;
                            } else {
                                this.$message({ message: data.message, type: 'error' });
                            }
                        })
                        .catch((err) => {
                            this.loading = false;
                            this.$message({ message: '保存失败，网络错误', type: 'error' });
                        });
                }
            });
        },
        //获取tbody里的数据
        getParentData() {
            this.loading = true;
            this.tableData = []; //  清空table的值
            this.instance.get('/risk/api/scene/definition/parentList').then((res) => {
                this.loading = false;
                this.parentData = res.data.data;
            });
        }
    },
    mounted() {
        //
        this.getParentData();
    }
};
