
// @ is an alias to /src
// import MyHeader from '@/components/MyHeader.vue'
export default {
    name: 'qualityTesting',
    components: {},
    props: {
        qualityTestingVisible: {
            type: Boolean,
            default: false
        },
        caseNo: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            loading: false,
            conclusionForm: {
                paradigm: false
            },
            problemData: [],
            publicPriblem: {
                questionLevelDesc: '',
                questionTypeDesc: '',
                questionNo: '',
                questionDesc: '',
                remark: ''
            },
            problemOption: [],
            caseQualityConclusionOption: [],
            rules: {
                conclusion: [{ required: true, message: '质检质量不能为空', trigger: 'change' }]
            }
        };
    },
    methods: {
        //质检结论处 问题change事件
        problemChange(items, index) {
            this.problemOption.forEach((val, key) => {
                if (items.questionNo == val.questionNo) {
                    this.problemData[index].questionLevelDesc = val.questionLevelDesc;
                    this.problemData[index].questionTypeDesc = val.questionTypeDesc;
                    this.problemData[index].questionNo = val.questionNo;
                    this.problemData[index].questionDesc = val.questionDesc;
                }
            });
        },
        handleSubmit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    let json = JSON.parse(JSON.stringify(this.conclusionForm));
                    json.questions = this.problemData;
                    json.caseNo = this.caseNo;
                    this.instance.post('/tpa/api/case/quality/add', json).then((res) => {
                        if (res.data.code == 200) {
                            this.$emit('closeDialog');
                            this.$message.success('提交成功！');
                        } else {
                            this.$message.error(res.data.message);
                        }
                    });
                }
            });
        },
        //获取质检问题
        getQuestionList() {
            let json = { condition: { enabled: true } };
            json.pageNo = 1;
            json.pageSize = 50;
            this.instance.post('/tpa/api/quality/question/paging', json).then((res) => {
                // console.log(res);
                this.problemOption = res.data.data.list;
            });
        },
        //删除问题
        deleteProblem(index) {
            this.problemData.splice(index, 1);
        },
        //添加问题
        addProblem() {
            let publicPriblem = JSON.parse(JSON.stringify(this.publicPriblem));
            this.problemData.push(publicPriblem);
        },
        //关闭当前dialog
        closeDialog() {
            this.$emit('closeDialog');
        }
    },
    mounted() {
        //
        this.getPublicEnum('getCaseQualityConclusionEnum', 'caseQualityConclusionOption'); //获取质检结论枚举
        this.getQuestionList();
    }
};
