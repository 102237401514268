
// @ is an alias to /src
import UserManageHl from '@/package/userManageHl/index.vue'
export default {
    name: 'userManage',
    components: {
        UserManageHl
    },
    data() {
        return {
            loading: false,
            menuType: 'searchReviewPage'
        }
    },
    methods: {},
    mounted() {
        //
    }
}
