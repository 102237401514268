import { render, staticRenderFns } from "./ruleManage.vue?vue&type=template&id=4fd88b40&scoped=true"
import script from "./ruleManage.vue?vue&type=script&lang=js"
export * from "./ruleManage.vue?vue&type=script&lang=js"
import style0 from "./ruleManage.vue?vue&type=style&index=0&id=4fd88b40&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fd88b40",
  null
  
)

export default component.exports