
import RiskDetail from './components/RiskDetail.vue';
import RuleDetail from './components/RuleDetail.vue';
export default {
    name: 'RuleManage',
    components: {
        RiskDetail,
        RuleDetail
    },
    props: {
        menuType: {
            type: String,
            default: '99'
        }
    },
    data() {
        return {
            loading: false,
            activeName: '全部',
            dialogVisibleRisk: false,
            dialogVisibleRule: false,
            statistics: {
                核赔参数规则: {},
                人员风险: {},
                材料风险: {},
                作业风险: {}
            },
            tabsData: []
        };
    },
    methods: {
        //关闭模态框
        beforeClose() {
            this.dialogVisibleRisk = false;
            this.dialogVisibleRule = false;
        },
        //点击查看详情
        handleLookDetail(val) {
            if (val == '核赔参数规则') {
                this.dialogVisibleRule = true;
                this.$refs.ruleChild.getTbodyData();
            } else {
                this.dialogVisibleRisk = true;
                this.$refs.riskChild.getTbodyData(val);
            }
        },
        //点击标签页
        handleClickTab() {},
        //获取场景数据
        getSceneData() {
            this.loading = true;
            this.instance.get('/risk/api/scene/definition/parentList', {}).then((res) => {
                if (res.data.code) {
                    this.tabsData = res.data.data;
                }
            });
        },
        //获取统计
        getStatistics() {
            let sceneCode = this.activeName == '全部' ? '' : this.activeName;
            this.instance.get('/risk/api/risk/statistics', { params: { sceneCode: sceneCode } }).then((res) => {
                if (res.data.code) {
                    this.statistics = res.data.data;
                }
            });
        }
    },
    mounted() {
        //
        this.getStatistics();
        this.getSceneData();
    }
};
