
import * as echarts from 'echarts'
export default {
    data() {
        return {
            queryForm: {
                condition: {
                    endTime: '',
                    startTime: ''
                },
                orders: [],
                pageNo: 1,
                pageSize: 1000
            },
            tableDataOrders: [],
            queryDate: '',
            pickerOptions: this.expireTimeOptionFun(),
            chart9Retract: false,
            tableData: [],
            chart1: null,
            chart2: null,
            chart3: null,
            chart4: null,
            chart5: null,
            chart6: null,
            chart7: null,
            chart8: null,
            chart9: null,
            chart10: null,
            chart11: null,
            chart12: null,
            chart13: null,
            chart14: null,
            chart15: null,
            chart16: null,
            chart17: null,
            chart18: null,
            chart19: null,
            chart20: null
        }
    },
    mounted() {
        const end = new Date()
        const start = new Date()
        this.queryDate = [this.getNowTime(start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)), this.getNowTime(new Date())]
        this.searchForm()
    },
    methods: {
        searchForm() {
            this.queryForm.condition.startTime = this.queryDate[0] + ' 00:00:00'
            this.queryForm.condition.endTime = this.queryDate[1] + ' 23:59:59'
            this.getCaseSimpleTrend()
            this.getSimpleTrend()
            this.getProgress()
            this.getPercent()
            this.getAmount()
            this.getAmountPercent()
            this.getAccidentGoods()
            this.getAccidentGoodsPercent()
            this.getAccidentGoodsUndecided()
            this.getCaseReporter()
            this.getCaseReporterPercent()
            this.getCaseReporterUndecided()
            this.getCaseAccidentReason()
            this.getAccidentReason()
            this.getCaseAccidentUndecided()
            this.getCaseDailyTrend()
            this.getDailyThrendStack()
            this.getDailyThrendPre()
            this.getCaseAccidentPerson()
        },
        // 简单赔付率趋势
        getCaseSimpleTrend() {
            this.instance.post('/tpa/api/dashboard/case/claim/simple/trend', this.queryForm).then((res) => {
                let data = res.data
                let tmpLegendData = ['']
                let tmpXAxisData = []
                let tmpSeriesDataObj = {
                    tmpSeriesData1: []
                }
                if (data.code == 200 && data.data && data.data.data.list.length > 0) {
                    let tmpList = data.data.data.list
                    tmpList.forEach((dataItem) => {
                        tmpXAxisData.push(dataItem.yearMonth)
                        tmpSeriesDataObj.tmpSeriesData1.push(dataItem.caseClaimPercent)
                    })
                    this.chart18 && this.chart18.dispose()
                    this.chart18 = echarts.init(document.getElementById('chart18'))
                    // 折线图
                    let option = this.returnLineOption(tmpLegendData, tmpXAxisData, tmpSeriesDataObj)
                    // 显示数值
                    for (let i = 0; i < option.series.length; i++) {
                        const element = option.series[i]
                        element.itemStyle = {
                            normal: {
                                label: {
                                    show: true, //开启显示
                                    position: 'top', //在上方显示
                                    textStyle: {
                                        //数值样式
                                        color: 'black',
                                        fontSize: 12
                                    }
                                }
                            }
                        }
                        element.label = {
                            normal: {
                                formatter: '{c}%',
                                textStyle: {
                                    // 提示文字的样式
                                    color: '#3e5dd3',
                                    fontSize: 14
                                }
                            }
                        }
                    }
                    this.chart18.setOption(option)
                    this.chart18Loading = false
                }
            })
        },
        // 整体理赔数据分析
        getSimpleTrend() {
            this.queryForm.orders = this.tableDataOrders
            this.instance.post('/tpa/api/dashboard/case/claim/simple/trend', this.queryForm).then((res) => {
                let data = res.data
                if (data.code == 200 && data.data && data.data.data.list.length > 0) {
                    this.tableData = data.data.data.list
                }
            })
        },
        sortChange(column) {
            // column.ascending==上升  column.descending==下降  column.prop
            if (!column.order) {
                this.tableDataOrders = []
            } else {
                let obj = {
                    asc: column.order == 'descending' ? false : true,
                    column: column.prop
                }
                this.tableDataOrders = [obj]
            }
            this.getSimpleTrend()
        },
        // 案件进展情况
        getProgress() {
            this.instance.post('/tpa/api/dashboard/case/progress', this.queryForm).then((res) => {
                let data = res.data
                if (data.code == 200 && data.data && data.data.data.list.length > 0) {
                    let tmpList = data.data.data.list
                    let tmpData = []
                    let tmpNum = 0
                    tmpList.forEach((dataItem) => {
                        tmpData.push({
                            name: dataItem.caseProgress,
                            value: dataItem.count
                        })
                        tmpNum += dataItem.count
                    })
                    this.chart1 && this.chart1.dispose()
                    let option = {
                        title: {
                            text: `${tmpNum}\n共计`,
                            left: 'center',
                            top: '55%',
                            textStyle: {
                                fontSize: 14
                            }
                        },
                        tooltip: {
                            trigger: 'item',
                            formatter: `{b} : {c}<br/>占比： {d}%`
                        },
                        legend: {
                            top: '5%',
                            left: 'center'
                        },
                        series: [
                            {
                                name: '',
                                type: 'pie',
                                radius: '50%',
                                radius: ['40%', '70%'],
                                center: ['50%', '60%'],
                                data: tmpData,
                                label: {
                                    normal: {
                                        formatter: '{b}  {d}%',
                                        textStyle: {
                                            // 提示文字的样式
                                            color: '#595959',
                                            fontSize: 14
                                        }
                                    }
                                },
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }
                            }
                        ]
                    }
                    this.chart1 = echarts.init(document.getElementById('chart1'))
                    this.chart1.setOption(option)
                }
            })
        },
        // 不同阶段案件赔付占比
        getPercent() {
            this.instance.post('/tpa/api/dashboard/case/progress/percent', this.queryForm).then((res) => {
                let data = res.data
                if (data.code == 200 && data.data && data.data.data.list.length > 0) {
                    let tmpList = data.data.data.list
                    let tmpxAxis = []
                    let tmpData1 = []
                    let tmpData2 = []
                    tmpList.forEach((dataItem) => {
                        tmpxAxis.push(dataItem.caseProgress)
                        tmpData1.push(dataItem.caseClaimAmount)
                        tmpData2.push(dataItem.caseCalculateAmount)
                    })

                    this.chart2 && this.chart2.dispose()

                    let option = {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                crossStyle: {
                                    color: '#999'
                                }
                            }
                        },
                        legend: {
                            data: ['案件索赔金额(万元)', '案件结案金额(万元)']
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: tmpxAxis,
                                axisLabel: { interval: 0, rotate: 20 }
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value'
                            }
                        ],
                        series: [
                            {
                                name: '案件索赔金额(万元)',
                                type: 'bar',
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value
                                    }
                                },
                                //显示数值
                                itemStyle: {
                                    normal: {
                                        label: {
                                            show: true, //开启显示
                                            position: 'top', //在上方显示
                                            textStyle: {
                                                //数值样式
                                                color: 'black',
                                                fontSize: 12
                                            }
                                        }
                                    }
                                },
                                data: tmpData1
                            },
                            {
                                name: '案件结案金额(万元)',
                                type: 'bar',
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value
                                    }
                                },
                                //显示数值
                                itemStyle: {
                                    normal: {
                                        label: {
                                            show: true, //开启显示
                                            position: 'top', //在上方显示
                                            textStyle: {
                                                //数值样式
                                                color: 'black',
                                                fontSize: 12
                                            }
                                        }
                                    }
                                },
                                data: tmpData2
                            }
                        ]
                    }
                    this.chart2 = echarts.init(document.getElementById('chart2'))
                    this.chart2.setOption(option)
                }
            })
        },
        // 已结案件金额分类
        getAmount() {
            this.instance.post('/tpa/api/dashboard/case/closed/amount', this.queryForm).then((res) => {
                let data = res.data
                if (data.code == 200 && data.data && data.data.data.list.length > 0) {
                    let tmpList = data.data.data.list
                    let tmpData = []
                    let tmpNum = 0
                    tmpList.forEach((dataItem) => {
                        tmpData.push({
                            name: dataItem.caseClosedAmountRange,
                            value: dataItem.count
                        })
                        tmpNum += dataItem.count
                    })
                    this.chart3 && this.chart3.dispose()
                    let option = {
                        title: {
                            text: `${tmpNum}\n共计`,
                            left: 'center',
                            top: '60%',
                            textStyle: {
                                fontSize: 14
                            }
                        },
                        tooltip: {
                            trigger: 'item',
                            formatter: `{b} : {c}<br/>占比： {d}%`
                        },
                        legend: {
                            top: '5%',
                            left: 'center'
                        },
                        series: [
                            {
                                name: '',
                                type: 'pie',
                                radius: '50%',
                                radius: ['40%', '70%'],
                                center: ['50%', '60%'],
                                data: tmpData,
                                label: {
                                    normal: {
                                        formatter: '{b}  {d}%',
                                        textStyle: {
                                            // 提示文字的样式
                                            color: '#595959',
                                            fontSize: 14
                                        }
                                    }
                                },
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }
                            }
                        ]
                    }
                    this.chart3 = echarts.init(document.getElementById('chart3'))
                    this.chart3.setOption(option)
                }
            })
        },
        // 不同金额范围占比
        getAmountPercent() {
            this.instance.post('/tpa/api/dashboard/case/closed/amount/percent', this.queryForm).then((res) => {
                let data = res.data
                if (data.code == 200 && data.data && data.data.data.list.length > 0) {
                    let tmpList = data.data.data.list
                    let tmpxAxis = []
                    let tmpData1 = []
                    let tmpData2 = []
                    tmpList.forEach((dataItem) => {
                        tmpxAxis.push(dataItem.caseClosedAmountRange)
                        tmpData1.push(dataItem.caseClaimAmount)
                        tmpData2.push(dataItem.caseCalculateAmount)
                    })

                    this.chart4 && this.chart4.dispose()

                    let option = {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                crossStyle: {
                                    color: '#999'
                                }
                            }
                        },
                        legend: {
                            data: ['索赔总金额(万元)', '结案总金额(万元)']
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: tmpxAxis,
                                axisLabel: { interval: 0, rotate: 20 },
                                axisPointer: {
                                    type: 'shadow'
                                }
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value'
                            }
                        ],
                        series: [
                            {
                                name: '索赔总金额(万元)',
                                type: 'bar',
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value
                                    }
                                },
                                //显示数值
                                itemStyle: {
                                    normal: {
                                        label: {
                                            show: true, //开启显示
                                            position: 'top', //在上方显示
                                            textStyle: {
                                                //数值样式
                                                color: 'black',
                                                fontSize: 12
                                            }
                                        }
                                    }
                                },
                                data: tmpData1
                            },
                            {
                                name: '结案总金额(万元)',
                                type: 'bar',
                                tooltip: {
                                    valueFormatter: function (value) {
                                        return value
                                    }
                                },
                                //显示数值
                                itemStyle: {
                                    normal: {
                                        label: {
                                            show: true, //开启显示
                                            position: 'top', //在上方显示
                                            textStyle: {
                                                //数值样式
                                                color: 'black',
                                                fontSize: 12
                                            }
                                        }
                                    }
                                },
                                data: tmpData2
                            }
                        ]
                    }
                    this.chart4 = echarts.init(document.getElementById('chart4'))
                    this.chart4.setOption(option)
                }
            })
        },
        // 出险商品类别分类统计
        getAccidentGoods() {
            this.instance.post('/tpa/api/dashboard/case/accident/goods', this.queryForm).then((res) => {
                let data = res.data
                if (data.code == 200 && data.data && data.data.data.list.length > 0) {
                    let tmpList = data.data.data.list
                    let tmpData = []
                    tmpList.forEach((dataItem) => {
                        tmpData.push({
                            name: dataItem.goodsCategory,
                            value: dataItem.count
                        })
                    })
                    this.chart5 && this.chart5.dispose()
                    this.chart5 = echarts.init(document.getElementById('chart5'))
                    // 饼图
                    this.chart5.setOption(this.returnPieOption(tmpData))
                }
            })
        },
        // 不同出险商品类别赔付比(已决)
        getAccidentGoodsPercent() {
            this.instance.post('/tpa/api/dashboard/case/accident/goods/percent', this.queryForm).then((res) => {
                let data = res.data
                let tmpLegendData = ['索赔总金额(万元)', '结案总金额(万元)', '索赔总金额[扣除免赔](万元)', '案件结案赔付比例']
                let tmpXAxisData = []
                let tmpSeriesDataObj = {
                    tmpSeriesData1: [],
                    tmpSeriesData2: [],
                    tmpSeriesData3: [],
                    tmpSeriesData4: []
                }
                if (data.code == 200 && data.data && data.data.data.list.length > 0) {
                    let tmpList = data.data.data.list
                    tmpList.forEach((dataItem) => {
                        tmpXAxisData.push(dataItem.goodsCategory)
                        tmpSeriesDataObj.tmpSeriesData1.push(dataItem.caseClaimAmount)
                        tmpSeriesDataObj.tmpSeriesData2.push(dataItem.caseCalculateAmount)
                        tmpSeriesDataObj.tmpSeriesData3.push(dataItem.caseClaimAmountDeductible)
                        if (dataItem.caseClosingPercent.length > 1) {
                            dataItem.caseClosingPercent = dataItem.caseClosingPercent.slice(0, dataItem.caseClosingPercent.length - 1)
                            tmpSeriesDataObj.tmpSeriesData4.push(Number(dataItem.caseClosingPercent))
                        } else {
                            tmpSeriesDataObj.tmpSeriesData4.push(0)
                        }
                    })
                    this.chart6 && this.chart6.dispose()
                    this.chart6 = echarts.init(document.getElementById('chart6'))
                    // 柱状图
                    let option = this.returnBarLineOption(tmpLegendData, tmpXAxisData, tmpSeriesDataObj)
                    for (let i = 0; i < option.series.length; i++) {
                        const element = option.series[i]
                        element.itemStyle = {
                            normal: {
                                label: {
                                    show: true, //开启显示
                                    position: 'top', //在上方显示
                                    rotate: 80, //标签旋转。从-90度到90度。正值是逆时针。
                                    textStyle: {
                                        //数值样式
                                        color: 'black',
                                        fontSize: 12
                                    }
                                }
                            }
                        }
                    }
                    this.chart6.setOption(option)
                }
            })
        },
        // 不同出险商品类别赔付比(未决)
        getAccidentGoodsUndecided() {
            this.instance.post('/tpa/api/dashboard/case/accident/goods/percent/undecided', this.queryForm).then((res) => {
                let data = res.data
                let tmpLegendData = ['索赔总金额(万元)', '定损总金额(万元)']
                let tmpXAxisData = []
                let tmpSeriesDataObj = {
                    tmpSeriesData1: [],
                    tmpSeriesData2: []
                }
                if (data.code == 200 && data.data && data.data.data.list.length > 0) {
                    let tmpList = data.data.data.list
                    tmpList.forEach((dataItem) => {
                        tmpXAxisData.push(dataItem.goodsCategory)
                        tmpSeriesDataObj.tmpSeriesData1.push(dataItem.caseClaimAmount)
                        tmpSeriesDataObj.tmpSeriesData2.push(dataItem.caseCalculateAmount)
                    })
                    this.chart7 && this.chart7.dispose()
                    this.chart7 = echarts.init(document.getElementById('chart7'))
                    // 折线图
                    let option = this.returnBarOption(tmpLegendData, tmpXAxisData, tmpSeriesDataObj)
                    // 显示数值
                    for (let i = 0; i < option.series.length; i++) {
                        const element = option.series[i]
                        element.itemStyle = {
                            normal: {
                                label: {
                                    show: true, //开启显示
                                    position: 'top', //在上方显示
                                    rotate: 80, //标签旋转。从-90度到90度。正值是逆时针。
                                    textStyle: {
                                        //数值样式
                                        color: 'black',
                                        fontSize: 12
                                    }
                                }
                            }
                        }
                    }
                    this.chart7.setOption(option)
                }
            })
        },
        // 报案人统计
        getCaseReporter() {
            this.instance.post('/tpa/api/dashboard/case/reporter', this.queryForm).then((res) => {
                let data = res.data
                if (data.code == 200 && data.data && data.data.data.list.length > 0) {
                    let tmpList = data.data.data.list
                    let tmpData = []
                    tmpList.forEach((dataItem) => {
                        tmpData.push({
                            name: dataItem.reporter,
                            value: dataItem.count
                        })
                    })
                    this.chart8 && this.chart8.dispose()
                    this.chart8 = echarts.init(document.getElementById('chart8'))
                    // 饼图
                    let option = this.returnPieOption(tmpData)
                    option.legend.top = '0%'
                    option.series.center = ['50%', '75%']
                    this.chart8.setOption(option)
                }
            })
        },
        // 不同报案人赔付占比(已决)
        getCaseReporterPercent() {
            this.instance.post('/tpa/api/dashboard/case/reporter/percent', this.queryForm).then((res) => {
                let data = res.data
                let tmpLegendData = ['索赔总金额(万元)', '结案总金额(万元)', '索赔总金额[扣除免赔](万元)', '案件结案赔付比例']
                let tmpXAxisData = []
                let tmpSeriesDataObj = {
                    tmpSeriesData1: [],
                    tmpSeriesData2: [],
                    tmpSeriesData3: [],
                    tmpSeriesData4: []
                }
                if (data.code == 200 && data.data && data.data.data.list.length > 0) {
                    let tmpList = data.data.data.list
                    tmpList.forEach((dataItem) => {
                        tmpXAxisData.push(dataItem.reporter)
                        tmpSeriesDataObj.tmpSeriesData1.push(dataItem.caseClaimAmount)
                        tmpSeriesDataObj.tmpSeriesData2.push(dataItem.caseCalculateAmount)
                        tmpSeriesDataObj.tmpSeriesData3.push(dataItem.caseClaimAmountDeductible)
                        if (dataItem.caseClosingPercent.length > 1) {
                            dataItem.caseClosingPercent = dataItem.caseClosingPercent.slice(0, dataItem.caseClosingPercent.length - 1)
                            tmpSeriesDataObj.tmpSeriesData4.push(Number(dataItem.caseClosingPercent))
                        } else {
                            tmpSeriesDataObj.tmpSeriesData4.push(0)
                        }
                    })
                    this.chart9 && this.chart9.dispose()
                    this.chart9 = echarts.init(document.getElementById('chart9'))
                    // 折线图
                    let option = this.returnBarLineOption(tmpLegendData, tmpXAxisData, tmpSeriesDataObj)
                    option.xAxis[0].axisLabel = { interval: 0, rotate: 20 }
                    option.grid = { bottom: '20%' }
                    for (let i = 0; i < option.series.length; i++) {
                        const element = option.series[i]
                        element.itemStyle = {
                            normal: {
                                label: {
                                    show: true, //开启显示
                                    position: 'top', //在上方显示
                                    rotate: 80, //标签旋转。从-90度到90度。正值是逆时针。
                                    textStyle: {
                                        //数值样式
                                        color: 'black',
                                        fontSize: 12
                                    }
                                }
                            }
                        }
                        if (element.type == 'line') {
                            element.label = {
                                normal: {
                                    position: ['90%', '90%'],
                                    formatter: '{c}%',
                                    textStyle: {
                                        // 提示文字的样式
                                        color: '#3e5dd3',
                                        fontSize: 14
                                    }
                                }
                            }
                        }
                    }
                    this.chart9.setOption(option)
                }
            })
        },
        // 不同报案人赔付占比(未决)
        getCaseReporterUndecided() {
            this.instance.post('/tpa/api/dashboard/case/reporter/percent/undecided', this.queryForm).then((res) => {
                let data = res.data
                let tmpLegendData = ['索赔总金额(万元)', '定损总金额(万元)']
                let tmpXAxisData = []
                let tmpSeriesDataObj = {
                    tmpSeriesData1: [],
                    tmpSeriesData2: []
                }
                if (data.code == 200 && data.data && data.data.data.list.length > 0) {
                    let tmpList = data.data.data.list
                    tmpList.forEach((dataItem) => {
                        tmpXAxisData.push(dataItem.reporter)
                        tmpSeriesDataObj.tmpSeriesData1.push(dataItem.caseClaimAmount)
                        tmpSeriesDataObj.tmpSeriesData2.push(dataItem.caseCalculateAmount)
                    })
                    this.chart10 && this.chart10.dispose()
                    this.chart10 = echarts.init(document.getElementById('chart10'))
                    // 折线图
                    let option = this.returnBarOption(tmpLegendData, tmpXAxisData, tmpSeriesDataObj)
                    // x轴文案倾斜
                    option.xAxis[0].axisLabel = { interval: 0, rotate: 60 }
                    // 显示数值
                    for (let i = 0; i < option.series.length; i++) {
                        const element = option.series[i]
                        element.itemStyle = {
                            normal: {
                                label: {
                                    show: true, //开启显示
                                    position: 'top', //在上方显示
                                    rotate: 80, //标签旋转。从-90度到90度。正值是逆时针。
                                    textStyle: {
                                        //数值样式
                                        color: 'black',
                                        fontSize: 12
                                    }
                                }
                            }
                        }
                    }
                    this.chart10.setOption(option)
                }
            })
        },
        // 出险原因分类统计
        getCaseAccidentReason() {
            this.instance.post('/tpa/api/dashboard/case/accident/reason', this.queryForm).then((res) => {
                let data = res.data
                if (data.code == 200 && data.data && data.data.data.list.length > 0) {
                    let tmpList = data.data.data.list
                    let tmpData = []
                    tmpList.forEach((dataItem) => {
                        tmpData.push({
                            name: dataItem.accReason,
                            value: dataItem.count
                        })
                    })
                    this.chart11 && this.chart11.dispose()
                    this.chart11 = echarts.init(document.getElementById('chart11'))
                    // 饼图
                    let option = this.returnPieOption(tmpData)
                    option.series.center = ['50%', '50%']
                    this.chart11.setOption(option)
                }
            })
        },
        // 不同原因赔付比(已决)
        getAccidentReason() {
            this.instance.post('/tpa/api/dashboard/case/accident/reason/percent', this.queryForm).then((res) => {
                let data = res.data
                let tmpLegendData = ['索赔总金额(万元)', '结案总金额(万元)']
                let tmpXAxisData = []
                let tmpSeriesDataObj = {
                    tmpSeriesData1: [],
                    tmpSeriesData2: []
                }
                if (data.code == 200 && data.data && data.data.data.list.length > 0) {
                    let tmpList = data.data.data.list
                    tmpList.forEach((dataItem) => {
                        tmpXAxisData.push(dataItem.accReason)
                        tmpSeriesDataObj.tmpSeriesData1.push(dataItem.caseClaimAmount)
                        tmpSeriesDataObj.tmpSeriesData2.push(dataItem.caseCalculateAmount)
                    })
                    this.chart12 && this.chart12.dispose()
                    this.chart12 = echarts.init(document.getElementById('chart12'))
                    // 柱状图
                    this.chart12.setOption(this.returnBarOption(tmpLegendData, tmpXAxisData, tmpSeriesDataObj))
                }
            })
        },
        // 不同出险原因赔付比(未决)
        getCaseAccidentUndecided() {
            this.instance.post('/tpa/api/dashboard/case/accident/reason/percent/undecided', this.queryForm).then((res) => {
                let data = res.data
                let tmpLegendData = ['索赔总金额(万元)', '结案总金额(万元)']
                let tmpXAxisData = []
                let tmpSeriesDataObj = {
                    tmpSeriesData1: [],
                    tmpSeriesData2: []
                }
                if (data.code == 200 && data.data && data.data.data.list.length > 0) {
                    let tmpList = data.data.data.list
                    tmpList.forEach((dataItem) => {
                        tmpXAxisData.push(dataItem.accReason)
                        tmpSeriesDataObj.tmpSeriesData1.push(dataItem.caseClaimAmount)
                        tmpSeriesDataObj.tmpSeriesData2.push(dataItem.caseCalculateAmount)
                    })
                    this.chart13 && this.chart13.dispose()
                    this.chart13 = echarts.init(document.getElementById('chart13'))
                    // 柱状图
                    this.chart13.setOption(this.returnBarOption(tmpLegendData, tmpXAxisData, tmpSeriesDataObj))
                }
            })
        },
        // 每日案件趋势图
        getCaseDailyTrend() {
            this.instance.post('/tpa/api/dashboard/case/daily/trend', this.queryForm).then((res) => {
                let data = res.data
                let tmpLegendData = ['案件流入量', '结案量']
                let tmpXAxisData = []
                let tmpSeriesDataObj = {
                    tmpSeriesData1: [],
                    tmpSeriesData2: []
                }
                if (data.code == 200 && data.data && data.data.data.list.length > 0) {
                    let tmpList = data.data.data.list
                    tmpList.forEach((dataItem) => {
                        tmpXAxisData.push(dataItem.date)
                        tmpSeriesDataObj.tmpSeriesData1.push(dataItem.caseInCount)
                        tmpSeriesDataObj.tmpSeriesData2.push(dataItem.caseCloseCount)
                    })
                    this.chart14 && this.chart14.dispose()
                    this.chart14 = echarts.init(document.getElementById('chart14'))
                    // 折线图
                    let option = this.returnBarOption(tmpLegendData, tmpXAxisData, tmpSeriesDataObj)
                    // x轴文案倾斜
                    option.xAxis.axisLabel = { interval: 0, rotate: 40 }
                    // 显示数值
                    for (let i = 0; i < option.series.length; i++) {
                        const element = option.series[i]
                        element.itemStyle = {
                            normal: {
                                label: {
                                    show: true, //开启显示
                                    position: 'top', //在上方显示
                                    rotate: 80, //标签旋转。从-90度到90度。正值是逆时针。
                                    textStyle: {
                                        //数值样式
                                        color: 'black',
                                        fontSize: 12
                                    }
                                }
                            }
                        }
                    }
                    this.chart14.setOption(option)
                }
            })
        },
        // 每日案件趋势图-按月叠加
        getDailyThrendStack() {
            this.instance.post('/tpa/api/dashboard/case/daily/trend/stack', this.queryForm).then((res) => {
                let data = res.data
                let tmpLegendData = []
                let tmpXAxisData = []
                let tmpSeriesDataObj = {
                    tmpSeriesData1: [],
                    tmpSeriesData2: [],
                    tmpSeriesData3: [],
                    tmpSeriesData4: [],
                    tmpSeriesData5: [],
                    tmpSeriesData6: [],
                    tmpSeriesData7: []
                }
                if (data.code == 200 && data.data && data.data.columns.length > 0) {
                    let tmpColumns = data.data.columns
                    let tmpList = data.data.data.list

                    tmpColumns.forEach((item) => {
                        if (item.title != '日期') {
                            tmpLegendData.push(item.title)
                        }
                    })

                    tmpList.forEach((dataItem) => {
                        tmpXAxisData.push(dataItem.date)
                        tmpSeriesDataObj.tmpSeriesData1.push(dataItem.sixMonth)
                        tmpSeriesDataObj.tmpSeriesData2.push(dataItem.sevenMonth)
                        tmpSeriesDataObj.tmpSeriesData3.push(dataItem.eightMonth)
                        tmpSeriesDataObj.tmpSeriesData4.push(dataItem.nineMonth)
                        tmpSeriesDataObj.tmpSeriesData5.push(dataItem.tenMonth)
                        tmpSeriesDataObj.tmpSeriesData6.push(dataItem.elevenMonth)
                        tmpSeriesDataObj.tmpSeriesData7.push(dataItem.twelveMonth)
                    })
                    this.chart15 && this.chart15.dispose()
                    this.chart15 = echarts.init(document.getElementById('chart15'))
                    // 折线图
                    let option = this.returnStackingOption(tmpLegendData, tmpXAxisData, tmpSeriesDataObj)
                    // x轴文案倾斜
                    option.xAxis[0].axisLabel = { interval: 0, rotate: 40 }
                    this.chart15.setOption(option)
                }
            })
        },
        // 每日前置案件趋势图
        getDailyThrendPre() {
            this.instance.post('/tpa/api/dashboard/case/daily/trend/pre', this.queryForm).then((res) => {
                let data = res.data
                let tmpLegendData = ['案件流入量', '结案量']
                let tmpXAxisData = []
                let tmpSeriesDataObj = {
                    tmpSeriesData1: [],
                    tmpSeriesData2: []
                }
                if (data.code == 200 && data.data && data.data.data.list.length > 0) {
                    let tmpList = data.data.data.list
                    tmpList.forEach((dataItem) => {
                        tmpXAxisData.push(dataItem.date)
                        tmpSeriesDataObj.tmpSeriesData1.push(dataItem.preCaseInCount)
                        tmpSeriesDataObj.tmpSeriesData2.push(dataItem.preCaseCount)
                    })
                    this.chart16 && this.chart16.dispose()
                    this.chart16 = echarts.init(document.getElementById('chart16'))
                    // 柱状图
                    this.chart16.setOption(this.returnBarOption(tmpLegendData, tmpXAxisData, tmpSeriesDataObj))
                }
            })
        },
        // 人员维度作业案件数
        getCaseAccidentPerson() {
            this.instance.post('/tpa/api/dashboard/case/accident/person', this.queryForm).then((res) => {
                let data = res.data
                let tmpLegendData = ['']
                let tmpXAxisData = []
                let tmpSeriesDataObj = {
                    tmpSeriesData1: []
                }
                if (data.code == 200 && data.data && data.data.data.list.length > 0) {
                    let tmpList = data.data.data.list
                    tmpList.forEach((dataItem) => {
                        tmpXAxisData.push(dataItem.handler)
                        tmpSeriesDataObj.tmpSeriesData1.push(dataItem.caseCount)
                    })
                    this.chart17 && this.chart17.dispose()
                    this.chart17 = echarts.init(document.getElementById('chart17'))
                    // 柱状图
                    let option = this.returnBarOption(tmpLegendData, tmpXAxisData, tmpSeriesDataObj)
                    option.xAxis[0].axisLabel = { interval: 0, rotate: 30 }
                    this.chart17.setOption(option)
                }
            })
        },
        downloadFile(status) {
            let downloadUrl = status == 1 ? '/tpa/api/dashboard/case/claim/simple/trend/excel' : ''
            let downloadFileName = status == 1 ? '整体理赔数据分析' : ''
            this.queryForm.orders = this.tableDataOrders
            this.instance.post(downloadUrl, this.queryForm, { responseType: 'blob' }).then((res) => {
                if (res.status == 200) {
                    let url = window.URL.createObjectURL(new Blob([res.data]))
                    let link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = url
                    link.setAttribute('download', downloadFileName + '.xlsx')
                    document.body.appendChild(link)
                    link.click()
                }
            })
        },
        formatMoney(amount) {
            // 将金额转换为字符串，并去除可能存在的非数字字符
            amount = parseFloat(amount).toFixed(2).toString()

            // 拆分整数部分和小数部分
            const parts = amount.split('.')
            let integerPart = parts[0]
            const decimalPart = parts[1]

            // 添加千位分隔符
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

            // 重新组合整数和小数部分
            return integerPart + '.' + decimalPart
        },
        expireTimeOptionFun() {
            let selectData = []
            return {
                shortcuts: [
                    // {
                    //     text: '最近一周',
                    //     onClick(picker) {
                    //         const end = new Date()
                    //         const start = new Date()
                    //         start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                    //         picker.$emit('pick', [start, end])
                    //     }
                    // },
                    {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date()
                            const start = new Date()
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                            picker.$emit('pick', [start, end])
                        }
                    }
                ],
                onPick: ({ maxDate, minDate }) => {
                    selectData[0] = minDate?.getTime()
                    selectData[1] = maxDate?.getTime()
                }
                // disabledDate(time) {
                //     //选择时间
                //     if (selectData[0] && !selectData[1]) {
                //         return time.getTime() > selectData[0] + 24 * 60 * 60 * 30 * 1000 || time.getTime() < selectData[0] - 24 * 60 * 60 * 30 * 1000
                //     }
                // }
            }
        },
        getDate() {
            var date = new Date() // 当前日期时间
            var year = date.getFullYear() // 年份
            var month = ('0' + (date.getMonth() + 1)).slice(-2) // 月份+1
            var day = ('0' + date.getDate()).slice(-2) // 日
            var hour = ('0' + date.getHours()).slice(-2) // 小时
            var minute = ('0' + date.getMinutes()).slice(-2) // 分钟
            var second = ('0' + date.getSeconds()).slice(-2) // 秒数
            var millisecond = ('000' + date.getMilliseconds()).slice(-3) // 毫秒数0-999

            return String(year + '-' + month + '-' + day)
        },
        // 获取日期
        getNowTime(temp) {
            var now = new Date(temp)
            var year = now.getFullYear() //得到年份
            var month = now.getMonth() + 1 //得到月份
            var date = now.getDate() //得到日期
            month = month.toString().padStart(2, '0')
            date = date.toString().padStart(2, '0')
            return `${year}-${month}-${date}`
        },
        // 饼图-option
        returnPieOption(seriesData) {
            return {
                title: {
                    left: 'center',
                    top: '0',
                    textStyle: {
                        fontSize: 14
                    }
                },
                tooltip: {
                    trigger: 'item',
                    formatter: `{b} : {c}<br/>占比： {d}%`
                },
                legend: {
                    top: '5%',
                    left: 'center'
                },
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: '50%',
                        radius: ['40%', '70%'],
                        center: ['50%', '60%'],
                        data: seriesData,
                        label: {
                            normal: {
                                formatter: '{b}  {d}%',
                                textStyle: {
                                    // 提示文字的样式
                                    color: '#595959',
                                    fontSize: 14
                                }
                            }
                        },
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            }
        },
        // 折线图-option
        returnLineOption(legendData, xAxisData, seriesDataObj) {
            let optionSeriesArr = []

            legendData.forEach((item, itemIndex) => {
                optionSeriesArr.push({
                    name: legendData[itemIndex],
                    type: 'line',
                    stack: '显示数值' + itemIndex,
                    data: seriesDataObj['tmpSeriesData' + (itemIndex + 1)]
                })
            })

            return {
                tooltip: {
                    trigger: 'axis',
                    formatter: '{c}%'
                },
                legend: {
                    data: legendData
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xAxisData
                },
                yAxis: {
                    type: 'value'
                },
                series: optionSeriesArr
            }
        },
        // 柱状图-option
        returnBarOption(legendData, xAxisData, seriesDataObj) {
            let optionSeriesArr = []

            legendData.forEach((item, itemIndex) => {
                optionSeriesArr.push({
                    name: legendData[itemIndex],
                    type: 'bar',
                    tooltip: {
                        valueFormatter: function (value) {
                            return value
                        }
                    },
                    //显示数值
                    itemStyle: {
                        normal: {
                            label: {
                                show: true, //开启显示
                                position: 'top', //在上方显示
                                textStyle: {
                                    //数值样式
                                    color: 'black',
                                    fontSize: 12
                                }
                            }
                        }
                    },
                    data: seriesDataObj['tmpSeriesData' + (itemIndex + 1)]
                })
            })

            return {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    data: legendData
                },
                xAxis: [
                    {
                        type: 'category',
                        data: xAxisData
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                        // splitNumber: 10
                    }
                ],
                series: optionSeriesArr
            }
        },
        // 堆叠面积图-option
        returnStackingOption(legendData, xAxisData, seriesDataObj) {
            let optionSeriesArr = []

            legendData.forEach((item, itemIndex) => {
                optionSeriesArr.push({
                    name: legendData[itemIndex],
                    type: 'line',
                    stack: 'Total',
                    label: {
                        show: true,
                        position: 'top'
                    },
                    areaStyle: {},
                    emphasis: {
                        focus: 'series'
                    },
                    data: seriesDataObj['tmpSeriesData' + (itemIndex + 1)]
                })
            })

            return {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            backgroundColor: '#6a7985'
                        }
                    }
                },
                legend: {
                    data: legendData
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        boundaryGap: false,
                        data: xAxisData
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: optionSeriesArr
            }
        },
        // 折柱混合-option
        returnBarLineOption(legendData, xAxisData, seriesDataObj) {
            let optionSeriesArr = []

            legendData.forEach((item, itemIndex) => {
                optionSeriesArr.push({
                    name: legendData[itemIndex],
                    type: legendData[itemIndex] == '案件结案赔付比例' ? 'line' : 'bar',
                    yAxisIndex: legendData[itemIndex] == '案件结案赔付比例' ? 1 : 0,
                    data: seriesDataObj['tmpSeriesData' + (itemIndex + 1)]
                })
            })

            return {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999'
                        }
                    }
                },
                legend: {
                    data: legendData
                },
                xAxis: [
                    {
                        type: 'category',
                        data: xAxisData,
                        axisPointer: {
                            type: 'shadow'
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}'
                        }
                    },
                    {
                        type: 'value',
                        axisLabel: {
                            formatter: '{value}%'
                        }
                    }
                ],
                series: optionSeriesArr
            }
        },
        getDate() {
            var date = new Date() // 当前日期时间
            var year = date.getFullYear() // 年份
            var month = ('0' + (date.getMonth() + 1)).slice(-2) // 月份+1
            var day = ('0' + date.getDate()).slice(-2) // 日
            var hour = ('0' + date.getHours()).slice(-2) // 小时
            var minute = ('0' + date.getMinutes()).slice(-2) // 分钟
            var second = ('0' + date.getSeconds()).slice(-2) // 秒数
            var millisecond = ('000' + date.getMilliseconds()).slice(-3) // 毫秒数0-999

            return String(year + '-' + month + '-' + day)
        }
    }
}
