
import ExpressionBuilder from '@/components/ExpressionBuilder';
export default {
    name: 'RiskDetail',
    components: {
        ExpressionBuilder
    },
    props: {
        dialogVisibleRisk: Boolean,
        activeName: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            scene: null,
            loading: false,
            dialogVisible: false,
            innerVisible: false,
            navForm: {
                riskCode: '',
                riskName: '',
                riskLevel: '',
                riskShowType: '',
                riskCategory: '',
                riskStep: '',
                createBy: '',
                startCreateTime: '',
                endCreateTime: ''
            },
            riskType: '',
            riskForm: {
                riskName: '',
                riskCategory: '',
                riskStep: '',
                riskLevel: '',
                riskShowType: '',
                riskDesc: ''
            },
            testForm: { case: '' },
            caseImport: false,
            caseEndStepOption: [],
            currentPage: 1, //当前页
            pageSize: 10, //一页多少条数据
            totalPage: 1, //总页数tableData:[],
            tableData: [],
            features: [],
            query: {
                type: '',
                typeName: '',
                symbol: '',
                children: [
                    {
                        field: '',
                        fieldName: '',
                        operator: '',
                        operatorDesc: '',
                        symbol: '',
                        term: '',
                        termName: '',
                        constant: '',
                        children: {
                            operator: '',
                            operatorDesc: '',
                            symbol: '',
                            term: '',
                            termName: '',
                            constant: ''
                        }
                    }
                ]
            },
            riskFormRules: {
                riskName: [{ required: true, message: '请输入风险点名称', trigger: 'blur' }],
                riskCategory: [{ required: true, message: '请选择展示分类', trigger: 'change' }],
                riskStep: [{ required: true, message: '请选择风险步骤', trigger: 'change' }],
                riskLevel: [{ required: true, message: '请选择风险等级', trigger: 'change' }],
                riskShowType: [{ required: true, message: '请选择风险等级', trigger: 'change' }]
            },
            expressPassStatus: false,
            formulaShow: ''
        };
    },
    methods: {
        expressPass(status) {
            this.expressPassStatus = status;
        },
        caseChange(val) {
            this.caseImport = val ? true : false;
        },
        // 获取特征
        getFeatureList() {
            this.instance.post('/risk/api/feature/definition/paging', { pageNo: 1, pageSize: 100 }).then(({ data }) => {
                const list = data.data.list;
                const columns = [];
                const values = list.map((item) => item.featureName);
                for (var i = 0; i < list.length; i++) {
                    const feature = list[i];
                    columns.push({
                        field: feature.featureCode,
                        label: feature.featureName,
                        type: this.getDataType(feature.dataType)
                    });
                }
                this.features = columns;
            });
        },
        //操作测试
        handleTest(row) {
            row.btnLoading = true;
            this.innerVisible = true;
            this.definition(row.riskCode, row);
        },
        // 规则测试
        definition(riskCode, row) {
            this.instance.post(`/risk/api/risk/rule/definition/${riskCode}`).then((res) => {
                row.btnLoading = false;
                let data = res.data;
                if (data.code == 200) {
                    this.formulaShow = data.data.formulaShow;
                }
            });
        },
        getDataType(code) {
            switch (code) {
                case 20:
                case 30:
                case 40:
                case 50:
                    return 'number';
                case 70:
                    return 'boolean';
                default:
                    return 'string';
            }
        },
        beforeClose(done) {
            this.currentPage = 1;
            this.$emit('beforeClose');
        },
        //点击添加或者修改按钮
        handleRisk(row) {
            this.$refs.riskForm && this.$refs.riskForm.resetFields();
            this.query = this.$options.data().query;
            // this.endCreateTime
            this.dialogVisible = true;
        },
        //点击提交
        handleAddRiskPiont(elementRefs) {
            // 添加风险点
            let json = JSON.parse(JSON.stringify(this.riskForm));
            json.scene = this.scene;
            json.riskType = this.riskType;

            // 规则组校验
            // this.$refs['expression1'].verificationRules()

            // 校验通过提交数据
            this.$refs[elementRefs].validate((valid) => {
                // if (valid && this.expressPassStatus) {
                if (valid) {
                    this.definitionAdd(json);
                }
            });
        },
        definitionAdd(jsonData) {
            this.instance.post('/risk/api/risk/definition/add', jsonData).then((res) => {
                if (res.data.code === 200) {
                    this.dialogVisible = false;
                    this.submitRule(res.data.data);
                } else {
                    this.$message.error('风险点' + this.riskForm.riskName + '添加失败, 原因:' + res.data.message);
                }
            });
        },
        // 规则配置
        submitRule(response) {
            let json = {
                output: {
                    failFeatureValue: ''
                }
            };
            // json.scene=this.scene;
            json.formula = this.queryToSql(this.query);
            json.formulaShow = this.queryToSqlShow(this.query);
            json.riskCode = response.riskCode;
            json.output.featureCode = response.riskFeatureRel;
            json.output.featureValue = response.riskName;
            // console.log(json)
            let sign = this.riskForm.code ? 'edit' : 'config';
            this.instance.post('/risk/api/rule/definition/' + sign, json).then((res) => {
                if (res.data.code == 200) {
                    this.$message.success('添加成功！');
                    this.dialogVisible = false;
                    this.getTbodyData();
                    this.$emit('getStatistics');
                }
            });
        },
        getTbodyData(riskType) {
            riskType && (this.riskType = riskType);
            this.scene = this.activeName == '全部' ? null : this.activeName;
            this.loading = true;
            this.tableData = []; //  清空table的值
            var condition = JSON.parse(JSON.stringify(this.navForm));
            condition.scene = this.scene;
            condition.riskType = this.riskType;
            let json = {
                condition: condition,
                pageNo: this.currentPage,
                pageSize: this.pageSize
            };
            this.instance.post('/risk/api/risk/definition/paging', json).then((res) => {
                this.loading = false;
                let data = res.data;
                if (data.code == 200) {
                    res.data.data.list.length > 0 &&
                        res.data.data.list.forEach((item) => {
                            item.btnLoading = false;
                        });
                    this.tableData = res.data.data.list;
                    this.totalPage = parseInt(res.data.data.total);
                }
            });
        },
        //点击查询
        searchTable() {
            this.currentPage = 1;
            this.getTbodyData();
        },
        //重置筛选项
        resetForm() {
            this.$refs.navForm.resetFields();
            this.navForm.endCreateTime = '';
        },
        onStatusChange: function (row) {
            this.$data.loading = true;
            this.instance
                .post('/risk/api/risk/definition/edit', { riskCode: row.riskCode, riskEnabled: row.riskEnabled })
                .then(({ data }) => {
                    this.$data.loading = false;
                    const operate = row.riskEnabled ? '启用' : '停用';
                    if (data.code == 200) {
                        this.$message({ message: '风险点' + row.riskName + '已' + operate, type: 'success' });
                    } else {
                        this.$message.error('风险点' + row.riskName + operate + '失败！');
                    }
                })
                .catch((_) => (this.$data.loading = false));
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val;
            this.getTbodyData();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange(val) {
            this.currentPage = Number(val);
            this.getTbodyData();
        }
    },
    mounted() {
        //
        // this.getTbodyData();
        this.getFeatureList();
    }
};
