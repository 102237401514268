
// @ is an alias to /src
import DeptAdminHl from '@/package/deptAdminHl/index.vue'
export default {
    name: 'deptAdmin',
    components: {
        DeptAdminHl
    },
    data() {
        return {
            loading: false,
            menuType: 'searchReviewPage'
        }
    },
    methods: {},
    mounted() {
        //
    }
}
