
import ImgDeal from '@/components/ImgDeal.vue';

let tmpEndTime = new Date().toLocaleDateString().replace(/\//g, '-') + ' 23:59:59';
tmpEndTime = JSON.parse(JSON.stringify(tmpEndTime));
export default {
    components: {
        ImgDeal
    },
    data() {
        return {
            dialogBtnClickFlag: false,
            loadingNextFlag: false,
            loading: false,
            medicineOutboundOcrProup: false,
            ocrRecognitionLoading: false,
            ocrTableSaveFlag: false,
            isEditInfo: true,
            OSSClient: null,
            imgStatus: 0,
            navForm: {
                condition: {
                    caseNo: '',
                    endOperateTime: '',
                    operatorIds: [],
                    startOperateTime: '',
                    status: []
                },
                pageNo: 1,
                pageSize: 10,
                pageTotal: 0
            },
            qualityForm: {
                caseNo: '',
                qualityRiskLevel: '',
                conclusion: '',
                emphasisCase: true,
                qualityRemark: ''
            },
            currentPage: 1,
            pageSize: 10,
            totalPage: 1,
            userTableData: [],
            tableData: [],
            tableHeight: null,
            imgGroupList: [],
            imgShowData: {},
            imgUrlList: [],
            initialIndex: 0,
            currentActiveMaterial: {},
            ocrResult: {},
            // 已经识别过的出库单列表
            alreadyOcrList: [],
            materialsOcrOpStatus: [],
            medicinePropertyOptions: [],
            medicineTypeOptions: []
        };
    },
    mounted() {
        // 动态计算table高度
        this.calcHeight();
        window.onresize = () => {
            this.calcHeight();
        };
        this.getTableData();
        this.getUserList();
        this.getCaseMaterialsOcrOpStatusEnum();
        // 获取药品性质
        this.getMedicineProperty();
        // 获取药品分类
        this.getMedicineType();
    },
    methods: {
        calcHeight() {
            let registerTabsHeight = document.querySelector('.register_tabs') ? document.querySelector('.register_tabs').clientHeight : 0;
            let publicBoxHeight = document.querySelector('.public_box') ? document.querySelector('.public_box').clientHeight : 0;
            let elPaginationHeight = document.querySelector('.el-pagination') ? document.querySelector('.el-pagination').clientHeight : 0;
            let tableHeaderHeight = 51;
            let guHeight = null;

            guHeight = 20 + 20 + 20 + 30 + 15;
            this.tableHeight = window.innerHeight - registerTabsHeight - publicBoxHeight - elPaginationHeight - tableHeaderHeight - guHeight;
        },
        getMedicineProperty() {
            this.medicinePropertyOptions = [];
            this.instance.get('/tpa/api/enum/getMedicinePropertyEnum').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.medicinePropertyOptions = data.data;
                }
            });
        },
        getMedicineType() {
            this.medicineTypeOptions = [];
            this.instance.get('/tpa/api/enum/getMedicineTypeEnum').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.medicineTypeOptions = data.data;
                }
            });
        },
        getTotay() {
            var time = new Date();
            var year = time.getFullYear();
            var month = time.getMonth() + 1;
            var date = time.getDate();
            return year + '-' + this.add0(month) + '-' + this.add0(date);
        },
        //修改月、天的格式，保持两位数显示
        add0(m) {
            return m < 10 ? '0' + m : m;
        },
        timeChange(val, valStr) {
            // 2023-08-21 00:00:00
            let tmpDate = val && val.slice(0, 11);
            let tmpTime = val && val.slice(11, 19);
            if (tmpTime == '00:00:00') {
                tmpDate += '23:59:59';
                this.navForm[valStr] = tmpDate;
            }
        },
        getUserList() {
            let jsonData = { schedulingType: 2 };
            this.instance.post('/tpa/api/case/task/searchUser', jsonData).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.userTableData = data.data;
                }
            });
        },
        getCaseMaterialsOcrOpStatusEnum() {
            this.instance.get('/tpa/api/enum/getCaseMaterialsOcrOpStatusEnum', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.materialsOcrOpStatus = data.data;
                }
            });
        },
        handlerData(rowItem, rowIndex, flag) {
            if (flag) {
                let tmpItem = JSON.parse(JSON.stringify(rowItem));
                this.ocrResult.detailList.splice(rowIndex, 0, tmpItem);
            } else {
                this.ocrResult.detailList.splice(rowIndex, 1);
            }
        },
        addTableData() {
            if (!this.ocrResult.detailList) {
                this.ocrResult = {
                    detailList: []
                };
            }

            let itemData = {
                medicineName: '',
                specification: '',
                dosageForm: '',
                manufacturer: '',
                manufacturer: '',
                approvalNumber: '',
                price: '',
                medicineProperty: '',
                medicineType: ''
            };
            this.ocrResult.detailList.push(itemData);
        },
        startCheck(rowItem, status) {
            this.imgStatus = status;
            if (!rowItem.signHash) {
                this.$message.error('当前材料没有 “hash” 值！');
                return;
            }
            this.imgGroupList = [];
            this.instance.post('/tpa/api/medicine/outbound/ocr/startCheck/' + rowItem.signHash, {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.imgShowData = rowItem;
                    this.medicineOutboundOcrProup = true;
                    this.getMaterialOcrResult(rowItem);
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        getMaterialOcrResult(rowItem) {
            this.instance.get('/tpa/api/medicine/outbound/ocr/hash/' + rowItem.signHash, { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.currentActiveMaterial.originResult = data.data || null;
                    this.ocrResult = data.data || {};
                    this.$forceUpdate();
                }
            });
        },
        revise(rowItem, status) {
            this.imgStatus = status;
            this.instance.post('/tpa/api/medicine/outbound/ocr/revise/' + rowItem.signHash, {}).then((res) => {
                let data = res.data;
                if (data.code == 200 && data.data) {
                    // this.getReviseData(rowItem);
                    this.imgShowData = rowItem;
                    this.ocrResult = data.data;
                    this.medicineOutboundOcrProup = true;
                } else if (!data.data) {
                    this.$message.error('接口暂无数据,请稍后!');
                } else if (data.code != 200) {
                    this.$message.error(data.message);
                }
            });
        },
        getReviseData(rowItem) {
            this.instance.get('/tpa/api/medicine/outbound/ocr/calibrated/' + rowItem.id, { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.imgShowData = rowItem;
                    this.ocrResult = data.data;
                    this.medicineOutboundOcrProup = true;
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        getTableData(queryFlag) {
            if (queryFlag) {
                this.navForm.pageNo = 1;
            }
            this.loading = true;
            this.tableData = []; //  清空table的值
            this.instance.post('/tpa/api/case/materials/paging', this.navForm).then((res) => {
                this.loading = false;
                let data = res.data;
                if (data.code == 200) {
                    this.tableData = data.data.list;
                    this.navForm.pageTotal = data.data.total;
                }
            });
        },
        getRiskInfo(row, rowIndex) {
            this.instance.get('/tpa/api/case/risk/getRiskInfo?caseNo=' + row.caseNo).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    row.riskLists = data.data;
                    let tmpRow = JSON.parse(JSON.stringify(row));
                    this.$set(this.tableData, rowIndex, tmpRow);
                }
            });
        },
        //点击查询
        searchTable(resetFlag) {
            if (resetFlag) {
                this.$refs.navForm.resetFields();
                this.navForm = {
                    condition: {
                        caseNo: '',
                        endOperateTime: '',
                        operatorIds: [],
                        startOperateTime: '',
                        status: []
                    },
                    pageNo: 1,
                    pageSize: 10,
                    pageTotal: 0
                };
            } else {
                this.getTableData(true);
            }
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.navForm.pageSize = val;
            this.getTableData();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange(val) {
            this.navForm.pageNo = val;
            this.getTableData();
        },
        // ocr 识别
        ocrRecognitionSubmit() {
            this.forceOcrRecognitionSubmit();
            return;
            if (this.currentActiveMaterial.originResult) {
                this.ocrResult = this.originOcrResult.find((item1) => item1.materialHash === this.currentActiveMaterial.materialHash);
                this.currentActiveMaterial.originResult = this.ocrResult;
            } else {
                this.forceOcrRecognitionSubmit();
            }

            this.alreadyOcrList.push(this.currentActiveMaterial);
            // 对 alreadyOcrList 去重
            let uniqueArray = this.alreadyOcrList.reduce((acc, cur) => {
                if (!acc[cur.id]) {
                    acc[cur.id] = cur;
                }
                return acc;
            }, {});
            this.alreadyOcrList = Object.values(uniqueArray);
        },
        // 非出库单
        notOutbound() {
            this.instance.post('/tpa/api/case/materials/mark/not-outbound/' + this.imgShowData.signHash, {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.$message.success('操作成功');
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        // 强制ocr 识别
        forceOcrRecognitionSubmit() {
            this.ocrRecognitionLoading = true;

            // 材料数据
            this.currentActiveId = this.imgShowData.id;
            this.currentActiveMaterial = this.imgShowData;

            this.instance.post('/tpa/api/medicine/outbound/ocr/force/' + this.currentActiveId, {}).then((res) => {
                this.ocrRecognitionLoading = false;
                let data = res.data;
                if (data.code === 200 && data.data) {
                    this.dialogBtnClickFlag = true;
                    this.ocrResult = data.data;
                } else {
                    this.$message.error('识别无结果');
                }
            });

            this.alreadyOcrList.push(this.currentActiveMaterial);
            // 对 alreadyOcrList 去重
            let uniqueArray = this.alreadyOcrList.reduce((acc, cur) => {
                if (!acc[cur.id]) {
                    acc[cur.id] = cur;
                }
                return acc;
            }, {});
            this.alreadyOcrList = Object.values(uniqueArray);
        },
        updateOcrResult(row) {},
        // 退出ocr识别
        nextOcr() {
            let tmpTableData = [];
            let queryData = {
                condition: {
                    status: this.imgStatus ? [30, 40] : [10]
                },
                pageNo: 1,
                pageSize: 10
            };
            this.loadingNextFlag = true;
            this.instance.post('/tpa/api/case/materials/paging', queryData).then((res) => {
                this.loadingNextFlag = false;
                let data = res.data;
                if (data.code == 200) {
                    data.data.list.forEach((item) => {
                        if (item.signHash) {
                            tmpTableData.push(item);
                        }
                    });
                    if (tmpTableData.length > 0) {
                        if (this.imgStatus) {
                            this.revise(tmpTableData[0], 1);
                        } else {
                            this.startCheck(tmpTableData[0], 0);
                        }
                    } else {
                        this.$message.error('暂未查询到下一个材料！');
                    }
                } else {
                    this.$message.error(data.data.message);
                }
            });
        },
        openCase(caseNo) {
            const routeUrl = this.$router.resolve({
                path: '/caseInquiry/index',
                query: { caseNo: caseNo, query: 'query' }
            });
            window.open(routeUrl.href, '_blank');
        },
        closePopup() {
            // if (this.dialogBtnClickFlag) {
            //     this.getTableData();
            //     this.dialogBtnClickFlag = false;
            // }
            this.getTableData();
            this.imgShowData = {};
            this.ocrResult = {};
            this.medicineOutboundOcrProup = false;
        },
        // 保存药品到药品库中
        ocrRecognitionSave() {
            if (!this.ocrResult.detailList) {
                this.$message.error('请先进行OCR识别！');
                return;
            }

            if (!this.ocrResult.materialOssPath) {
                this.completionOcrData();
            }
            let checkNameFlag = false;
            let checkNumberFlag = false;
            let checkManufacturerFlag = false;
            this.ocrResult.caseNo = this.imgShowData.caseNo;
            this.ocrResult.materialHash = this.imgShowData.signHash;
            this.ocrResult.detailList.forEach((item, index) => {
                item.materialId = this.imgShowData.id;
                item.nameError = !item.medicineName;
                item.numberError = !Boolean(item.approvalNumber);
                item.checkManufacturerError = !item.manufacturer;

                // 商品名校验
                if (item.nameError) {
                    checkNameFlag = true;
                    let tmpItem = JSON.parse(JSON.stringify(item));
                    this.$set(this.ocrResult.detailList, index, tmpItem);
                }

                //生产厂家校验
                if (item.checkManufacturerError) {
                    checkManufacturerFlag = true;
                    let tmpItem = JSON.parse(JSON.stringify(item));
                    this.$set(this.ocrResult.detailList, index, tmpItem);
                }

                // 批准文号校验
                if (item.numberError) {
                    checkNumberFlag = true;
                    let tmpItem = JSON.parse(JSON.stringify(item));
                    this.$set(this.ocrResult.detailList, index, tmpItem);
                }
            });

            if (checkNameFlag) {
                this.$message.error('商品名，为必填项！');
                return;
            }
            if (checkManufacturerFlag) {
                this.$message.error('生产厂家，为必填项！');
                return;
            }
            if (checkNumberFlag) {
                this.$message.error('国药准字/批准文号，为必填项！');
                return;
            }

            this.ocrTableSaveFlag = true;
            this.instance.post('/tpa/api/medicine/outbound/ocr/calibrated/save', [this.ocrResult]).then((res) => {
                let data = res.data;
                this.ocrTableSaveFlag = false;
                if (data.code === 200) {
                    this.dialogBtnClickFlag = true;
                    this.$message.success('保存成功');
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        completionOcrData() {
            this.ocrResult.caseNo = this.imgShowData.caseNo;
            this.ocrResult.currentPageTotal = null;
            this.ocrResult.discountAmount = null;
            this.ocrResult.materialHash = this.imgShowData.signHash;
            this.ocrResult.materialId = this.imgShowData.id;
            this.ocrResult.materialOssPath = this.imgShowData.materialPath;
            this.ocrResult.totalReceivable = null;
            this.ocrResult.totalReceived = null;
        }
    }
};
