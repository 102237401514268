
// @ is an alias to /src
import * as Options from './options';
import { createElement, getComponent } from '@syncfusion/ej2-base';
import { DropDownList } from '@syncfusion/ej2-dropdowns';
let inOperators = ['in', 'notin'];

export default {
    name: 'RuleAdmin',
    components: {},
    data() {
        return {
            loading: false,
            ruleDataFrame: {
                data: [],
                total: 0
            },
            ruleDetail: {},
            features: [],
            ...Options,
            ruleEditor: {
                visible: false,
                title: '新增规则'
            },
            dataSource: Options.employeeData,
            values: ['Mr.', 'Mrs.'],
            showButtons: { ruleDelete: true, groupInsert: true, groupDelete: true },
            paymentTemplate: {
                create: (args) => {
                    console.log(args);
                    return createElement('input', { attrs: { type: 'text' } });
                },
                destroy: (args) => {
                    let multiselect = getComponent(document.getElementById(args.elementId), 'multiselect');
                    if (multiselect) multiselect.destroy();
                    let dropdownlist = getComponent(document.getElementById(args.elementId), 'dropdownlist');
                    if (dropdownlist) dropdownlist.destroy();
                },
                write: (args) => {
                    if (inOperators.indexOf(args.operator) > -1) {
                        let multiSelectObj = new MultiSelect({
                            dataSource: ds,
                            value: args.values,
                            mode: 'CheckBox',
                            placeholder: 'Select Transaction',
                            change: (e) => {
                                this.$refs.exprEditor.$el.ej2_instances[0].notifyChange(e.value, e.element);
                            }
                        });
                        multiSelectObj.appendTo('#' + args.elements.id);
                    } else {
                        let dropDownObj = new DropDownList({
                            dataSource: ds,
                            value: args.values,
                            change: (e) => {
                                this.$refs.exprEditor.$el.ej2_instances[0].notifyChange(e.itemData.value, e.element);
                            }
                        });
                        dropDownObj.appendTo('#' + args.elements.id);
                    }
                }
            }
        };
    },
    methods: {
        onStatusChange: function (row) {
            this.$data.loading = true;
            this.instance
                .post('/risk/api/rule/definition/edit', { code: row.ruleCode, status: row.status })
                .then(({ data }) => {
                    this.$data.loading = false;
                    const operate = row.status == 0 ? '启用' : '停用';
                    if (data.code == 200) {
                        this.$message({ message: '规则' + row.ruleName + '已' + operate, type: 'success' });
                    } else {
                        this.$message.error('规则' + row.ruleName + operate + '失败！');
                    }
                })
                .catch((_) => (this.$data.loading = false));
        },
        editRules: function (ruleCode) {
            this.$data.ruleEditor.visible = true;
            this.$data.ruleDetail = {};
            this.$refs.exprEditor && this.$refs.exprEditor.ej2Instances.reset();
            if (ruleCode) {
                this.$data.loading = true;
                this.$data.ruleEditor.title = '编辑规则';
                this.instance
                    .post('/risk/api/rule/definition/' + ruleCode)
                    .then(({ data }) => {
                        this.$data.loading = false;
                        const ruleDetail = data.data;
                        this.$data.ruleDetail = {
                            code: ruleDetail.code,
                            desc: ruleDetail.desc,
                            name: ruleDetail.name,
                            scene: ruleDetail.scene
                        };
                        console.log(ruleDetail);
                        this.$refs.exprEditor.ej2Instances.setRulesFromSql(ruleDetail.formula);
                    })
                    .catch((error) => {
                        this.$data.loading = false;
                        this.$message.error('加载规则详情失败，网络错误！');
                    });
            } else {
                this.$data.ruleEditor.title = '新增规则';
                this.$data.ruleDetail = {};
                this.$refs.exprEditor.ej2Instances.reset();
                this.$refs.exprEditor.ej2Instances.setRules({ condition: 'and', rules: [{}] });
            }
        },
        ruleUpload: function () {
            this.$data.loading = true;
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    const rule = {
                        ...this.$data.ruleDetail,
                        output: {
                            failFeatureValue: 'test1',
                            featureCode: 'FEATURE1626891799006163044',
                            featureValue: 'test'
                        }
                    };
                    const sqlExpr = this.$refs.exprEditor.ej2Instances.getSqlFromRules();
                    rule.formula = sqlExpr;
                    console.log(sqlExpr);
                    let sign = this.ruleDetail.code ? 'edit' : 'config';
                    this.instance
                        .post('/risk/api/rule/definition/' + sign, rule)
                        .then(({ data }) => {
                            this.$data.loading = false;
                            if (data.code == 200) {
                                this.$message({ message: '规则配置成功', type: 'success' });
                                this.ruleEditor.visible = false;
                                this.fetchRuleDataFrame(1);
                            } else {
                                this.$message({ message: data.message, type: 'error' });
                            }
                        })
                        .catch((err) => {
                            this.$data.loading = false;
                            this.$message({ message: '保存失败，网络错误', type: 'error' });
                        });
                } else {
                    this.$message({ message: '规则配置错误，无法提交，请重新填写！', type: 'error' });
                }
            });
        },

        handleClose: function () {
            this.ruleEditor.visible = false;
        },
        handleRuleChange: function () {
            console.log(this.$refs.exprEditor.ej2Instances.getSqlFromRules());
        },
        fetchRuleDataFrame: function (currentPage) {
            this.$data.loading = true;
            this.instance.post('/risk/api/rule/definition/paging', { pageNo: currentPage, pageSize: 10 }).then(({ data }) => {
                this.$data.loading = false;
                console.log(data);
                this.$data.ruleDataFrame = {
                    data: data.data.list,
                    total: parseInt(data.data.total),
                    current: parseInt(data.data.current)
                };
            });
        }
    },
    mounted() {
        //
        this.baseURL = this.$store.getters.baseURL;
        this.fetchRuleDataFrame(1);
        this.instance.post('/risk/api/feature/definition/paging', { pageNo: 1, pageSize: 100 }).then(({ data }) => {
            const list = data.data.list;
            const columns = [];
            const values = list.map((item) => item.featureName);
            for (var i = 0; i < list.length; i++) {
                const feature = list[i];
                columns.push({
                    field: feature.featureCode,
                    label: feature.featureName,
                    type: Options.getDataType(feature.dataType)
                    //template: this.paymentTemplate,
                    //values: values.filter(v => v != feature.featureName)
                });
            }
            this.$data.features = columns;
            //this.$refs.querybuilder.ej2Instances.setRules(this.$data.importRules);
        });
    }
};
