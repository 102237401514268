
import OSS from 'ali-oss';
import { getPie3D, getParametricEquation } from './chart.js';
import * as echarts from 'echarts';
import 'echarts-gl'; // 3d图表库
const color = ['#4F0DF5', '#00B6D6', '#FFDD5F', '#05AB5A'];

export default {
    data() {
        return {
            resetOssNum: 0,
            largeStyle: false,
            downloadFlag: false,
            titleTime: null,
            largeChartRisk1Loading: false,
            largeChartRisk3Loading: false,
            largeChart5Loading: false,
            largeChart6Loading: false,
            largeChart7Loading: false,
            largeChart8Loading: false,
            largeChart9Loading: false,
            largeChart6: null,
            // start
            statusChart: null,
            option: {},
            // end
            value1: '',
            navForm: {
                startTime: '',
                endTime: ''
            },
            tmpOSSClient: null,
            caseSummartData: {},
            coreData: {},
            highRiskStationAnalysisData: [
                { riskRateRange: '--', shippingSiteCount: 0, deliverySiteCount: 0 },
                { riskRateRange: '--', shippingSiteCount: 0, deliverySiteCount: 0 },
                { riskRateRange: '--', shippingSiteCount: 0, deliverySiteCount: 0 },
                { riskRateRange: '--', shippingSiteCount: 0, deliverySiteCount: 0 },
                { riskRateRange: '--', shippingSiteCount: 0, deliverySiteCount: 0 },
                { riskRateRange: '--', shippingSiteCount: 0, deliverySiteCount: 0 }
            ],
            imageRiskData: {},
            wineRiskData: {},
            lossReasonData: [],
            claimAmountData: null,
            goodsCategoryColor: ['#15D187', '#03B6EC', '#6E69F8', '#1F67F0', '#2CF2F9'],
            goodsCategoryData: [],
            workerWorkData: {},
            longTailCaseData: {},
            fileFLag: false
        };
    },
    mounted() {
        this.initOSSClient();
        this.getDashboard();
        this.resizeHeight = window.innerHeight - 10 - 20 - 40 + 'px';
        window.onresize = () => {
            this.chartsResize();
            this.resizeHeight = window.innerHeight - 10 - 20 - 40 + 'px';
        };

        this.largeStyle = window.innerHeight > 714;
        let today = new Date();
        today.setDate(today.getDate() - 1);
        this.titleTime = this.formatDate(today);
        this.value1 = ['2023-09-01', this.formatDate(today)];
        this.pickerChange();
        this.queryData();
    },
    methods: {
        queryData() {
            // 图片风险识别
            this.getImageRisk();
            // 酒类风险识别
            this.getWineRisk();
            // 长尾案件统计分析
            this.getLongTailCaseStatistics();
            // 案件定损原因分析Top5
            this.getLossReasonData();

            // 案件概要
            this.getCaseSummary();
            // 案件赔付情况数据分析
            this.getCoreDataIndex();
            // 高风险站点分析
            this.getHighRiskStationAnalysis();

            // 商品分类Top5
            this.getGoodsCategoryCaseTop5();
            // 不同索赔区间案件分布
            this.getClaimAmountDistribution();
            // 作业人员作业量统计
            this.getWorkerWorkloadStatistics();
        },
        pickerChange() {
            this.navForm = {
                startTime: this.value1[0],
                endTime: this.value1[1]
            };
            this.queryData();
        },
        downloadBtn() {
            this.downloadFlag = true;
            this.fileFLag = false;
            this.instance.post('/tpa/api/dashboard/screen/export', this.navForm).then((res) => {
                let data = res.data || null;
                if (data && data.code == 200) {
                    this.downloadFile(data.data, 'downloadFlag');
                }
            });
        },
        downloadFile(fileKey, btnLoading) {
            if (this.tmpOSSClient) {
                this.isExistObject(fileKey);
                if (this.fileTime > 200) {
                    this.$message.error('文件下载失败，请稍后重试！');
                    return;
                }

                if (!this.fileFLag) {
                    setTimeout(() => {
                        this.fileTime++;
                        this.downloadFile(fileKey, btnLoading);
                    }, 3000);
                } else {
                    this.fileFLag = false;
                    this.fileTime = 0;
                    let fileUrl = this.tmpOSSClient.signatureUrl(fileKey);
                    this[btnLoading] = false;
                    location.href = fileUrl;
                }
            }
        },
        async isExistObject(name, options = {}) {
            try {
                await this.tmpOSSClient.head(name, options);
                this.fileFLag = true;
            } catch (error) {
                if (error.code === 'NoSuchKey') {
                    this.fileFLag = false;
                }
            }
        },
        // 初始化OSS
        initOSSClient() {
            this.tmpOSSClient = null;
            this.resetOssNum++;
            this.instance
                .get('/tpa/api/aliyun/oss/access')
                .then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.tmpOSSClient = new OSS({
                            accessKeyId: data.data.accessKeyId,
                            accessKeySecret: data.data.accessKeySecret,
                            stsToken: data.data.securityToken,
                            // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                            region: data.data.region,
                            bucket: data.data.bucket
                        });
                    } else {
                        console.log('oss初始化失败，正在重试！');

                        if (this.resetOssNum < 20) {
                            setTimeout(() => {
                                this.initOSSClient();
                            }, 1000);
                        } else {
                            console.log('oss初始化超过20s，失败!');
                        }
                    }
                })
                .catch((err) => {
                    console.log('oss初始化失败-');
                    console.log(err);
                });
        },
        chartsResize() {
            setTimeout(() => {
                this.largeChartRisk1 && this.largeChartRisk1.resize();
                this.largeChartRisk2 && this.largeChartRisk2.resize();
                this.largeChartRisk3 && this.largeChartRisk3.resize();
                this.largeChartRisk4 && this.largeChartRisk4.resize();
                this.largeChart5 && this.largeChart5.resize();
                this.largeChart6 && this.largeChart6.resize();
                this.largeChart7 && this.largeChart7.resize();
                this.largeChart8 && this.largeChart8.resize();
            }, 500);
        },
        chartsClear() {
            this.largeChartRisk1 && this.largeChartRisk1.clear();
            this.largeChartRisk2 && this.largeChartRisk2.clear();
            this.largeChartRisk3 && this.largeChartRisk3.clear();
            this.largeChartRisk4 && this.largeChartRisk4.clear();
            this.largeChart5 && this.largeChart5.clear();
            this.largeChart6 && this.largeChart6.clear();
            this.largeChart7 && this.largeChart7.clear();
            this.largeChart8 && this.largeChart8.clear();
        },
        // 图片风险识别
        getImageRisk() {
            this.largeChartRisk1Loading = true;
            this.instance.post('/tpa/api/dashboard/screen/imageRiskRecognition', this.navForm).then((res) => {
                this.largeChartRisk1Loading = false;
                let data = res.data;
                if (data.code == 200) {
                    this.imageRiskData = data.data;
                    // this.setChartRisk1(this.imageRiskData);
                    // this.setChartRisk2(this.imageRiskData);
                }
            });
        },
        // 酒类风险识别
        getWineRisk() {
            this.largeChartRisk3Loading = true;
            this.instance.post('/tpa/api/dashboard/screen/wineVerification', this.navForm).then((res) => {
                this.largeChartRisk3Loading = false;
                let data = res.data;
                if (data.code == 200) {
                    this.wineRiskData = data.data;
                    // this.setChartRisk3(this.wineRiskData);
                    // this.setChartRisk4(this.wineRiskData);
                }
            });
        },
        // 长尾案件统计分析
        getLongTailCaseStatistics() {
            this.largeChart5Loading = true;
            this.instance.post('/tpa/api/dashboard/screen/longTailCaseStatistics', this.navForm).then((res) => {
                this.largeChart5Loading = false;
                let data = res.data;
                if (data.code == 200) {
                    data.data = [
                        {
                            period: '120天以上',
                            caseCount: 153,
                            caseRate: '46.22%',
                            totalCaseCount: 331
                        },
                        {
                            period: '30-60天',
                            caseCount: 32,
                            caseRate: '9.67%',
                            totalCaseCount: 331
                        },
                        {
                            period: '60-90天',
                            caseCount: 59,
                            caseRate: '17.82%',
                            totalCaseCount: 331
                        },
                        {
                            period: '90-120天',
                            caseCount: 87,
                            caseRate: '26.28%',
                            totalCaseCount: 331
                        }
                    ];
                    this.longTailCaseData = data.data;
                    this.setChart5(this.longTailCaseData);
                }
            });
        },
        // 案件概要
        getCaseSummary() {
            this.instance.post('/tpa/api/dashboard/fixed/caseSummary', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 200 && data.data) {
                    this.caseSummartData = data.data.data.list[0];
                    for (const key in this.caseSummartData) {
                        this.caseSummartData[key] = String(this.caseSummartData[key]).split('.')[0];
                    }
                }
            });
        },
        // 案件赔付情况数据分析
        getCoreDataIndex() {
            this.instance.post('/tpa/api/dashboard/screen/coreDataIndex', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.coreData = data.data;
                    for (const key in this.coreData) {
                        if (String(this.coreData[key]).indexOf('%') == -1 && String(this.coreData[key]).indexOf('时') == -1) {
                            this.coreData[key] = String(this.coreData[key]).split('.')[0];
                        }
                    }
                }
            });
        },
        // 高风险站点分析
        getHighRiskStationAnalysis() {
            this.instance.post('/tpa/api/dashboard/screen/highRiskStationAnalysis', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.highRiskStationAnalysisData = data.data;
                }
            });
        },
        // 案件定损原因分析Top5
        getLossReasonData() {
            let tmpFormData = {
                condition: this.navForm,
                pageNo: 1,
                pageSize: 0
            };
            this.largeChart6Loading = true;
            this.instance.post('/tpa/api/dashboard/fixed/lossReasonData', tmpFormData).then((res) => {
                this.largeChart6Loading = false;
                let data = res.data || null;
                if (data && data.code == 200 && data.data) {
                    this.lossReasonData = data.data;
                    this.setChart6(this.lossReasonData);
                }
            });
        },
        // 商品分类Top5
        getGoodsCategoryCaseTop5() {
            this.largeChart7Loading = true;
            this.instance.post('/tpa/api/dashboard/screen/goodsCategoryCaseTop5', this.navForm).then((res) => {
                this.largeChart7Loading = false;
                let data = res.data;
                if (data.code == 200) {
                    this.goodsCategoryData = data.data;
                    this.setChart7(this.goodsCategoryData);
                }
            });
        },
        // 不同索赔区间案件分布
        getClaimAmountDistribution() {
            this.largeChart8Loading = true;
            this.instance.post('/tpa/api/dashboard/screen/claimAmountDistribution', this.navForm).then((res) => {
                this.largeChart8Loading = false;
                let data = res.data;
                if (data.code == 200) {
                    this.claimAmountData = data.data;
                    this.setChart8(this.claimAmountData);
                }
            });
        },
        // 作业人员作业量统计
        getWorkerWorkloadStatistics() {
            this.largeChart9Loading = true;
            this.instance.post('/tpa/api/dashboard/screen/workerWorkloadStatistics', this.navForm).then((res) => {
                this.largeChart9Loading = false;
                let data = res.data;
                if (data.code == 200) {
                    this.workerWorkData = data.data;
                }
            });
        },
        setChartRisk1(response) {
            let chartDom = document.getElementById('largeChartRisk1');

            if (!chartDom) {
                return;
            }

            let option = {
                title: [
                    {
                        text: response.riskCaseRate ? response.riskCaseRate : '',
                        top: '13px',
                        left: '22px',
                        textStyle: {
                            color: '#fff',
                            fontSize: 12
                        }
                    }
                ],
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        let htmlStr = `
                        <p><span style="display:inline-block;width:10px;height:10px;border-radius:50%;margin-right:10px;background:${params.color}"></span>${params.name}<span style="margin-right:10px;"></span> ${params.value}</p>`;

                        return htmlStr;
                    }
                },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: ['50%', '70%'],
                        center: ['50%', '35%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        hoverAnimation: false,
                        labelLine: {
                            show: false
                        },
                        color: ['#3A75EC', '#0B2972'],
                        data: [
                            { value: response.riskCaseCount, name: '风险案件数' },
                            { value: response.caseCount, name: '案件数' }
                        ]
                    }
                ]
            };
            this.largeChartRisk1 = echarts.init(chartDom);
            this.largeChartRisk1.clear();
            this.largeChartRisk1.setOption(option);
        },
        setChartRisk2(response) {
            let chartDom = document.getElementById('largeChartRisk2');

            if (!chartDom) {
                return;
            }

            let option = {
                title: [
                    {
                        text: response.riskRate ? response.riskRate : '',
                        top: '13px',
                        left: '22px',
                        textStyle: {
                            color: '#fff',
                            fontSize: 12
                        }
                    }
                ],
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        let htmlStr = `
                        <p><span style="display:inline-block;width:10px;height:10px;border-radius:50%;margin-right:10px;background:${params.color}"></span>${params.name}<span style="margin-right:10px;"></span> ${params.value}</p>`;

                        return htmlStr;
                    }
                },
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: ['50%', '70%'],
                        center: ['50%', '35%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        hoverAnimation: false,
                        labelLine: {
                            show: false
                        },
                        color: ['#3A75EC', '#0B2972'],
                        data: [
                            { value: response.riskRecognitionCount, name: '风险识别次数' },
                            { value: response.callCount, name: '调用次数' }
                        ]
                    }
                ]
            };
            this.largeChartRisk2 = echarts.init(chartDom);
            this.largeChartRisk2.clear();
            this.largeChartRisk2.setOption(option);
        },
        setChartRisk3(response) {
            let chartDom = document.getElementById('largeChartRisk3');

            if (!chartDom) {
                return;
            }

            let option = {
                title: [
                    {
                        text: response.riskCaseRate ? response.riskCaseRate : '--',
                        top: '13px',
                        left: '22px',
                        textStyle: {
                            color: '#fff',
                            fontSize: 12
                        }
                    }
                ],
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        let htmlStr = `
                        <p><span style="display:inline-block;width:10px;height:10px;border-radius:50%;margin-right:10px;background:${params.color}"></span>${params.name}<span style="margin-right:10px;"></span> ${params.value}</p>`;

                        return htmlStr;
                    }
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['50%', '70%'],
                        center: ['50%', '35%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        hoverAnimation: false,
                        labelLine: {
                            show: false
                        },
                        color: ['#3A75EC', '#0B2972'],
                        data: [
                            { value: response.caseCount ? response.caseCount : 0, name: '案件数' },
                            { value: response.riskCaseCount ? response.riskCaseCount : 0, name: '风险案件数' }
                        ]
                    }
                ]
            };
            this.largeChartRisk3 = echarts.init(chartDom);
            this.largeChartRisk3.clear();
            this.largeChartRisk3.setOption(option);
        },
        setChartRisk4(response) {
            let chartDom = document.getElementById('largeChartRisk4');

            if (!chartDom) {
                return;
            }

            let option = {
                title: [
                    {
                        text: response.riskRate ? response.riskRate : '--',
                        top: '13px',
                        left: '22px',
                        textStyle: {
                            color: '#fff',
                            fontSize: 12
                        }
                    }
                ],
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        let htmlStr = `
                        <p><span style="display:inline-block;width:10px;height:10px;border-radius:50%;margin-right:10px;background:${params.color}"></span>${params.name}<span style="margin-right:10px;"></span> ${params.value}</p>`;

                        return htmlStr;
                    }
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['50%', '70%'],
                        center: ['50%', '35%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        hoverAnimation: false,
                        labelLine: {
                            show: false
                        },
                        color: ['#3A75EC', '#0B2972'],
                        data: [
                            { value: response.callCount ? response.callCount : 0, name: '调用次数' },
                            { value: response.riskRecognitionCount ? response.riskRecognitionCount : 0, name: '风险识别次数' }
                        ]
                    }
                ]
            };
            this.largeChartRisk4 = echarts.init(chartDom);
            this.largeChartRisk4.clear();
            this.largeChartRisk4.setOption(option);
        },
        setChart6(response) {
            let chartDom = document.getElementById('largeChart6');

            if (!chartDom) {
                return;
            }

            let xAxisData = [];
            let seriesData1 = [];
            let seriesData2 = [];
            let seriesData3 = [];
            let seriesData4 = [];
            let yAxisMax = 0;
            let valMax = 0;
            let valMin = 0;
            if (response.data && response.data.list) {
                response.data.list.forEach((res, index) => {
                    if (index < 5) {
                        xAxisData.push(res.lossReason);
                        // 结案金额
                        seriesData1.push(res.closeAmount);
                        // 差异金额
                        seriesData2.push(res.diffAmount);
                        // 案件数
                        seriesData3.push(res.caseCount);
                        // 索赔金额
                        seriesData4.push(res.claimAmount);
                    }
                });
                const arr = response.data.list;

                let maxValue = arr.reduce((max, obj) => Math.max(max, obj.claimAmount), -Infinity);
                valMax = maxValue;

                const array = response.data.list;

                let minValue = 0;

                for (let i = 0; i < array.length; i++) {
                    if (array[i].claimAmount < minValue) {
                        minValue = array[i].claimAmount;
                    }
                }
                valMin = minValue;
            }
            yAxisMax = Math.max(...seriesData3);

            let axisLabelItem = {
                show: true,
                interval: 0, // 使x轴文字显示全
                textStyle: {
                    color: '#fff' // 设置字体颜色为白色
                }
            };

            if (xAxisData.length > 2) {
                axisLabelItem = {
                    show: true,
                    interval: 0, // 使x轴文字显示全
                    textStyle: {
                        color: '#fff' // 设置字体颜色为白色
                    },
                    formatter: function (params) {
                        var newParamsName = '';
                        var paramsNameNumber = params.length;
                        var provideNumber = 4; // 一行显示几个字
                        var rowNumber = Math.ceil(paramsNameNumber / provideNumber); // 计算需要几行显示
                        for (var i = 0; i < rowNumber; i++) {
                            var tempStr = '';
                            for (var j = 0; j < provideNumber; j++) {
                                var index = i * provideNumber + j;
                                if (index < paramsNameNumber) {
                                    tempStr += params[index];
                                }
                            }
                            newParamsName += tempStr + '\n'; // 换行
                        }
                        return newParamsName;
                    }
                };
            }

            let option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    textStyle: {
                        color: '#fff'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '2%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: xAxisData,
                        axisTick: {
                            alignWithLabel: true
                        },
                        axisLabel: axisLabelItem
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '金额（元）',
                        nameTextStyle: {
                            color: '#fff'
                        },
                        axisLabel: {
                            formatter: '{value}',
                            textStyle: {
                                color: '#fff'
                            }
                        },
                        splitNumber: 4, // 设置Y轴分割段数为4
                        splitLine: {
                            show: true, // 是否显示分割线
                            lineStyle: {
                                color: '#1170B1' // 设置分割线的颜色
                            }
                        }
                    }
                ],
                color: ['#7B80FE', '#6AC0FD', '#74C487'],
                series: [
                    {
                        name: '结案金额',
                        type: 'bar',
                        stack: 'total',
                        barMaxWidth: '28px',
                        data: seriesData1
                    },
                    {
                        name: '差异金额',
                        type: 'bar',
                        stack: 'total',
                        barMaxWidth: '28px',
                        data: seriesData2
                    },
                    {
                        name: '索赔金额',
                        type: 'bar',
                        barMaxWidth: '28px',
                        data: seriesData4
                    }
                ]
            };
            this.largeChart6 = echarts.init(chartDom);
            this.largeChart6.clear();
            this.largeChart6.setOption(option);
        },
        setChart7(response) {
            let _this = this;
            let chartDom = document.getElementById('largeChart7');

            if (!chartDom) {
                return;
            }
            let tmpResponse = JSON.parse(JSON.stringify(response));
            tmpResponse.sort((a, b) => a.caseCount - b.caseCount);
            let seriesData = [];
            let itemColor = ['#15D187', '#03B6EC', '#6E69F8', '#1F67F0', '#2CF2F9'];
            tmpResponse.forEach((res, index) => {
                seriesData.push({
                    value: res.caseRate ? (Number(res.caseRate.split('%')[0]) / 100) * 4 : 0,
                    itemStyle: { color: itemColor[tmpResponse.length - index - 1] }
                });
            });

            let option = {
                polar: {
                    radius: this.largeStyle ? [10, '90%'] : [10, '80%'],
                    center: this.largeStyle ? ['45%', '45%'] : ['43.5%', '35%'],
                    startAngle: 10
                },
                angleAxis: {
                    max: 4,
                    show: false, // 隐藏角度轴
                    clockwise: false
                },
                radiusAxis: {
                    type: 'category',
                    show: false // 隐藏半径轴
                },
                series: {
                    type: 'bar',
                    barWidth: 6,
                    data: seriesData,
                    showBackground: true,
                    backgroundStyle: {
                        color: 'rgba(180, 180, 180, 0.2)'
                    },
                    coordinateSystem: 'polar',
                    label: {
                        show: true,
                        rotate: 0,
                        color: '#fff',
                        fontSize: 10,
                        position: 'start',
                        offset: [35, 0],
                        formatter: (params) => {
                            return tmpResponse[params.dataIndex].caseRate;
                        }
                    }
                }
            };
            this.largeChart7 = echarts.init(chartDom);
            this.largeChart7.clear();
            this.largeChart7.setOption(option);
        },
        setChart8(response) {
            let chartDom = document.getElementById('largeChart8');

            if (!chartDom) {
                return;
            }

            let seriesData = [];
            response.forEach((res) => {
                seriesData.push({
                    value: Number(res.caseRate.split('%')[0]),
                    name: res.claimAmountRange
                });
            });

            // 根据params.dataIndex或其他属性来设置颜色
            let colorList = ['#488CF7', '#38CAFB', '#4BAFFA', '#4ADFCB', '#29A3EB', '#036EEB', '#194089']; // 颜色列表
            let option = {
                tooltip: {
                    trigger: 'item',
                    formatter: (params) => {
                        let showItem;
                        let showIndex = -1;
                        response.forEach((res, index) => {
                            if (res.claimAmountRange == params.name) {
                                showItem = res;
                                showIndex = index;
                            }
                        });
                        let htmlStr = `
                        <p><span style="display:inline-block;width:10px;height:10px;border-radius:50%;margin-right:10px;background:${showIndex >= 0 ? colorList[showIndex] : 'transparent'}"></span>${showItem.claimAmountRange}</p>
                        <p style="text-align: justify;">案件占比：<span style="margin-left:15px"></span>${showItem.caseRate}</p>
                        <p style="text-align: justify;">索赔案件数量：<span style="margin-left:5px"></span>${showItem.claimAmountRangeCount}</p>
                        `;
                        return htmlStr;
                    }
                },
                series: [
                    {
                        name: 'Nightingale Chart',
                        type: 'pie',
                        radius: this.largeStyle ? [10, 70] : [10, 50],
                        center: this.largeStyle ? ['50%', '45%'] : ['50%', '50%'],
                        roseType: 'area',
                        data: seriesData,
                        label: {
                            show: true,
                            color: '#fff',
                            formatter: function (params) {
                                return params.data.name + '\n' + params.data.value + '%';
                            }
                        },
                        itemStyle: {
                            color: function (params) {
                                return colorList[params.dataIndex];
                            }
                        }
                    }
                ]
            };
            this.largeChart8 = echarts.init(chartDom);
            this.largeChart8.clear();
            this.largeChart8.setOption(option);
        },
        getDashboard() {
            this.instance.get('/tpa/api/case/dashboard/caseQualityDashboard', { params: {} }).then((res) => {
                let data = res.data || null;
                if (data && data.code == 200) {
                    this.iframeSrc = data.data;
                }
            });
        },
        formatDate(date) {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        goBack() {
            this.$router.push({
                path: '/caseInquiry/index',
                query: {
                    t: +new Date() //保证每次点击路由的query项都是不一样的，确保会重新刷新view
                }
            });
        },
        // start
        setLabel(response) {
            let pieColor = ['#4F0DF5', '#00B6D6', '#FFDD5F', '#04AB59'];
            response.forEach((item, index) => {
                item.itemStyle = {
                    color: pieColor[index]
                };
                item.label = {
                    normal: {
                        show: true,
                        color: pieColor[index],
                        rich: {
                            b: {
                                color: '#fff',
                                lineHeight: 25,
                                align: 'left'
                            },
                            c: {
                                fontSize: 22,
                                color: '#fff'
                            },
                            d: {
                                color: pieColor[index],
                                align: 'left'
                            }
                        }
                    }
                };
            });
        },
        // 图表初始化
        setChart5(response) {
            let chartDom = document.getElementById('largeChart5');

            if (!chartDom) {
                return;
            }

            let pieColor = ['#4F0DF5', '#00B6D6', '#FFDD5F', '#04AB59'];
            let seriesData = [];
            response.forEach((res, index) => {
                seriesData.push({
                    name: res.period,
                    value: Number(res.caseRate.split('%')[0]),
                    itemStyle: { color: pieColor[index] },
                    label: {
                        show: true,
                        rich: {
                            b: {
                                color: '#fff',
                                lineHeight: 25,
                                align: 'left'
                            },
                            c: {
                                fontSize: 22,
                                color: '#fff'
                            },
                            d: {
                                color: '#237BCD',
                                align: 'left'
                            }
                        }
                    }
                });
            });
            // 传入数据生成 option, 构建3d饼状图, 参数工具文件已经备注的很详细
            this.option = getPie3D(seriesData, 0.8, 240, 28, 26, 0.5);
            // 是否需要label指引线，如果要就添加一个透明的2d饼状图并调整角度使得labelLine和3d的饼状图对齐，并再次setOption
            this.option.tooltip = {
                trigger: 'item', // 触发类型，默认数据触发，可选为：'item'、'axis'
                formatter: (params) => {
                    let showItem;
                    let showIndex = -1;
                    response.forEach((res, index) => {
                        if (res.period == params.name) {
                            showItem = res;
                            showIndex = index;
                        }
                    });
                    let htmlStr = `
                        <p><span style="display:inline-block;width:10px;height:10px;border-radius:50%;margin-right:10px;background:${showIndex >= 0 ? pieColor[showIndex] : 'transparent'}"></span>${showItem.period}</p>
                        <p style="text-align: justify;">案件占比：<span style="margin-left:20px"></span>${showItem.caseRate}</p>
                        <p style="text-align: justify;">案件数：<span style="margin-left:30px"></span>${showItem.caseCount}</p>
                        `;
                    return htmlStr;
                }
            };
            // const bgImg = new URL(`../../../public/images/largeScreen/3dBg.png`, import.meta.url).href;
            // this.option.graphic = [
            //     {
            //         type: 'image', // 图形元素类型
            //         // width: '50%', // 图片宽度为容器宽度的50% 380/2 = 190 290/2= 195
            //         left: 'center',
            //         top: 'center', // middle
            //         // right: 'center', // 根据父元素进行定位 （居中）
            //         // bottom: 'center', // 根据父元素进行定位   （0%）, 如果bottom的值是 0，也可以删除该bottom属性值。
            //         z: -1, // 层叠
            //         bounding: 'all', // 决定此图形元素在定位时，对自身的包围盒计算方式
            //         style: {
            //             image: 'https://static.sigreal.com.cn/cargo-fe/static/img/3dBg.b2aabc05.png',
            //             width: 290,
            //             height: 198
            //         }
            //     }
            // ];
            this.option.series.push({
                name: 'pie2d',
                type: 'pie',
                label: {
                    opacity: 1,
                    lineHeight: 20,
                    color: '#fff',
                    show: true,
                    formatter: '{b}\n{d}%'
                },
                labelLine: {
                    length: 15, // 标签线的长度，可以根据需要调整
                    lineStyle: {
                        color: '#fff' // 标签线的颜色
                    }
                },
                startAngle: -40, // 起始角度，支持范围[0, 360]。
                clockwise: false, // 饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
                radius: ['20%', '50%'],
                center: ['50%', '48%'],
                data: seriesData,
                itemStyle: {
                    opacity: 0 //这里必须是0，不然2d的图会覆盖在表面
                }
            });
            this.largeChart5 = echarts.init(chartDom);
            this.largeChart5.clear();
            this.largeChart5.setOption(this.option);
            // this.bindListen(this.statusChart);
        },
        // 监听鼠标事件，实现饼图选中效果（单选），近似实现高亮（放大）效果。
        // optionName是防止有多个图表进行定向option传递，单个图表可以不传，默认是opiton
        bindListen(myChart, optionName = 'option') {
            return;
            let selectedIndex = '';
            let hoveredIndex = '';
            // 监听点击事件，实现选中效果（单选）
            myChart.on('click', (params) => {
                // 从 option.series 中读取重新渲染扇形所需的参数，将是否选中取反。
                const isSelected = !this[optionName].series[params.seriesIndex].pieStatus.selected;
                const isHovered = this[optionName].series[params.seriesIndex].pieStatus.hovered;
                const k = this[optionName].series[params.seriesIndex].pieStatus.k;
                const startRatio = this[optionName].series[params.seriesIndex].pieData.startRatio;
                const endRatio = this[optionName].series[params.seriesIndex].pieData.endRatio;
                // 如果之前选中过其他扇形，将其取消选中（对 option 更新）
                if (selectedIndex !== '' && selectedIndex !== params.seriesIndex) {
                    this[optionName].series[selectedIndex].parametricEquation = getParametricEquation(this[optionName].series[selectedIndex].pieData.startRatio, this[optionName].series[selectedIndex].pieData.endRatio, false, false, k, this[optionName].series[selectedIndex].pieData.value);
                    this[optionName].series[selectedIndex].pieStatus.selected = false;
                }
                // 对当前点击的扇形，执行选中/取消选中操作（对 option 更新）
                this[optionName].series[params.seriesIndex].parametricEquation = getParametricEquation(startRatio, endRatio, isSelected, isHovered, k, this[optionName].series[params.seriesIndex].pieData.value);
                this[optionName].series[params.seriesIndex].pieStatus.selected = isSelected;
                // 如果本次是选中操作，记录上次选中的扇形对应的系列号 seriesIndex
                selectedIndex = isSelected ? params.seriesIndex : null;
                // 使用更新后的 option，渲染图表
                myChart.setOption(this[optionName]);
            });
            // 监听 mouseover，近似实现高亮（放大）效果
            myChart.on('mouseover', (params) => {
                // 准备重新渲染扇形所需的参数
                let isSelected;
                let isHovered;
                let startRatio;
                let endRatio;
                let k;
                // 如果触发 mouseover 的扇形当前已高亮，则不做操作
                if (hoveredIndex === params.seriesIndex) {
                    // 否则进行高亮及必要的取消高亮操作
                } else {
                    // 如果当前有高亮的扇形，取消其高亮状态（对 option 更新）
                    if (hoveredIndex !== '') {
                        // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 false。
                        isSelected = this[optionName].series[hoveredIndex].pieStatus.selected;
                        isHovered = false;
                        startRatio = this[optionName].series[hoveredIndex].pieData.startRatio;
                        endRatio = this[optionName].series[hoveredIndex].pieData.endRatio;
                        k = this[optionName].series[hoveredIndex].pieStatus.k;
                        // 对当前点击的扇形，执行取消高亮操作（对 option 更新）
                        this[optionName].series[hoveredIndex].parametricEquation = getParametricEquation(startRatio, endRatio, isSelected, isHovered, k, this[optionName].series[hoveredIndex].pieData.value);
                        this[optionName].series[hoveredIndex].pieStatus.hovered = isHovered;
                        // 将此前记录的上次选中的扇形对应的系列号 seriesIndex 清空
                        hoveredIndex = '';
                    }
                    // 如果触发 mouseover 的扇形不是透明圆环，将其高亮（对 option 更新）
                    if (params.seriesName !== 'mouseoutSeries' && params.seriesName !== 'pie2d') {
                        // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 true。
                        isSelected = this[optionName].series[params.seriesIndex].pieStatus.selected;
                        isHovered = true;
                        startRatio = this[optionName].series[params.seriesIndex].pieData.startRatio;
                        console.log('---');
                        console.log(this[optionName].series[params.seriesIndex].pieData);
                        endRatio = this[optionName].series[params.seriesIndex].pieData.endRatio;
                        k = this[optionName].series[params.seriesIndex].pieStatus.k;
                        // 对当前点击的扇形，执行高亮操作（对 option 更新）
                        this[optionName].series[params.seriesIndex].parametricEquation = getParametricEquation(startRatio, endRatio, isSelected, isHovered, k, this[optionName].series[params.seriesIndex].pieData.value + 60);
                        this[optionName].series[params.seriesIndex].pieStatus.hovered = isHovered;
                        // 记录上次高亮的扇形对应的系列号 seriesIndex
                        hoveredIndex = params.seriesIndex;
                    }
                    // 使用更新后的 option，渲染图表
                    myChart.setOption(this[optionName]);
                }
            });
            // 修正取消高亮失败的 bug
            myChart.on('globalout', () => {
                // 准备重新渲染扇形所需的参数
                let isSelected;
                let isHovered;
                let startRatio;
                let endRatio;
                let k;
                if (hoveredIndex !== '') {
                    // 从 option.series 中读取重新渲染扇形所需的参数，将是否高亮设置为 true。
                    isSelected = this[optionName].series[hoveredIndex].pieStatus.selected;
                    isHovered = false;
                    k = this[optionName].series[hoveredIndex].pieStatus.k;
                    startRatio = this[optionName].series[hoveredIndex].pieData.startRatio;
                    endRatio = this[optionName].series[hoveredIndex].pieData.endRatio;
                    // 对当前点击的扇形，执行取消高亮操作（对 option 更新）
                    this[optionName].series[hoveredIndex].parametricEquation = getParametricEquation(startRatio, endRatio, isSelected, isHovered, k, this[optionName].series[hoveredIndex].pieData.value);
                    this[optionName].series[hoveredIndex].pieStatus.hovered = isHovered;
                    // 将此前记录的上次选中的扇形对应的系列号 seriesIndex 清空
                    hoveredIndex = '';
                }
                // 使用更新后的 option，渲染图表
                myChart.setOption(this[optionName]);
            });
        }
        // end
    },
    watch: {
        isCollapse: {
            handler(val) {
                this.chartsResize();
            },
            immediate: true
        }
    }
};
