
import lossRecheckPage from '@/components/caseInfo/lossRecheckPage.vue';
export default {
    components: {
        lossRecheckPage
    },
    data() {
        return {
            jdDutyOptions: [
                { value: true, desc: '是' },
                { value: false, desc: '否' }
            ],
            queryLoading: false,
            caseDialogFlag: false,
            batchTransferFlag: true,
            dialogVisible: false,
            loading: false,
            multipleSelection: [],
            navForm: {
                condition: {
                    caseInTimeStart: '',
                    caseInTimeEnd: '',
                    caseNo: '',
                    reviewStatus: [],
                    reviewUserIds: []
                },
                pageNo: 1,
                pageSize: 10,
                total: 0
            },
            tmpUserData: [],
            tableData: [],
            caseStepOption: [],
            caseEndStepOption: [],
            fetchCaseStepFlow: [],
            queryUserData: [],
            dialogForm: {
                userId: '',
                phone: ''
            },
            tabDictionary: {
                正式案件: '1',
                预报案案件: '2'
            },
            handleFlag: false,
            handleData: {},
            tableHeight: null,
            statisticsUserData: {},
            overviewStatisticsData: {},
            dialogUserOption: [],
            userTableData: [],
            transferData: {},
            reviewStatusEnum: [],
            manualDialogFlag: false,
            manualList: [{ id: '', name: '', percentage: '' }]
        };
    },
    mounted() {
        // 动态计算table高度
        this.calcHeight();
        window.onresize = () => {
            this.calcHeight();
        };
        this.getReviewStatus();
        this.getTableData();
        // this.getStatisticsByUser();
        // this.getStatisticsByUserStatus();
        this.getUserList();
        this.getManual();
    },
    methods: {
        calcHeight() {
            let statisticsBoxHeight = document.querySelector('.statistics_box') ? document.querySelector('.statistics_box').clientHeight : 0;
            let elPaginationHeight = document.querySelector('.el-pagination') ? document.querySelector('.el-pagination').clientHeight : 0;
            let guHeight = 20 + 62 + 200;
            this.tableHeight = window.innerHeight - statisticsBoxHeight - elPaginationHeight - guHeight;
        },
        getReviewStatus() {
            this.instance.get('/tpa/api/enum/getCaseReviewStatusEnum', {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.reviewStatusEnum = data.data;
                }
            });
        },
        getTableData() {
            this.getStatisticsByUser();
            this.getStatisticsByUserStatus();
            this.loading = true;
            this.instance.post('/tpa/api/case/review/record/paging', this.navForm).then((res) => {
                this.loading = false;
                let data = res.data;
                if (res.data.code == 200 && res.data.data) {
                    this.tableData = res.data.data.list || [];
                    this.navForm.total = data.data.total;
                } else {
                    this.navForm.total = 0;
                }
            });
        },
        // 统计案件流入量-按照复核状态-按照人
        getStatisticsByUser() {
            this.instance.get('/tpa/api/case/review/record/review-status/statistics-by-user', {}).then((res) => {
                let data = res.data;
                if (data.code == 200 && data.data) {
                    this.statisticsUserData = data.data;
                }
            });
        },
        // 查询改派任务人员
        searchDialogTable() {
            let tmpListData = [];
            if (this.dialogForm.userId && this.dialogForm.phone) {
                this.userTableData.length > 0 &&
                    this.userTableData.forEach((item) => {
                        if (this.dialogForm.userId == item.userId && this.dialogForm.phone == item.mobile) {
                            tmpListData.push(item);
                        }
                    });
            }
            if (this.dialogForm.userId) {
                this.userTableData.length > 0 &&
                    this.userTableData.forEach((item) => {
                        if (this.dialogForm.userId == item.userId) {
                            tmpListData.push(item);
                        }
                    });
            }
            if (this.dialogForm.phone) {
                this.userTableData.length > 0 &&
                    this.userTableData.forEach((item) => {
                        if (this.dialogForm.phone == item.mobile) {
                            tmpListData.push(item);
                        }
                    });
            }
            if (!this.dialogForm.userId && !this.dialogForm.phone) {
                this.tmpUserData = this.queryUserData;
                return;
            }
            this.tmpUserData = tmpListData;
        },
        resetDialogTable() {
            this.tmpUserData = this.queryUserData;
            this.$refs.dialogForm.resetFields();
        },
        getUserList(queryFlag) {
            this.instance.post('/tpa/api/case/task/searchUser', { schedulingType: 3 }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    if (!queryFlag) {
                        this.userTableData = data.data;
                    } else {
                        this.queryUserData = data.data;
                        this.tmpUserData = data.data;
                        this.dialogUserOption = [];
                        this.queryUserData.length > 0 &&
                            this.queryUserData.forEach((item) => {
                                this.dialogUserOption.push({
                                    username: item.username,
                                    userId: item.userId
                                });
                            });
                    }
                }
            });
        },
        closeDialog() {
            this.tmpUserData = [];
            this.dialogFormVisible = false;
            this.$refs.dialogForm.resetFields();
        },
        // 统计案件流入量-按照复核状态-按照人
        getStatisticsByUserStatus() {
            this.queryLoading = true;
            this.instance.get('/tpa/api/case/review/record/review-status/statistics', {}).then((res) => {
                let data = res.data;
                this.queryLoading = false;
                if (data.code == 200 && data.data) {
                    this.overviewStatisticsData = data.data[0];
                }
            });
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.navForm.pageSize = val;
            this.getTableData();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange(val) {
            this.navForm.pageNo = val;
            this.getTableData();
        },
        showCaseInfo(rowItem) {
            rowItem.editReview = false;
            this.handleData = rowItem;
            this.caseDialogFlag = true;
        },
        transferBtn(bathFlag, rowCaseNo) {
            let caseNoList = [];
            // 获取人员列表
            this.getUserList(true);
            if (bathFlag) {
                this.multipleSelection.forEach((res) => {
                    res.reviewStatus == 20 && caseNoList.push(res.caseNo);
                });
            } else {
                caseNoList = [rowCaseNo];
            }
            this.transferData = {
                reviewUserId: null,
                caseNoList: caseNoList
            };
            this.dialogVisible = true;
        },
        transferCase(userItem) {
            this.transferData.reviewUserId = userItem.userId;
            this.instance.post('/tpa/api/case/review/record/transfer', this.transferData).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.$message.success('转交成功！');
                    this.multipleSelection = [];

                    this.transferData = {};
                    this.dialogVisible = false;
                    this.getTableData();
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        closeCaseDetail() {},
        selectChange(val) {
            this.multipleSelection = val;
        },
        searchTable() {
            this.navForm.pageNo = 1;
            this.getTableData();
        },
        resetForm() {
            this.navForm = {
                condition: {
                    caseInTimeStart: '',
                    caseInTimeEnd: '',
                    caseNo: '',
                    reviewStatus: [],
                    reviewUserIds: []
                },
                pageNo: 1,
                pageSize: 10,
                total: 0
            };
        },
        handleManualData(handleType, itemIndex) {
            if (handleType == 0) {
                let itemManual = { id: '', name: '', percentage: '' };
                this.manualList.push(itemManual);
            } else {
                this.manualList.splice(itemIndex, 1);
            }
        },
        getManual() {
            this.instance.post('/tpa/api/env/get/reviewer', {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.manualList = data.data.reviewers;
                }
            });
        },
        submitManual(subFlag) {
            if (subFlag) {
                console.log(this.manualList);
                this.instance.post('/tpa/api/env/update/reviewer', { reviewers: this.manualList }).then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.$message.success('保存成功');
                        this.submitManual(false);
                    } else {
                        this.$message.error(data.message);
                    }
                });
            } else {
                this.manualDialogFlag = false;
                this.manualList = [{ id: '', name: '', percentage: '' }];
                this.getManual();
            }
        },
        handleChangeItem(name, index) {
            console.log(name);
            // 通过 name 在 userTableData 查找user 对象
            this.userTableData.find((item) => {
                if (item.username === name) {
                    this.manualList[index].id = item.userId;
                }
            });
            console.log(this.manualList);
        }
    }
};
