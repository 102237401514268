
export default {
    data() {
        return {
            iframeSrc: '',
            resizeHeight: '653'
        };
    },
    mounted() {
        this.getDashboard();
        this.resizeHeight = window.innerHeight - 10 - 20 - 40 + 'px';
        window.onresize = () => {
            this.resizeHeight = window.innerHeight - 10 - 20 - 40 + 'px';
        };
    },
    methods: {
        getDashboard() {
            this.instance.get('/tpa/api/case/dashboard/caseQualityDashboard', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.iframeSrc = data.data;
                }
            });
        }
    }
};
