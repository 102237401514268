
export default {
    name: 'featureList',
    components: {},
    props: {
        menuType: {
            type: String,
            default: '99'
        }
    },
    data() {
        return {
            loading: false,
            dialogVisible: false,
            dialogVisibleUser: false,
            currentPage: 1, //当前页
            pageSize: 10, //一页多少条数据
            totalPage: 1, //总页数tableData:[],
            tableData: [],
            rules: {
                featureName: [{ required: true, message: '请输入因子名称', trigger: 'blur' }],
                fetchType: [{ required: true, message: '请输入因子数据来源', trigger: 'change' }],
                dataType: [{ required: true, message: '请输入因子数据类型', trigger: 'change' }],
                featureDesc: [{ required: true, message: '请输入因子描述', trigger: 'blur' }]
            },
            navForm: {
                //储存搜索框的检索条件信息
                endCreateTime: '',
                startCreateTime: '',
                createBy: '',
                featureName: '',
                featureCode: ''
            },
            featureForm: {
                featureDesc: '',
                featureName: '',
                fetchType: '',
                fetchUrl: '',
                dataType: ''
            },
            dataTypeList: [],
            fetchTypeList: []
        };
    },
    methods: {
        //点击添加按钮
        handleFeature() {
            this.$refs.featureForm && this.$refs.featureForm.resetFields();
            this.featureForm.featureDesc = '';
            this.dialogVisible = true;
        },
        // 保存因子
        handleAddFeature() {
            this.$refs.featureForm.validate((valid) => {
                if (valid) {
                    if (this.featureForm.fetchType == 20 && !this.featureForm.fetchUrl) {
                        this.loading = false;
                        this.$message({ message: '抱歉，请填写数据获取路径！', type: 'error' });
                        return false;
                    }
                    let feature = JSON.parse(JSON.stringify(this.featureForm));
                    this.loading = true;
                    this.instance
                        .post('/risk/api/feature/definition/add', feature)
                        .then(({ data }) => {
                            this.loading = false;
                            if (data.code === 200) {
                                this.$message({ message: '因子配置成功', type: 'success' });
                                this.featureForm.visible = false;
                                this.handleCurrentChange(1);
                                this.dialogVisible = false;
                            } else {
                                this.$message({ message: data.message, type: 'error' });
                            }
                        })
                        .catch((err) => {
                            this.loading = false;
                            this.$message({ message: '保存失败，网络错误', type: 'error' });
                        });
                }
            });
        },
        onStatusChange: function (row) {
            this.loading = true;
            this.instance
                .post('/risk/api/feature/definition/edit', { featureCode: row.featureCode, status: row.status })
                .then(({ data }) => {
                    this.loading = false;
                    const operate = row.status === '0' ? '启用' : '停用';
                    if (data.code === 200) {
                        this.$message({ message: '因子' + row.featureName + '已' + operate, type: 'success' });
                        this.handleCurrentChange(this.$data.currentPage);
                    } else {
                        this.$message.error('因子' + row.ruleName + operate + '失败！');
                    }
                })
                .catch((_) => (this.loading = false));
        },
        handleDataType: function () {
            this.instance.get('/risk/api/enum/getDataTypeEnum').then((res) => {
                this.loading = false;
                this.dataTypeList = res.data.data;
            });
        },
        handleFetchType: function () {
            this.instance.get('/risk/api/enum/getFetchTypeEnum').then((res) => {
                this.loading = false;
                this.fetchTypeList = res.data.data;
            });
        },
        //获取tbody里的数据
        getTbodyData() {
            this.loading = true;
            this.tableData = []; //  清空table的值
            var param = {
                condition: JSON.parse(JSON.stringify(this.navForm)),
                pageNo: this.currentPage,
                pageSize: this.pageSize
            };
            this.instance.post('/risk/api/feature/definition/paging', param).then((res) => {
                this.loading = false;
                this.tableData = res.data.data.list;
                this.totalPage = parseInt(res.data.data.total);
            });
        },
        //点击查询
        searchTable() {
            this.currentPage = 1;
            this.getTbodyData();
        },
        //重置筛选项
        resetForm() {
            this.navForm.endCreateTime = '';
            this.$refs.navForm.resetFields();
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val;
            this.getTbodyData();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange(val) {
            this.currentPage = Number(val);
            this.getTbodyData();
        }
    },
    mounted() {
        //
        this.getTbodyData();
        this.handleDataType();
        this.handleFetchType();
    }
};
