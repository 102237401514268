
// @ is an alias to /src
import CaseInfo from '@/components/caseInfo/index';
export default {
    name: 'qualityTesting',
    components: {
        CaseInfo
    },
    data() {
        return {
            loading: false,
            isVisible: false,
            activeName: '10,20',
            qualityBtnFlag: false,
            navForm: {
                qualityStepList: [],
                endReportTime: '',
                inflowEndTime: ''
            },
            ruleName: [
                {
                    desc: '案件总用时',
                    value: '1'
                },
                {
                    desc: '立案用时',
                    value: '2'
                },
                {
                    desc: '核赔用时',
                    value: '3'
                }
            ],
            ruleData: [
                {
                    name: '',
                    value: '',
                    unit1: '',
                    unit2: ''
                }
            ],
            publicRule: {
                name: '',
                value: '',
                unit1: '',
                unit2: ''
            },
            qualityStepOption: [],
            caseEndStepOption: [],
            qualityStepList: [],
            currentPage: 1, //当前页
            pageSize: 10, //一页多少条数据
            totalPage: 1, //总页数tableData:[],
            tableData: [],
            tableHeight: null
        };
    },
    mounted() {
        // 动态计算table高度
        this.calcHeight();

        window.onresize = () => {
            this.calcHeight();
        };
        this.getQualityStepEnum();
    },
    methods: {
        calcHeight() {
            let elRadioGroup = document.querySelector('.el-radio-group') ? document.querySelector('.el-radio-group').clientHeight : 0;
            let registerTabsHeight = document.querySelector('.register_tabs') ? document.querySelector('.register_tabs').clientHeight : 0;
            let publicBoxHeight = document.querySelector('.public_box') ? document.querySelector('.public_box').clientHeight : 0;
            let elPaginationHeight = document.querySelector('.el-pagination') ? document.querySelector('.el-pagination').clientHeight : 0;
            let tableHeaderHeight = 51;
            let guHeight = null;

            switch (this.menuType) {
                case 'searchPage':
                    guHeight = 20 + 20 + 20 + 60 + 10;
                    this.tableHeight = window.innerHeight - registerTabsHeight - publicBoxHeight - elPaginationHeight - tableHeaderHeight - guHeight;
                    break;
                case 'staging':
                    guHeight = 20 + 20 + 20 + 60 + 30;
                    this.tableHeight = window.innerHeight - elRadioGroup - registerTabsHeight - publicBoxHeight - elPaginationHeight - tableHeaderHeight - guHeight;
                    break;

                default:
                    guHeight = 20 + 20 + 20 + 60 + 5;
                    this.tableHeight = window.innerHeight - registerTabsHeight - publicBoxHeight - elPaginationHeight - tableHeaderHeight - guHeight;
                    break;
            }
            this.tableHeight = this.tableHeight < 200 ? 200 : this.tableHeight;
        },
        handleCase(row) {
            localStorage.setItem('caseNo', row.caseNo);
            this.qualityBtnFlag = true;
        },
        closeInfo(data) {
            this.getTbodyData();
            this.qualityBtnFlag = data;
        },
        //添加规则
        addRule() {
            let publicRule = JSON.parse(JSON.stringify(this.publicRule));
            this.ruleData.push(publicRule);
        },
        //删除规则
        deleteRule(items, index) {
            if (this.ruleData.length > 1) {
                this.ruleData.splice(index, 1);
            } else {
                this.$message.error('抱歉，需保留一条自定义抽检规则！');
            }
        },
        //点击tabs时的回调
        handleClickTabs(tab, event) {
            this.navForm.qualityStepList = this.activeName.split(',');
            this.getTbodyData();
        },
        getTbodyData() {
            this.loading = true;
            this.tableData = []; //  清空table的值
            var json = {};
            var navForm = JSON.parse(JSON.stringify(this.navForm));
            json.condition = navForm;
            json.condition.caseStep = '70';
            json.pageNo = this.currentPage;
            json.pageSize = this.pageSize;
            this.instance.post('/tpa/api/case/quality/paging', json).then((res) => {
                this.loading = false;
                this.tableData = res.data.data.list;
                this.totalPage = parseInt(res.data.data.total);
            });
        },
        // 获取质检状态枚举
        getQualityStepEnum() {
            this.instance.get('/tpa/api/enum/getQualityStepEnum', {}).then((res) => {
                if (res.data.code == 200) {
                    this.qualityStepOption = res.data.data;
                    let obj = {
                        code: '',
                        desc: '全部'
                    };
                    this.qualityStepOption.forEach((val) => {
                        obj.code += val.code + ',';
                        this.qualityStepList.push(val.code);
                    });
                    obj.code = obj.code.slice(0, -1);
                    this.qualityStepOption.unshift(obj);
                    this.navForm.qualityStepList = this.qualityStepList;
                    this.getTbodyData();
                }
            });
        },
        //点击查询
        searchTable() {
            this.currentPage = 1;
            this.getTbodyData();
            this.handleClickTabs();
        },
        //重置筛选项
        resetForm() {
            this.$refs.navForm.resetFields();
            this.ruleData = [];
            this.addRule();
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val;
            this.getTbodyData();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange(val) {
            this.currentPage = Number(val);
            this.getTbodyData();
        }
    }
};
