
import OSS from 'ali-oss';
import InnerQualityPage from '@/components/caseInfo/InnerQualityPage.vue';
import ForecastCasePage from '@/components/caseInfo/ForecastCasePage.vue';

let tmpEndTime = new Date().toLocaleDateString().replace(/\//g, '-') + ' 23:59:59';
tmpEndTime = JSON.parse(JSON.stringify(tmpEndTime));
export default {
    name: 'innerQualityManage',
    components: {
        InnerQualityPage,
        ForecastCasePage
    },
    data() {
        return {
            jdDutyOptions: [
                { value: true, desc: '是' },
                { value: false, desc: '否' }
            ],
            handleFlag: false,
            loading: false,
            dialogVisible: false,
            OSSClient: null,
            editableTabsValue: '0',
            editableTabs: [
                {
                    title: '质检案件列表',
                    menuTypeNum: '0'
                }
            ],
            navForm: {
                caseNo: '',
                handlerId: '',
                riskLevel: '',
                innerQualityStep: '',
                lossAmountRange: '',
                decidedAmountRange: '',
                accCause: '',
                startReportTime: '',
                endReportTime: '',
                startInflowTime: '',
                endInflowTime: '',
                startCaseEndTime: '',
                endCaseEndTime: '',
                startQualityTime: '',
                endQualityTime: '',
                pageSize: ''
            },
            qualityForm: {
                caseNo: '',
                qualityRiskLevel: '',
                conclusion: '',
                emphasisCase: true,
                qualityRemark: ''
            },
            handleData: null,
            currentPage: 1,
            pageSize: 10,
            totalPage: 1,
            userTableData: [],
            riskLevelOption: [],
            lossAmountRangeOption: [],
            decidedAmountRangeOption: [],
            goodsCategoryOption: [],
            questionLevelOption: [],
            accCauseOption: [
                {
                    code: 10,
                    desc: '丢失'
                },
                {
                    code: 20,
                    desc: '破损'
                }
            ],
            caseQualityStepOption: [],
            questionTypeOption: [],
            tableData: [],
            qualityRules: {
                qualityRiskLevel: [{ required: true, message: '请选择类型', trigger: 'change' }],
                conclusion: [{ required: true, message: '请选择问题', trigger: 'change' }],
                emphasisCase: [{ required: true, message: '请选择', trigger: 'change' }]
            },
            qualityQueryOption: [
                {
                    code: 20,
                    desc: '已质检'
                },
                {
                    code: 10,
                    desc: '待质检'
                }
            ],
            conclusionOption: [],
            tableHeight: null,
            searchFold: false,
            conclusion: ['全额赔付', '部分赔付', '不赔付', '不确认']
        };
    },
    mounted() {
        // 动态计算table高度
        this.calcHeight();
        window.onresize = () => {
            this.calcHeight();
        };
        this.getPublicEnum('getQuestionTypeEnum', 'questionTypeOption'); //质检问题定义类型枚举
        this.getPublicEnum('getQuestionLevelEnum', 'questionLevelOption'); //质检问题定义级别枚举
        // this.getPublicEnum('getCaseQualityStepEnum', 'caseQualityStepOption') //获取质检步骤枚举
        this.getTbodyData();
        this.getRiskLevelEnum();
        this.getLossAmountRangeEnum();
        this.getDecidedAmountRangeEnum();
        this.getGoodsCategoryEnum();
        this.initOSSClient();
        this.getUserList();
    },
    methods: {
        foldQuery() {
            this.searchFold = !this.searchFold;
            this.$nextTick(() => {
                this.calcHeight();
            });
        },
        calcHeight() {
            let elRadioGroup = document.querySelector('.el-radio-group') ? document.querySelector('.el-radio-group').clientHeight : 0;
            let registerTabsHeight = document.querySelector('.register_tabs') ? document.querySelector('.register_tabs').clientHeight : 0;
            let publicBoxHeight = document.querySelector('.public_box') ? document.querySelector('.public_box').clientHeight : 0;
            let elPaginationHeight = document.querySelector('.el-pagination') ? document.querySelector('.el-pagination').clientHeight : 0;
            let tableHeaderHeight = 51;
            let guHeight = null;

            switch (this.menuType) {
                case 'searchPage':
                    guHeight = 20 + 20 + 30 + 10;
                    this.tableHeight = window.innerHeight - registerTabsHeight - publicBoxHeight - elPaginationHeight - tableHeaderHeight - guHeight;
                    break;
                case 'staging':
                    guHeight = 20 + 20 + 30 + 20 + 10;
                    this.tableHeight = window.innerHeight - elRadioGroup - registerTabsHeight - publicBoxHeight - elPaginationHeight - tableHeaderHeight - guHeight;
                    break;

                default:
                    guHeight = 20 + 20 + 20 + 30;
                    this.tableHeight = window.innerHeight - registerTabsHeight - publicBoxHeight - elPaginationHeight - tableHeaderHeight - guHeight;
                    break;
            }
        },
        getTotay() {
            var time = new Date();
            var year = time.getFullYear();
            var month = time.getMonth() + 1;
            var date = time.getDate();
            return year + '-' + this.add0(month) + '-' + this.add0(date);
        },
        //修改月、天的格式，保持两位数显示
        add0(m) {
            return m < 10 ? '0' + m : m;
        },
        timeChange(val, valStr) {
            // 2023-08-21 00:00:00
            let tmpDate = val && val.slice(0, 11);
            let tmpTime = val && val.slice(11, 19);
            if (tmpTime == '00:00:00') {
                tmpDate += '23:59:59';
                this.navForm[valStr] = tmpDate;
            }
        },
        qualityStepChange(val) {
            if (val != 10) {
                this.navForm.pageSize = null;
            }
        },
        getUserList() {
            let jsonData = { schedulingType: 2 };
            this.instance.post('/tpa/api/case/task/searchUser', jsonData).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.userTableData = data.data;
                }
            });
        },
        clickTab(tabRow) {
            if (tabRow.name == 99) {
                this.handleFlag = true;
            } else {
                let tmpIndex = this.editableTabs.findIndex((item) => {
                    return item.menuTypeNum == '99';
                });
                this.handleFlag = tabRow.name == 99 ? true : false;
                this.menuTypeNum = Number(tabRow.name);
                if (tmpIndex == -1) {
                    this.handleData.menuTypeNum = Number(tabRow.name);
                }
            }
        },
        addTab() {
            // 30立案 40定损 50核赔 90预立案
            this.editableTabs.push({
                title: '案件质检详情',
                menuTypeNum: '99'
            });
        },
        removeTab(targetName) {
            let tabs = this.editableTabs;

            this.handleFlag = false;
            this.editableTabsValue = this.tmpActiveName;
            this.clickTab({ name: this.tmpActiveName });
            if (targetName == '99') {
                localStorage.removeItem('detailShow');
                this.editableTabs = tabs.filter((tab) => tab.menuTypeNum !== targetName);
            }
        },
        handleCase(data) {
            this.handleData = JSON.parse(JSON.stringify(data));
            this.handleData.menuType = this.menuType;

            // 添加详情tab
            let tmpIndex = this.editableTabs.findIndex((item) => item.menuTypeNum == '99');
            this.tmpActiveName = this.handleData.tmpHandleData ? this.handleData.tmpHandleData.menuTypeNum + '' : this.handleData.menuTypeNum + '';
            tmpIndex == -1 && this.addTab();
            this.editableTabsValue = '99';
            this.handleFlag = true;
        },
        closeCaseDetail(switchData) {
            switchData ? this.handleCase(switchData) : this.removeTab('99');
        },
        // 初始化OSS
        initOSSClient() {
            this.instance
                .get('/tpa/api/aliyun/oss/access')
                .then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        console.log('oss初始化成功');
                        this.OSSClient = new OSS({
                            accessKeyId: data.data.accessKeyId,
                            accessKeySecret: data.data.accessKeySecret,
                            stsToken: data.data.securityToken,
                            // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                            region: data.data.region,
                            bucket: data.data.bucket
                        });
                    } else {
                        console.log('oss初始化失败，正在重试！-0');
                        this.initOSSClient();
                    }
                })
                .catch((err) => {
                    console.log(err);
                    console.log('oss初始化失败-');
                    console.log('oss初始化失败，正在重试！-1');
                });
        },
        getRiskLevelEnum() {
            this.instance.post('/tpa/api/enum/getRiskLevelEnum', {}).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.riskLevelOption = data.data;
                }
            });
        },

        getLossAmountRangeEnum() {
            this.instance.post('/tpa/api/enum/getLossAmountRangeEnum', {}).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.lossAmountRangeOption = data.data;
                }
            });
        },

        getDecidedAmountRangeEnum() {
            this.instance.post('/tpa/api/enum/getDecidedAmountRangeEnum', {}).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.decidedAmountRangeOption = data.data;
                }
            });
        },

        getGoodsCategoryEnum() {
            this.instance.post('/tpa/api/enum/getGoodsCategoryEnum', {}).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    this.goodsCategoryOption = data.data;
                }
            });
        },
        //点击添加或者修改按钮
        handleProblem(row, flagStr) {
            if (flagStr === 'show') {
                this.handleData = row;
                this.handleFlag = true;
                this.editableTabs.length === 1 && this.addTab();
                this.editableTabsValue = '99';
            } else {
                this.dialogVisible = true;
                this.conclusionOption = row.riskLists ? JSON.parse(JSON.stringify(row.riskLists)) : [];
                this.conclusionOption.push({ riskName: '其他', id: 9999991 });
                this.conclusionOption.push({ riskName: '无', id: 9999992 });
                this.$refs.qualityRef && this.$refs.qualityRef.resetFields();
                this.qualityForm = {
                    caseNo: row.caseNo,
                    qualityRiskLevel: '',
                    conclusion: '',
                    emphasisCase: true,
                    qualityRemark: ''
                };
            }
        },
        //操作保存
        handleSubmit(submitFlag) {
            submitFlag &&
                this.$refs.qualityRef.validate((valid) => {
                    if (valid) {
                        this.instance.post('/tpa/api/case/quality/add', this.qualityForm).then((res) => {
                            this.dialogVisible = false;
                            if (res.data.code == 200) {
                                this.$refs.qualityRef.resetFields();
                                this.$message.success('质检成功');
                                this.getTbodyData();
                            } else {
                                this.$message.error(res.data.message);
                            }
                        });
                    }
                });

            if (!submitFlag) {
                this.dialogVisible = false;
                this.conclusionOption = [];
                this.$refs.qualityRef.resetFields();
            }
        },
        getTbodyData() {
            this.loading = true;
            this.tableData = []; //  清空table的值
            var json = {};
            var navForm = JSON.parse(JSON.stringify(this.navForm));
            json.condition = navForm;
            json.pageNo = this.currentPage;
            json.pageSize = this.navForm.pageSize ? navForm.pageSize : this.pageSize;
            this.instance.post('/tpa/api/case/inner/quality/paging', json).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.loading = false;
                    this.tableData = data.data.list;
                    this.totalPage = parseInt(res.data.data.total);

                    this.tableData.forEach((item, index) => {
                        if (item.riskCount > 0) {
                            this.getRiskInfo(item, index);
                        }
                    });
                }
            });
        },
        getRiskInfo(row, rowIndex) {
            this.instance.get('/tpa/api/case/risk/getRiskInfo?caseNo=' + row.caseNo).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    row.riskLists = data.data;
                    let tmpRow = JSON.parse(JSON.stringify(row));
                    this.$set(this.tableData, rowIndex, tmpRow);
                }
            });
        },
        //点击查询
        searchTable(resetFlag) {
            if (resetFlag) {
                this.$refs.navForm.resetFields();
                this.navForm.startReportTime = '';
                this.navForm.endReportTime = '';
                this.navForm.startInflowTime = '';
                this.navForm.endInflowTime = '';
                this.navForm.startCaseEndTime = '';
                this.navForm.endCaseEndTime = '';
                this.navForm.pageSize = '';
            } else {
                this.currentPage = 1;
                this.getTbodyData();
            }
        },
        //重置筛选项
        resetForm() {
            this.navForm.createBy = '';
            this.navForm.startCreateTime = '';
            this.navForm.endCreateTime = '';
            this.$refs.navForm.resetFields();
        },
        onStatusChange: function (row) {
            this.$data.loading = true;
            this.instance
                .post('/tpa/api/quality/question/edit', { questionNo: row.questionNo, enabled: row.enabled })
                .then(({ data }) => {
                    this.$data.loading = false;
                    const operate = row.enabled == 1 ? '启用' : '停用';
                    if (data.code == 200) {
                        this.$message({ message: '质检问题' + row.questionName + '已' + operate, type: 'success' });
                        this.getTbodyData();
                    } else {
                        this.$message.error('质检问题' + row.questionName + operate + '失败！');
                    }
                })
                .catch((_) => (this.$data.loading = false));
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val;
            this.getTbodyData();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange(val) {
            this.currentPage = Number(val);
            this.getTbodyData();
        }
    }
};
