
// @ is an alias to /src
import CaseList from '@/components/CaseList.vue';
export default {
    name: 'financialVerification',
    components: {},
    data() {
        return {
            loading: false,
            isVisible: false,
            navForm: {
                month: '2023-01'
            },
            currentPage: 1, //当前页
            pageSize: 10, //一页多少条数据
            totalPage: 1, //总页数tableData:[],
            tableData: [
                {
                    no1: 'AJ3994095',
                    no2: 'P23423123423',
                    no3: '已决案件',
                    no4: '张裕 四星金奖白兰地 700ml',
                    no5: '2023-01-31  13:24:21',
                    no6: '15分钟',
                    no7: '118',
                    no8: '112',
                    no9: '112',
                    no10: '未决案件',
                    no11: '——',
                    no12: '——'
                },
                {
                    no1: 'AJ5893756',
                    no2: 'P24950058933',
                    no3: '未决案件',
                    no4: '奥康男士低帮商务正装鞋绿622161118541',
                    no5: '2023-01-31  13:26:32',
                    no6: '1小时',
                    no7: '700',
                    no8: '599',
                    no9: '599',
                    no10: '已决案件',
                    no11: '39.9元',
                    no12: '+39.9元'
                },
                {
                    no1: 'AJ9048594',
                    no2: 'P49390498595',
                    no3: '已决案件',
                    no4: '五芳斋速冻红豆沙汤圆480g',
                    no5: '2023-01-31  13:27:131',
                    no6: '2小时',
                    no7: '39.9',
                    no8: '0',
                    no9: '0',
                    no10: '未决案件',
                    no11: '——',
                    no12: '——'
                },
                {
                    no1: 'AJ4885973',
                    no2: 'P43005059904',
                    no3: '已决案件',
                    no4: '思念速冻汤圆454g',
                    no5: '2023-01-31  13:28:22',
                    no6: '1小时45分钟',
                    no7: '24.8',
                    no8: '24.8',
                    no9: '24.8',
                    no10: '已决案件',
                    no11: '44.8元',
                    no12: '+20元'
                },
                {
                    no1: 'AJ8593895',
                    no2: 'P40039400590',
                    no3: '未决案件',
                    no4: '高露洁（Colgate）全面防蛀牙膏4支装1000g',
                    no5: '2023-01-31  13:32:01',
                    no6: '15分钟',
                    no7: '57.9',
                    no8: '19.3',
                    no9: '19.3',
                    no10: '已决案件',
                    no11: '15元',
                    no12: '-4.3元'
                }
            ]
        };
    },
    methods: {
        handlePreview() {
            this.fullscreenLoading = this.$loading({
                lock: true,
                text: '上传中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            setTimeout(() => {
                this.fullscreenLoading.close();
                this.isVisible = true;
            }, 1000);
        },
        getTbodyData() {},
        //分页数据多少条change
        handleSizeChange(val) {
            this.pageSize = val;
            this.getTbodyData();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange(val) {
            // console.log(val);
            this.currentPage = Number(val);
            this.getTbodyData();
        }
    },
    mounted() {
        //
    }
};
