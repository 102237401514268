
// @ is an alias to /src
import CaseList from '@/components/CaseList.vue';
export default {
    name: 'firstAudit',
    components: {
        CaseList
    },
    data() {
        return {
            editableTabsValue: '0',
            editableTabs: [
                {
                    title: '待核赔案件',
                    menuTypeNum: '0'
                }
            ],
            loading: false,
            menuType: '49'
        };
    },
    methods: {},
    mounted() {
        this.editableTabs[0].title = this.$route.meta.title;
    }
};
