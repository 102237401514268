
// @ is an alias to /src
import CaseList from '@/components/CaseList.vue'
export default {
    name: 'firstAudit',
    components: {
        CaseList
    },
    data() {
        return {
            loading: false,
            menuType: '10'
        }
    },
    methods: {},
    mounted() {}
}
