
import * as echarts from 'echarts';
export default {
    data() {
        return {
            queryForm: {
                condition: {
                    endTime: '',
                    startTime: ''
                },
                orders: [],
                pageNo: 1,
                pageSize: 10,
                total: 0
            },
            nameList: [],
            dataList: [],
            dataList1: [],
            lChart: null,
            rChart: null,
            myChartLine: null,
            queryDate: '',
            pickerOptions: this.expireTimeOptionFun(),
            pickerOptions1: {
                shortcuts: [
                    {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }
                ]
            },
            ledgerForm: {
                handler: '',
                caseStep: ''
            },
            caseStepOption: [],
            userTableData: [],
            pageTitle: ''
        };
    },
    mounted() {
        this.pageTitle = this.$route.meta.title;
        this.queryDate = [this.getNowTime(new Date()), this.getNowTime(new Date())];
        this.getDaily();
        this.getDetail();
        this.getPublicEnum('getCaseStepEnum', 'caseStepOption'); //获取案件状态枚举
        this.getUserList();
    },
    methods: {
        getUserList() {
            let jsonData = { schedulingType: 2 };
            this.instance.post('/tpa/api/case/task/searchUser', jsonData).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.userTableData = data.data;
                }
            });
        },
        searchForm() {
            this.queryForm.pageNo = 1;
            this.getDaily();
            this.getDetail();
        },
        initSchedule(dataAll) {
            let _this = this;
            let myColor = ['#3C83FF'];
            var option = {
                grid: {
                    // 直角坐标系内绘图网格
                    left: '5%', //grid 组件离容器左侧的距离,
                    //left的值可以是80这样具体像素值，
                    //也可以是'80%'这样相对于容器高度的百分比
                    right: '5%'
                    //containLabel: true   //gid区域是否包含坐标轴的刻度标签。为true的时候，
                    // left/right/top/bottom/width/height决定的是包括了坐标轴标签在内的
                    //所有内容所形成的矩形的位置.常用于【防止标签溢出】的场景
                },
                xAxis: {
                    //直角坐标系grid中的x轴,
                    //一般情况下单个grid组件最多只能放上下两个x轴,
                    //多于两个x轴需要通过配置offset属性防止同个位置多个x轴的重叠。
                    type: 'value', //坐标轴类型,分别有：
                    //'value'-数值轴；'category'-类目轴;
                    //'time'-时间轴;'log'-对数轴
                    splitLine: {
                        show: false
                    }, //坐标轴在 grid 区域中的分隔线
                    axisLabel: {
                        show: false
                    }, //坐标轴刻度标签
                    axisTick: {
                        show: false
                    }, //坐标轴刻度
                    axisLine: {
                        show: false
                    } //坐标轴轴线
                },
                yAxis: {
                    type: 'category',
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: false
                    },
                    axisLabel: {
                        color: 'rgba(0,0,0,.7)',
                        fontSize: 14,
                        textStyle: {
                            rich: {
                                a: {
                                    fontSize: 14,
                                    width: 100
                                },
                                b: {
                                    fontSize: 14,
                                    align: 'center',
                                    color: '#3C83FF',
                                    fontWeight: 600
                                },
                                c: {
                                    padding: [0, 0, 0, 5],
                                    fontSize: 14,
                                    align: 'center'
                                }
                            }
                        }
                    },
                    data: [] //类目数据，在类目轴（type: 'category'）中有效。
                    //如果没有设置 type，但是设置了axis.data,则认为type 是 'category'。
                },
                legend: {
                    left: '30',
                    bottom: '1'
                },
                series: [
                    //系列列表。每个系列通过 type 决定自己的图表类型
                    {
                        name: `减损案件数量\n${this.dataList[0].reduceCount}件 ${this.dataList[0].reduceCountRate}`, //系列名称
                        type: 'bar', //柱状、条形图
                        barWidth: 15, //柱条的宽度,默认自适应
                        data: [this.dataList[0].reduceCount], //系列中数据内容数组
                        label: {
                            //图形上的文本标签
                            show: true,
                            position: 'right', //标签的位置
                            offset: [0, -40], //标签文字的偏移，此处表示向上偏移40
                            //formatter: '{c}{a}',//标签内容格式器 {a}-系列名,{b}-数据名,{c}-数据值
                            formatter: function (data) {
                                //.toFixed(0)去掉小数位，如果为2就是保留两位小数
                                //let b = data.percent.toFixed(0) + "%";
                                //subText = b;
                                return '';
                            },
                            color: 'rgba(0,0,0,.7)', //标签字体颜色
                            fontSize: 26 //标签字号
                        },
                        zlevel: 1 //柱状图所有图形的 zlevel 值,
                        //zlevel 大的 Canvas 会放在 zlevel 小的 Canvas 的上面
                    },
                    {
                        name: `案件总数量\n${this.dataList[0].reportCount}件`, //系列名称
                        type: 'bar',
                        barGap: '-100%', //不同系列的柱间距离，为百分比。
                        // 在同一坐标系上，此属性会被多个 'bar' 系列共享。
                        // 此属性应设置于此坐标系中最后一个 'bar' 系列上才会生效，
                        //并且是对此坐标系中所有 'bar' 系列生效。
                        barWidth: 15,
                        data: [this.dataList[0].reportCount],
                        color: '#DEE0E3' //柱条颜色
                    }
                ]
            };

            this.$nextTick(() => {
                this.myChartLine = echarts.init(this.$refs.chartImplement);
                this.myChartLine.setOption(option, true);
            });
        },
        getDaily() {
            if (this.queryDate && this.queryDate.length > 1) {
                this.queryForm.condition.startTime = this.queryDate[0] + ' 00:00:00';
                this.queryForm.condition.endTime = this.queryDate[1] + ' 23:59:59';
            }
            this.instance.post('/tpa/api/dashboard/case/daily', this.queryForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.dataList = data.data.data.list;
                    if (this.dataList[0].claimAmount) {
                        this.rightChart(data.data);
                        this.initSchedule();
                    }

                    this.leftChart(data.data);
                }
            });
        },
        getDetail(ledgerForm) {
            ledgerForm && Object.assign(this.queryForm.condition, this.queryForm.condition, ledgerForm);
            if (this.queryDate && this.queryDate.length > 1) {
                this.queryForm.condition.startTime = this.queryDate[0] + ' 00:00:00';
                this.queryForm.condition.endTime = this.queryDate[1] + ' 23:59:59';
            }
            this.instance.post('/tpa/api/dashboard/case/daily/detail', this.queryForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.dataList1 = data.data.data.list;
                    this.queryForm.total = data.data.data.total;
                }
            });
        },
        leftChart(data) {
            let dataNameObj = {};
            if (data.columns && data.columns.length > 0) {
                data.columns.forEach((item) => {
                    dataNameObj[item.code] = item;
                });
            }
            this.lChart && this.lChart.dispose();
            let option = {
                title: {
                    text: `报案总数：${data.data.list[0].reportCount}`,
                    left: 'center',
                    bottom: '5',

                    textStyle: {
                        fontSize: 14
                    }
                },
                tooltip: {
                    trigger: 'item',
                    formatter: '{b} : {c}<br/>占比： ({d}%)'
                },
                legend: {
                    orient: 'vertical',
                    left: 'right'
                },
                series: [
                    {
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: '#fff',
                            borderWidth: 2
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: data.data.list[0].closedCount, name: dataNameObj['closedCount'].title },
                            { value: data.data.list[0].closedWaitJdReplyCount, name: dataNameObj['closedWaitJdReplyCount'].title },
                            { value: data.data.list[0].jdReportCount, name: dataNameObj['jdReportCount'].title }
                        ]
                    }
                ]
            };

            this.$nextTick(() => {
                this.lChart = echarts.init(document.getElementById('leftChart'));
                this.lChart.setOption(option);
            });
        },
        rightChart(data) {
            this.rChart && this.rChart.dispose();
            let option = {
                title: {
                    // text: `索赔总金额(元)：${this.formatMoney(data.data.list[0].claimAmount)}`,
                    text: `索赔总金额(元)：${data.data.list[0].claimAmount ? data.data.list[0].claimAmount : ''}`,
                    left: 'center',
                    top: '0',

                    textStyle: {
                        fontSize: 14
                    }
                },
                tooltip: {
                    trigger: 'item',
                    formatter: `{b} : {c}<br/>占比： ({d}%)`
                },
                legend: {
                    orient: 'vertical',
                    left: 'right'
                },
                color: ['#005bab', '#FF9393'],
                series: [
                    {
                        name: '',
                        type: 'pie',
                        radius: '50%',
                        data: [
                            { value: data.data.list[0].reduceAmount, name: '减损总金额(元)' },
                            { value: data.data.list[0].checkAmount, name: '核损总金额(元)' }
                        ],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };
            this.$nextTick(() => {
                this.rChart = echarts.init(document.getElementById('rightChart'));
                this.rChart.setOption(option);
            });
        },
        sortChange(column) {
            // column.ascending==上升  column.descending==下降  column.prop
            if (!column.order) {
                this.queryForm.orders = [];
            } else {
                let obj = {
                    asc: column.order == 'descending' ? false : true,
                    column: column.prop
                };
                this.queryForm.orders = [obj];
            }
            this.getDetail();
        },
        handleCurrentChange(val) {
            this.queryForm.pageNo = val;
            this.getDetail();
        },
        handleSizeChange(val) {
            this.queryForm.pageSize = val;
            this.getDetail();
        },
        formatMoney(amount) {
            // 将金额转换为字符串，并去除可能存在的非数字字符
            amount = parseFloat(amount).toFixed(2).toString();

            // 拆分整数部分和小数部分
            const parts = amount.split('.');
            let integerPart = parts[0];
            const decimalPart = parts[1];

            // 添加千位分隔符
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            // 重新组合整数和小数部分
            return integerPart + '.' + decimalPart;
        },
        downloadFile(status) {
            let downloadUrl = status == 1 ? '/tpa/api/dashboard/case/daily/excel' : '/tpa/api/dashboard/case/daily/detail/excel';
            let downloadFileName = status == 1 ? '聚合报表' : '报表详情';
            this.instance.post(downloadUrl, this.queryForm, { responseType: 'blob' }).then((res) => {
                if (res.status == 200) {
                    let url = window.URL.createObjectURL(new Blob([res.data]));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', downloadFileName + '.xlsx');
                    document.body.appendChild(link);
                    link.click();
                }
            });
        },
        expireTimeOptionFun() {
            let selectData = [];
            return {
                shortcuts: [
                    {
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }
                ],
                onPick: ({ maxDate, minDate }) => {
                    selectData[0] = minDate?.getTime();
                    selectData[1] = maxDate?.getTime();
                },
                disabledDate(time) {
                    //选择时间
                    if (selectData[0] && !selectData[1]) {
                        return time.getTime() > selectData[0] + 24 * 60 * 60 * 30 * 1000 || time.getTime() < selectData[0] - 24 * 60 * 60 * 30 * 1000;
                    }
                }
            };
        },
        getDate() {
            var date = new Date(); // 当前日期时间
            var year = date.getFullYear(); // 年份
            var month = ('0' + (date.getMonth() + 1)).slice(-2); // 月份+1
            var day = ('0' + date.getDate()).slice(-2); // 日
            var hour = ('0' + date.getHours()).slice(-2); // 小时
            var minute = ('0' + date.getMinutes()).slice(-2); // 分钟
            var second = ('0' + date.getSeconds()).slice(-2); // 秒数
            var millisecond = ('000' + date.getMilliseconds()).slice(-3); // 毫秒数0-999

            return String(year + '-' + month + '-' + day);
        },
        // 获取日期
        getNowTime(temp) {
            var now = new Date(temp);
            var year = now.getFullYear(); //得到年份
            var month = now.getMonth() + 1; //得到月份
            var date = now.getDate(); //得到日期
            month = month.toString().padStart(2, '0');
            date = date.toString().padStart(2, '0');
            return `${year}-${month}-${date}`;
        }
    }
};
